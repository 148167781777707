import React, { Component } from 'react';
import swal from "sweetalert";
import axios from "axios";

import './appraisals.css';

import DatePicker from "react-datepicker";
import setMinutes from "date-fns/setMinutes";
import setHours from "date-fns/setHours";
import "react-datepicker/dist/react-datepicker.css";


import Gallery from 'react-grid-gallery';
import PhotoEditor from "../photoEditor/photoEditor";


const trackingTemplate = {
	usps:'https://tools.usps.com/go/TrackConfirmAction?tLabels=XXXXXXXXXX',
	ups:'http://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=1ZXXXXXXXXXX',
	fedex:'http://www.fedex.com/Tracking?action=track&tracknumbers=XXXXXXXXXX',
	laser:'http://www.lasership.com/track/LSXXXXXXXXXX',
	dhlus:'http://track.dhl-usa.com/TrackByNbr.asp?ShipmentNumber=XXXXXXXXXX',
	dhlgl:'http://webtrack.dhlglobalmail.com/?trackingnumber=XXXXXXXXXX',
	ontrac:'http://www.ontrac.com/trackingdetail.asp?tracking=XXXXXXXXXX',
	icc:'http://iccworld.com/track.asp?txtawbno=XXXXXXXXXX',
	canadapost:'http://www.canadapost.ca/cpotools/apps/track/personal/findByTrackNumber?trackingNumber=XXXXXXXXXX',
	indiapost:'http://ipsweb.ptcmysore.gov.in/ipswebtracking/IPSWeb_item_events.asp?itemid=XXXXXXXXXX'
}


const DatepickerInput = ({ ...props }) => (
  <input type="text" {...props} readOnly />
);

class Appraisal extends Component {

  constructor(props){
  	super(props)

  	let qvals={}
  	let selectedTab

  	(window.location.href.split('?')[1]||'').split('&').map((vv)=>{
    	let vx=vv.split('=')
    	qvals[vx[0]]=vx[1]
	})
	if(qvals.selectedTab){
		selectedTab = qvals.selectedTab
	}

  	this.state={
  		currentImage:null,
  		selectedTab:selectedTab||'description',
  		isDirty:false,
  		appraisalId:window.location.href.split('/appraisal/')[1].split('/').slice(0)[0],
  		appraisal:{},
  		photos:[],
      jewelers:[],
      ut:this.parseJwt()
  	}

  	this.fetch()
  }
  parseJwt() {
    let token = localStorage.getItem("jwtToken");
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function(c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  fetchJewelers(){
  let self=this

    axios
      .get("/api/users/jewelers")
      .then(res => {
        if (res.data) {
          self.setState({jewelers:res.data},self.fetchPhotos)
        }
        else{

          self.setState({jewelers:[]})  
        }
        // else if (res.data.result === "error") {
          console.log({jewelers:res});
        // }
      })
      .catch(error => {
        console.log(error);
      });

  }
  fetchPhotos(){
	let self=this

    axios
      .get("/api/photos/appraisals/"+self.state.appraisalId)
      .then(res => {
        if (res.data) {
        	self.setState({photos:res.data})
        }
        else{

        	self.setState({photos:{}})	
        }
        // else if (res.data.result === "error") {
        	console.log({photos:res});
        // }
      })
      .catch(error => {
        console.log(error);
      });

  }
  fetch(){
	let self=this

    axios
      .get("/api/appraisals/view/"+self.state.appraisalId)
      .then(res => {
        if (res.data) {
        	self.setState({appraisal:res.data},self.fetchJewelers)
        }
        else{

        	self.setState({appraisal:{}})	
        }
        // else if (res.data.result === "error") {
        	console.log({appraisal:res});
        // }
      })
      .catch(error => {
        console.log(error);
      });

  }

  updateField(field,value){
    let appraisal = this.state.appraisal
    appraisal[field]=value
    this.setState({appraisal,isDirty:true})
  }
  updateFields(fields,values){
    let appraisal = this.state.appraisal
    fields.forEach((f,fx)=>{
    	appraisal[f]=values[fx]	
    })
    
    this.setState({appraisal,isDirty:true})
  }

  addToCart(){
  	let self=this

        self.update(() => {

            let type = 'appraisal'
            let price = 75
            let title = (self.state.appraisal.description || '').slice(0, 100).split('.')[0]
            let description = (self.state.appraisal.description || '')
            let customer = self.state.appraisal.customer
            let user = self.state.appraisal.user._id
                //{type,title,description,instructions,customer,user,price}

            axios.post('/api/sales', {
                type,
                price,
                title,
                description,
                customer,
                user
            }).then((data) => {
                let sale = data.data


                console.log({sale})
                self.updateField('sale', sale._id)
                self.update(() => {

                  swal({
                      title: "Success!",
                      text: 'Added to Cart',
                      type: "success",
                      timer: 1000
                  }).then(value => {
                      // window.location='/sale/'+sale._id
                      window.location.reload(false);
                  });
                })

            }).catch((e) => {
                console.log('Creation failed, Error ', e)
                    // self.setState({errorMessage:e})
            });

            console.log('updated')
        })

  }
  update(callback){
    let self=this
    let appraisal = this.state.appraisal

    axios.put('/api/appraisals/'+appraisal._id,{appraisal}).then( (data) => {
      console.log(`Customer updated successfully`);

		if(callback){
			callback()
		}
		else{
			swal({
				title:"Success!",
				text:'Appraisal updated successfully',
				type:"success",
				timer:1000
			}).then(value => {
	      			window.location='/appraisal/'+appraisal._id+'?t='+new Date().getTime() + '&selectedTab='+self.state.selectedTab	
			});
		}
    }).catch((e) => {
    	console.log('Creation failed, Error ',e)
    	swal("Error!", e.message, "error");
		// self.setState({errorMessage:e})
	});
  }

  delete(){

  	let self=this
  	if(this.state.photos && this.state.photos.length){
  		swal("Error!", "You must first delete all photos associated with this appraisal.", "error");
  		return
  	}

    swal("Delete this appraisal?", {
  		icon: "warning",
      buttons: {
        nope: {
          text: "Cancel",
          value: "nope"
        },
        sure: {
          className: "btn-danger",
          text: "I'm, Sure",
          value: "sure"
        }
      }
    }).then(value => {

      switch (value) {
        case "sure":
          	console.log('Deleted')
				axios.delete('/api/appraisals/'+self.state.appraisal._id, {})
				.then( (data) => {
			      console.log(`Appraisal deleted successfully`);
		      		window.location='/appraisals'
			      // console.log(data)
			      	// self.props.addPhotoToModel(data.data.filename)
					// self.setState({
					// 	selectedNote:data.data,
					// },self.filterResults)
			    }).catch((e) => {
			    	console.log('Appraisal delete failed, Error ',e)
					// self.setState({errorMessage:e})
				});
          break;
        case "nope":
          	console.log('Didnt Delete (cancel)')
          break;
        default:
          	console.log('Didnt Delete (dismissed)')
          // swal("Got away safely!");
          break;
      }
    });
  }

	updatePhoto(field,value){
		let photos = this.state.photos
		photos[this.state.currentImage][field]=value
		this.setState({photos})
		console.log({updatePhoto:{field,value}})
	}

  	savePhoto(){
  		let photo = this.state.photos[this.state.currentImage]


		axios.put('/api/photos/'+photo._id, {photo})
		.then( (data) => {
	      console.log(`Item updated successfully`);
	      // self.fetchPhotos()
	      console.log(data)
	      	// self.props.addPhotoToModel(data.data.filename)
			// self.setState({
			// 	selectedNote:data.data,
			// },self.filterResults)
	    }).catch((e) => {
	    	console.log('Photo update failed, Error ',e)
			// self.setState({errorMessage:e})
		});
  	}


	newPhoto(fileObj){
		let self=this
		console.log(this.state)

		var formData = new FormData();
		formData.append("file", fileObj);
		formData.append("type", 'appraisals');
		formData.append("refId", this.state.appraisal._id);

		axios.post('/api/photos', formData, {
		    headers: {
		      'Content-Type': 'multipart/form-data'
		    }
		})
		.then( (data) => {
	      console.log(`Item created successfully`);
	      self.fetchPhotos()
	      console.log(data)
	      	// self.props.addPhotoToModel(data.data.filename)
			// self.setState({
			// 	selectedNote:data.data,
			// },self.filterResults)
	    }).catch((e) => {
	    	console.log('Creation failed, Error ',e)
			// self.setState({errorMessage:e})
		});

	}

	deletePhoto(){
		let self=this
  		let photo = this.state.photos[this.state.currentImage]


		axios.delete('/api/photos/'+photo._id, {})
		.then( (data) => {
	      console.log(`Photo deleted successfully`);
      		window.location='/appraisal/'+self.state.appraisal._id+'?t='+new Date().getTime() + '&selectedTab='+self.state.selectedTab
	      // console.log(data)
	      	// self.props.addPhotoToModel(data.data.filename)
			// self.setState({
			// 	selectedNote:data.data,
			// },self.filterResults)
	    }).catch((e) => {
	    	console.log('Photo delete failed, Error ',e)
			// self.setState({errorMessage:e})
		});
		// let photo = this.state.appraisal.photos[this.state.currentImage]
		// this.props.deleteModelPhoto(photo)
		// console.log({deletePhoto:photo})
		// console.log({deletePhoto:this.state.currentImage})
	}

    onCurrentImageChange(index) {
        console.log({
            onCurrentImageChange: index
        })
        this.setState({
            currentImage: index
        });
    }



  render() {
		let self=this

    let shopHint = ''
    if(self.state.appraisal && !self.state.appraisal.shopId){
      console.log({ok1:self.state})
      shopHint = '/' + localStorage.currentShop
    }
    else{
      console.log({ok2:self.state})
    }


    let addToCartButton = null
    if(!self.state.appraisal.sale){
      addToCartButton = (

            <ol key='update_button' className="breadcrumb float-sm-right">
              <li style={{marginRight: '10px'}}>          
                <a onClick={()=>{self.addToCart()}} className="btn btn-block btn-success" style={{color:'white'}}>Add to Cart $75</a>
              </li>
            </ol>
    )
    }
		let updateButton=(

            <ol key='update_button' className="breadcrumb float-sm-right">
              <li style={{marginRight: '10px'}}>          
                <a href={axios.defaults.baseURL + '/api/print/appraisal/'+ this.state.appraisal._id + shopHint} target='_blank' className="btn btn-block btn-primary">Print</a>
              </li>
            </ol>
    )
		if(self.state.isDirty){
			updateButton=(

	          <ol key='update_button' className="breadcrumb float-sm-right">
	            <li style={{marginRight: '10px'}}>         	
	          		<button type="button" className="btn btn-block btn-success" onClick={()=>{self.update()}}>Update</button>
	            </li>
	          </ol>
			)
		}


        let galleryPhotos=(this.state.photos||[]).map((p,pid)=>{
			return {
		        src: axios.defaults.baseURL + "/api/photos/"+p.type+'/'+p.fileName,
		        thumbnail: axios.defaults.baseURL + "/api/photos/"+p.type+'/'+p.fileName,
		        // thumbnailWidth: 80,
		        // thumbnailHeight: 190,
		        // isSelected: true,
		        // caption: "After Rain (Jeshu John - designerspics.com)"
			}
		})

		let currentImageDetails = ''
		if(this.state.photos && this.state.photos.length){
			if(this.state.currentImage!=null && !isNaN(this.state.currentImage) && this.state.photos[this.state.currentImage] && this.state.photos[this.state.currentImage].details){
				currentImageDetails = this.state.photos[this.state.currentImage].details
			}
		}
		let gallery = null
		if(galleryPhotos.length){
			gallery = (

        		<Gallery key="_gallery"
        			rowHeight={100}
        			enableImageSelection={false} 
					backdropClosesModal={true}
					showLightboxThumbnails={true}
        			images={galleryPhotos}
					currentImageWillChange={(index)=>self.onCurrentImageChange(index)}
					customControls={[

                    	<button key="deleteImage" onClick={()=>self.deletePhoto()}>Delete</button>,

						<textarea 
							className="form-control photo-description-textarea" 
							rows="7" 
							onChange={(e)=>{self.updatePhoto('details',e.target.value)}}
							onBlur={()=>{self.savePhoto()}}
							value={currentImageDetails}
							placeholder="Photo Details..."/>
					]}/>
			)
		}

		let trackingLink = null;

		if((this.state.appraisal.shippingMethod||'').trim().length && this.state.appraisal.shippingMethod !== 'other' && (this.state.appraisal.tracking||'').trim().length){
			let trackingUrl = trackingTemplate[this.state.appraisal.shippingMethod].replace('XXXXXXXXXX',this.state.appraisal.tracking)

			trackingLink = (
				<span>&nbsp;
					<a target="_blank" href={trackingUrl}>track</a>
				</span>
			)
		}

    return (
    <div className="content-wrapper">
	  {/* Content Header (Page header) */}
	  <div className="content-header">
	    <div className="container-fluid">
	      <div className="row mb-2">
	        <div className="col-sm-12">

	          <h1 className="m-0 text-dark float-sm-left">
            	Appraisal
              {
              //self.state.appraisal.title
              }
	          </h1>
	          {updateButton}
            {addToCartButton}
	        </div>{/* /.col */}
	      </div>{/* /.row */}
	    </div>{/* /.container-fluid */}
	  </div>
	  {/* /.content-header */}
	  {/* Main content */}



    <section className="content">
      <div className="row">
        <div className="col-12">

          <div className="card">
          	{
            // <div className="card-header">
            // 	<h3>
            // 		{name}
            // 	</h3>
            // </div>
        	}
            {
            // <!-- /.card-header -->
            //const newAppraisal = new Appraisal({type,title,description,instructions,customer,user,price});
        	}
            <div className="card-body">

      			<div className="row">
        			<div className="col-6">

        			  <label>Name</label>

                      <input
                        onChange={(e)=>{self.updateField('preparedName',e.target.value)}}
                        value={this.state.appraisal.preparedName||''}             
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off" 
                        type="text"
                        className="form-control"
                        id="preparedName"
                        placeholder="Full Name"
                      />

        			  <label>Address</label>

                      <input
                        onChange={(e)=>{self.updateField('preparedAddress',e.target.value)}}
                        value={this.state.appraisal.preparedAddress||''}             
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off" 
                        type="text"
                        className="form-control"
                        id="preparedAddress"
                        placeholder="Full Address"
                      />

        			  <label>City, State, Zip</label>

                      <input
                        onChange={(e)=>{self.updateField('preparedCityStateZip',e.target.value)}}
                        value={this.state.appraisal.preparedCityStateZip||''}             
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off" 
                        type="text"
                        className="form-control"
                        id="cityStateZip"
                        placeholder="City, State Zip"
                      />

        			</div>
        			<div className="col-6">

        			  <label>value</label>
                      <input
                        onChange={(e)=>{self.updateField('value',e.target.value.replace(/[^0-9\.]/gi,''))}}
                        value={'$'+this.state.appraisal.value||0}             
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off" 
                        type="text" pattern="\d*"
                        className="form-control"
                        id="value"
                        placeholder="Value"
                        onBlur={(e)=>{
                        	if(isNaN(+this.state.appraisal.value)){
                        		self.updateField('value','0.00')
                        	}
                        	else{
                        		self.updateField('value',(+this.state.appraisal.value).toFixed(2))	
                        	}
                        }}
                      />

        			  <label>Appraisal Date</label>

                      <DatePicker
            			selected={this.state.appraisal.preparedDate?new Date(this.state.appraisal.preparedDate):new Date()}
                        onChange={date => this.updateField('preparedDate',date)}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        timeCaption="time"
                        minTime={setHours(setMinutes(new Date(), 30), 9)}
                        maxTime={setHours(setMinutes(new Date(), 0), 19)}
                        dateFormat="yyyy-MM-dd h:mm aa"
                        customInput={<DatepickerInput className="form-control" />}
                      />

{
                      // <label>Appraised By</label>
                      // <select 
                      //   className="form-control select2"
                      //     onChange={(e)=>{self.updateField('jeweler',e.target.value||'')}}
                      //     value={(this.state.appraisal.jeweler||'')}>
                      //   <option value="">Choose Jeweler</option>
                      //   {self.state.jewelers.map((j)=>{
                      //     return <option key={'j_'+j._id} value={j._id}>{[(j.firstName||''),(j.lastName||'')].filter((vv)=>{return vv.trim().length}).join(' ').trim()}</option>
                      //   })}
                      // </select>
}
        			</div>

			          <div className="col-12">&nbsp;</div>

			          <div className="col-12">
			            <ul className="nav nav-tabs" id="custom-content-below-tab" role="tablist">
			              <li className="nav-item">
			                <a className={"nav-link" + (self.state.selectedTab==='description'?' active':'')}
			                   id="custom-content-description-tab" 
			                   data-toggle="pill" 
			                   href="#custom-content-description" 
			                   role="tab" 
			                   aria-controls="custom-content-description" 
			                   aria-selected="false"
			                   onClick={(e)=>{
			                   	self.setState({selectedTab:'description'})
			                   }}>Description</a>
			              </li>
			              <li className="nav-item">
			                <a className={"nav-link" + (self.state.selectedTab==='characteristics'?' active':'')}
			                   id="custom-content-characteristics-tab" 
			                   data-toggle="pill" 
			                   href="#custom-content-characteristics" 
			                   role="tab" 
			                   aria-controls="custom-content-characteristics" 
			                   aria-selected="false"
			                   onClick={(e)=>{
			                   	self.setState({selectedTab:'characteristics'})
			                   }}>Characteristics</a>
			              </li>
			              <li className="nav-item">
			                <a className={"nav-link" + (self.state.selectedTab==='photos'?' active':'')}
			                   id="custom-content-photos-tab" 
			                   data-toggle="pill" 
			                   href="#custom-content-photos" 
			                   role="tab" 
			                   aria-controls="custom-content-photos" 
			                   aria-selected="false"
			                   onClick={(e)=>{
			                   	self.setState({selectedTab:'photos'})
			                   }}>Photos</a>
			              </li>
			              <li className="nav-item">
			                <a className={"nav-link" + (self.state.selectedTab==='more'?' active':'')}
			                   id="custom-content-more-tab" 
			                   data-toggle="pill" 
			                   href="#custom-content-more" 
			                   role="tab" 
			                   aria-controls="custom-content-more" 
			                   aria-selected="false"
			                   onClick={(e)=>{
			                   	self.setState({selectedTab:'more'})
			                   }}>More...</a>
			              </li>
			            </ul>
			            <div className="tab-content" id="custom-content-below-tabContent">
			              <div className={"tab-pane fade show" + (self.state.selectedTab==='description'?' active':'')} 
			              	   id="custom-content-description" 
			              	   role="tabpanel" 
			              	   aria-labelledby="custom-content-description-tab">
			                 
			                      <textarea 
			                      	className="form-control" 
			                      	rows="12" 
			                        onChange={(e)=>{self.updateField('description',e.target.value)}}
			                        value={this.state.appraisal.description}
			                      	placeholder="Description..."/>
			              </div>
			              <div className={"tab-pane fade show" + (self.state.selectedTab==='characteristics'?' active':'')} 
			              	   id="custom-content-characteristics" 
			              	   role="tabpanel" 
			              	   aria-labelledby="custom-content-characteristics-tab">
			                 
			                      <textarea 
			                      	className="form-control" 
			                      	rows="12" 
			                        onChange={(e)=>{self.updateField('characteristics',e.target.value)}}
			                        value={this.state.appraisal.characteristics}
			                      	placeholder="Characteristics..."/>
			              </div>
			              <div className={"tab-pane fade show" + (self.state.selectedTab==='photos'?' active':'')} 
			              	   id="custom-content-photos" 
			              	   role="tabpanel" 
			              	   aria-labelledby="custom-content-photos-tab">
			                 
                        <div className="col-2">
                          <PhotoEditor 
                          text="New Photo" 
                          onImageBlob={(data)=>{
                            self.newPhoto(data.blobFile)
                          }}/>
                        </div>
			            		{gallery}
			              </div>
			              <div className={"tab-pane fade show" + (self.state.selectedTab==='more'?' active':'')} 
			              	   id="custom-content-more" 
			              	   role="tabpanel" 
			              	   aria-labelledby="custom-content-more-tab">
			                 
	          					<button type="button" className="btn btn-block btn-danger" onClick={()=>{self.delete()}}>delete</button>
			              </div>
			            </div>
			          </div>

        		</div>

            </div>
	        {
	        //<!-- /.card-body -->
	        }
          </div>
          {
          	//<!-- /.card -->
      	  }
        </div>
        {
        //<!-- /.col -->
    	}
      </div>
      {
      //<!-- /.row -->
  }
    </section>
	</div>

      );
  }
}

export default Appraisal;
