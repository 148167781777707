import React, { Component } from 'react';
import swal from "sweetalert";
import axios from "axios";


import Gallery from 'react-grid-gallery';

import PhotoEditor from "../photoEditor/photoEditor";

const ALL_TYPES = {
  bracelet:'bracelet',
  earrings:'earrings',
  ring:'ring',
  necklace:'necklace',
  pendant:'pendant',
  watch:'watch'
}

const ALL_CATALOGS = {
  bering:"Bering",
  chicpistachio:"Chic Pistachio",
  citizen:"Citizen",
  classicgrown:"Classic Grown",
  jorgerevilla:"Jorge Revilla",
  michou:"Michou",
  silverelegance:"Silver Elegance",
  twinklesbysevan:"Twinkles by Sevan",
  tisentomilano:"Ti Sento Milano",
  steelrevolt:"Steel Revolt"
}

const ALL_CATEGORIES = {
  bridal:"bridal",
  gents:"gents",
  custom:"custom",
  lab:"lab",
  cz:"cz",
  diamond:"diamond",
  ruby:"ruby",
  emerald:"emerald",
  sapphire:"sapphire",
  gemstone:"gemstone",
  sterling:"sterling",
  gold:"gold",
  '10k':"10k",
  '14k':"14k",
  '18k':"18k",
  platinum:"platinum"
}
class CustomerStory extends Component {

  constructor(props){
    super(props)

    let qvals={}
    let selectedTab

        (window.location.href.split('?')[1] || '').split('&').map((vv) => {
        let vx = vv.split('=')
        qvals[vx[0]] = vx[1]
    })
    if (qvals.selectedTab) {
        selectedTab = qvals.selectedTab
    }
  	this.state={
      selectedTab:selectedTab||'description',
  		isDirty:false,
  		storyId:window.location.href.toLowerCase().split('/customerstory/')[1].split('/').slice(0)[0],
  		story:{},
  		sku:{},
      categories:[],
      photos:[],
      queue:[],
      sales:[],
      sizeOption:'',
      colorOption:''
  	}

  	this.fetch()
  }

  componentDidMount() {
    let self=this
    // console.log({lstory:'mounted'})
    window.addEventListener("beforeunload", (e)=>{
      self.handleNavigation(e)
    });
  }

  componentWillUnmount() {
    let self=this
    // console.log({lstory:'unmounting'})
    window.removeEventListener("beforeunload", (e)=>{
      self.handleNavigation(e)
    });
  }

  handleNavigation(e){
    // console.log({lstory:'handleNavigation',e,isDirty:this.state.isDirty})
    // return "Are you sure you want to leave?"
    if(this.state.isDirty){
      // e.returnValue =  "Are you sure you want to leave?"
      var confirmationMessage = "Are you sure you want to leave?";

      (e || window.event).returnValue = confirmationMessage;     // Gecko + IE
      return confirmationMessage;
    }
    
  }

  fetch(callback){
    let self=this

    let cb = callback || function(){}

    axios
      .get("/api/customerStories/view/"+self.state.storyId)
      .then(res => {
        if (res.data) {
          self.setState({
            story:res.data,
            photos: res.data.images || []
          },()=>{

            // self.fetchPhotos(cb)
          })
        }
        else{

          self.setState({story:{}},()=>{
            // self.fetchPhotos(cb)
          })  
        }
        // else if (res.data.result === "error") {
          console.log({story:res});
        // }
      })
      .catch(error => {
        console.log(error);
        // self.fetchPhotos(cb)
      });

  }

  updateField(field,value,callback){
    let story = this.state.story
    story[field]=value
    this.setState({story,isDirty:true},callback)
  }
  updateMetadataField(field,value,callback){
    let story = this.state.story
    let metadata = story.metadata || {}
    metadata[field]=value
    story.metadata=metadata
    this.setState({story,isDirty:true},callback)
  }
  updateSkuField(field,value,callback){
    let sku = this.state.sku
    sku[field]=value
    this.setState({sku,isDirty:true},callback)
  }
  updateFields(fields,values,callback){
    let story = this.state.story
    fields.forEach((f,fx)=>{
      story[f]=values[fx]  
    })
    
    this.setState({story,isDirty:true},callback)
  }

  update(callback){
        let self = this
        let story = this.state.story
        let {customerName,title,location,description,active,images,isEJR,isWillow} = self.state.story

        console.log({title,description,active,images,isEJR,isWillow})

        axios.put('/api/customerStories/' + story._id, {
          story:{customerName,title,location,description,active,images,isEJR,isWillow}
        }).then((data) => {
            console.log(`CustomerStory updated successfully`);


            this.setState({story,isDirty:false},()=>{
              self.fetch(()=>{
                if(callback){
                  callback()
                }
                // self.props.onUpdate()
              })  
            })
            
            // swal({
            //   title:"Success!",
            //   text:'CustomerStory updated successfully',
            //   type:"success",
            //   timer:1000
            // }).then(value => {
                // window.location = '/product?t=' + new Date().getTime()
            // });
        }).catch((e) => {
            console.log('Creation failed, Error ', e)
            swal("Error!", e.message, "error");
            // self.setState({errorMessage:e})
        });
  }

  delete(){
    let self=this
    console.log(this.state.sale)

    let additionalWarning=''

    swal(additionalWarning+ "Are you sure you want to delete this story?", {
      icon: "warning",
      buttons: {
        nope: {
          text: "Cancel",
          value: "nope"
        },
        sure: {
          className: "btn-danger",
          text: "I'm, Sure",
          value: "sure"
        }
      }
    }).then(value => {
      if(this.state.photos && this.state.photos.length){
        swal("Error!", "You must first delete all photos associated with this story.", "error");
        return
      }

      switch (value) {
        case "sure":
          
            console.log('Deleted')
        axios.delete('/api/customerStories/'+self.state.story._id, {})
        .then( (data) => {
            console.log(`CustomerStory deleted successfully`);
                window.location='/customerStories'
            // console.log(data)
              // self.props.addPhotoToModel(data.data.filename)
          // self.setState({
          //  selectedNote:data.data,
          // },self.filterResults)
          }).catch((e) => {
            console.log('CustomerStory delete failed, Error ',e)
          // self.setState({errorMessage:e})
        });
          break;
        case "nope":
            console.log('Didnt Delete (cancel)')
          break;
        default:
            console.log('Didnt Delete (dismissed)')
          // swal("Got away safely!");
          break;
      }
    });

  }

//   fetchPhotos(callback){
//     let self=this
//     let cb = callback || function(){}

//     axios
//       .get("/api/photos/stories/"+self.state.storyId)
//       .then(res => {
//         if (res.data) {
//           self.setState({photos:res.data},cb)
//         }
//         else{

//           self.setState({photos:[]},cb)  
//         }
//         // else if (res.data.result === "error") {
//           console.log({photos:res});
//         // }
//       })
//       .catch(error => {
//         console.log(error);
//         cb()
//       });

//   }

  updatePhoto(field,value){
    let photos = this.state.photos
    photos[this.state.currentImage][field]=value
    this.setState({photos})
    console.log({updatePhoto:{field,value}})
  }

  // savePhoto(){
  //     let photo = this.state.photos[this.state.currentImage]


  //   axios.put('/api/photos/'+photo._id, {photo})
  //   .then( (data) => {
  //       console.log(`Item updated successfully`);
  //       // self.fetchPhotos()
  //       console.log(data)
  //         // self.props.addPhotoToModel(data.data.filename)
  //     // self.setState({
  //     //  selectedNote:data.data,
  //     // },self.filterResults)
  //     }).catch((e) => {
  //       console.log('Photo update failed, Error ',e)
  //     // self.setState({errorMessage:e})
  //   });
  // }
  
  newPhoto(file){
    let self = this

    console.log(file)

    // let extension = file.name.split('.').slice(-1)[0]
    let extension = file.type .split('/').slice(-1)[0]
    const blob = new Blob([file], { type: file.type });// WORKS much better (if you know what MIME type you want.
    const formData = new FormData();
    formData.append('file', blob, this.state.story.id + '_'+new Date().getTime() + '.' + extension);
  
    console.log({formData})

    // self.setState({editField:'imageWait'},()=>{
      axios.post('/api/customerStories/images', formData, {timeout:1000*60*20})
      .then((upload)=>{
        if(upload && upload.data && upload.data.url){
          let images = self.state.story.images || []
          images.push(upload.data.url)
          self.updateField('images',images,()=>{
            self.update(()=>{
              console.log({updating1:true})
              self.setState({photos:images})
              // window.location.reload()

              // window.$("#slider ul").bxSlider({
              // 	controls: false,
              // 	auto: false,
              // 	mode: 'fade',
              // 	preventDefaultSwipeX: false
              // });
              // window.location = '/editCustomerStory/' + this.state.productId + '?t='+new Date().getTime()
            })
          })
        }
        console.log({upload})
      }).catch((err) => {
        console.log({err})
      // self.setState({errorMessage:e})
      });	
    // })
  }
  // newPhoto_old(fileObj){
  //   let self=this
  //   console.log(this.state)

  //   var formData = new FormData();
  //   formData.append("file", fileObj);
  //   formData.append("type", 'products');
  //   formData.append("refId", this.state.product._id);

  //   axios.post('/api/photos', formData, {
  //       headers: {
  //         'Content-Type': 'multipart/form-data'
  //       }
  //   })
  //   .then( (data) => {
  //       console.log(`Item created successfully`);
  //       self.fetchPhotos()
  //       console.log(data)
  //         // self.props.addPhotoToModel(data.data.filename)
  //     // self.setState({
  //     //  selectedNote:data.data,
  //     // },self.filterResults)
  //     }).catch((e) => {
  //       console.log('Creation failed, Error ',e)
  //     // self.setState({errorMessage:e})
  //   });

  // }

  deletePhoto(url){
    let self = this

      swal("Are you sure you want to delete this file?", {
          buttons: {
              nope: {
                  text: "Cancel",
                  value: "nope",
                  className: "btn-primary"
              },
              sure: {
                  text: "Delete",
                  value: "sure",
                  className: "btn-danger"
              }
          }
      }).then(value => {

          switch (value) {
              case "sure":


                self.setState({editField:'imageWait'},()=>{

            let fileName = url.split('/').slice(-1)[0]

          axios.delete('/api/customerStories/images', {data:{fileName}}).then((result)=>{
            console.log({result})
            if(result && result.data && result.data.ok){
              let images = (self.state.story.images || []).filter((i)=>{return i!==url})
              // let images = self.state.images.filter((i)=>{return i!==url})
              self.updateField('images',images,()=>{
                self.update(()=>{
                  console.log({updating2:true})
                  // window.location='/admin/product/'+self.state.product.id+'?t='+new Date().getTime() + '&selectedTab='+self.state.selectedTab
                  // window.location.reload()

                  // window.$("#slider ul").bxSlider({
                  // 	controls: false,
                  // 	auto: false,
                  // 	mode: 'fade',
                  // 	preventDefaultSwipeX: false
                  // });
                  // window.location = '/editCustomerStory/' + this.state.productId + '?t='+new Date().getTime()
                })
              })
            }
          }).catch((err) => {
            console.log({err})
          // self.setState({errorMessage:e})
          });
                })
                  break;
              case "nope":
                  // swal("Ok", "success");
                  self.setState({editField:'null'})
                  break;
              default:
                  // swal("Got away safely!");
          }
      });

  }
  deletePhoto_old(){
    let self=this
      let photo = this.state.photos[this.state.currentImage]


    axios.delete('/api/photos/'+photo._id, {})
    .then( (data) => {
        console.log(`Photo deleted successfully`);
          window.location='/customerStory/'+self.state.story._id+'?t='+new Date().getTime() + '&selectedTab='+self.state.selectedTab
        // console.log(data)
          // self.props.addPhotoToModel(data.data.filename)
      // self.setState({
      //  selectedNote:data.data,
      // },self.filterResults)
      }).catch((e) => {
        console.log('Photo delete failed, Error ',e)
      // self.setState({errorMessage:e})
    });
    // let photo = this.state.sale.photos[this.state.currentImage]
    // this.props.deleteModelPhoto(photo)
    // console.log({deletePhoto:photo})
    // console.log({deletePhoto:this.state.currentImage})
  }

  onCurrentImageChange(index) {
    console.log({onCurrentImageChange:index})
        this.setState({ currentImage: index });
    }




  render() {
		let self=this
		let title=''



		let updateButton=null
		if(self.state.isDirty){
			updateButton=(

	            <li key='update_button' id='sadas'>         	
	          		<button type="button" className="btn btn-block btn-success" onClick={()=>{self.update()}}>update</button>
	            </li>
			)
		}

    let activeButton=null

    if(self.state.story._id){
      title = self.state.story.title
     
      if(self.state.story.active){
        activeButton=(

              <li key='deactivate_button'>          
                <button type="button" className="btn btn-block btn-danger" onClick={(e)=>{
                  self.updateField('active',false,()=>{
                    self.update()
                  })
                }}>Make Inactive</button>
              </li>
        )
      }
      else{
        activeButton=(

              <li key='activate_button'>          
                <button type="button" className="btn btn-block btn-primary" onClick={(e)=>{
                  self.updateField('active',true,()=>{
                    self.update()
                  })
                }}>Make Active</button>
              </li>
        )

      }
    }

    let pImages = (this.state.story || {}).images || []
    
// console.log({pImages})
        let galleryPhotos=pImages.map((p,pid)=>{
      return {
            src: p,
            thumbnail: p,
            // src: axios.defaults.baseURL + "/api/photo/"+p.type+'/'+p.fileName,
            // thumbnail: axios.defaults.baseURL + "/api/photo/"+p.type+'/'+p.fileName,
            // thumbnailWidth: 80,
            // thumbnailHeight: 190,
            // isSelected: true,
            // caption: "After Rain (Jeshu John - designerspics.com)"
      }
    })

    let currentImageDetails = ''
    if(this.state.photos && this.state.photos.length){
      if(this.state.currentImage!=null && !isNaN(this.state.currentImage) && this.state.photos[this.state.currentImage] && this.state.photos[this.state.currentImage].details){
        currentImageDetails = this.state.photos[this.state.currentImage].details
      }
    }
    let gallery = null
    if(galleryPhotos.length){
      gallery = (

            <Gallery key="_gallery"
              rowHeight={100}
              enableImageSelection={false} 
              backdropClosesModal={true}
              showLightboxThumbnails={true}
              images={galleryPhotos}
              currentImageWillChange={(index)=>self.onCurrentImageChange(index)}
              customControls={[

                      <button key="deleteImage" onClick={()=>{
                        // console.log({deletePhoto:self.state})
                        self.deletePhoto((self.state.story.images || [])[self.state.currentImage])
                      }}>Delete</button>,

            // <textarea 
            //   className="form-control photo-description-textarea" 
            //   rows="7" 
            //   onChange={(e)=>{self.updatePhoto('details',e.target.value)}}
            //   onBlur={()=>{self.savePhoto()}}
            //   value={currentImageDetails}
            //   placeholder="Photo Details..."/>
          ]}/>
      )
    }

    let queue = self.state.queue || []
    if(!queue || !Array.isArray(queue)){
      queue = []
    }
    let sales = self.state.sales || []
    if(!sales || !Array.isArray(sales)){
      sales = []
    }

    console.log({sp:this.state.product})
    return (
    <div className="content-wrapper">
	  {/* Content Header (Page header) */}
	  <div className="content-header">
	    <div className="container-fluid">
	      <div className="row mb-2">
	        <div className="col-sm-12">

	          <h3 className="m-0 text-dark float-sm-left">
            	<a href="/customerStories">Customer Stories</a>: {title}
	          </h3>

            <ol className="breadcrumb float-sm-right">
            {updateButton}

              <li key='li-space' id='sadas'>           
                &nbsp;&nbsp;&nbsp;
              </li>
            {activeButton}
            </ol>
	        </div>{/* /.col */}
	      </div>{/* /.row */}
	    </div>{/* /.container-fluid */}
	  </div>
	  {/* /.content-header */}
	  {/* Main content */}



    <section className="content">
      <div className="row">
        <div className="col-12">

          <div className="card">
          	{
            // <div className="card-header">
            // 	<h3>
            // 		{name}
            // 	</h3>
            // </div>
        	}
            {
            // <!-- /.card-header -->
        	}
            <div className="card-body">
            <form autoComplete="none">
            <input type="hidden" value="dummy" />

            	 
      			<div className="row">
        			<div className="col-6">

                <input
                  type="checkbox"
                  checked={this.state.story.isEJR}
                  onChange={(e)=>{self.updateField('isEJR',e.target.checked)}}
                  id="isEJR"
                />                
                <label htmlFor="isEJR" style={{marginLeft:'10px'}}>EJR</label><br/>
                <input
                  type="checkbox"
                  checked={this.state.story.isWillow}
                  onChange={(e)=>{self.updateField('isWillow',e.target.checked)}}
                  id="isWillow"
                />                
                <label htmlFor="isWillow" style={{marginLeft:'10px'}}>Willow</label><br/>

                <hr/>
                <label>Customer Name</label>
                <input
                  onChange={(e)=>{self.updateField('customerName',e.target.value)}}
                  value={this.state.story.customerName||''}
                  autoComplete="none"
                  autoCorrect="off"
                  autoCapitalize="off" 
                  type="text"
                  className="form-control"
                  id="customerName"
                  placeholder="Ex: Bill P."
                />
                <label>Title</label>
                <input
                  onChange={(e)=>{self.updateField('title',e.target.value)}}
                  value={this.state.story.title||''}
                  autoComplete="none"
                  autoCorrect="off"
                  autoCapitalize="off" 
                  type="text"
                  className="form-control"
                  id="title"
                  placeholder="Title"
                />
                <label>Customer Location</label>
                <input
                  onChange={(e)=>{self.updateField('location',e.target.value)}}
                  value={this.state.story.location||''}
                  autoComplete="none"
                  autoCorrect="off"
                  autoCapitalize="off" 
                  type="text"
                  className="form-control"
                  id="location"
                  placeholder="Ex: Elyria, OH  or just Ohio"
                />


        			</div>
        			<div className="col-6">

        			</div>
        		</div>

              <div className="col-12">&nbsp;</div>

                <div className="col-12">
                  <ul className="nav nav-tabs" id="custom-content-below-tab" role="tablist">
                    <li className="nav-item">
                      <a className={"nav-link" + (self.state.selectedTab==='description'?' active':'')}
                         id="custom-content-description-tab" 
                         data-toggle="pill" 
                         href="#custom-content-description" 
                         role="tab" 
                         aria-controls="custom-content-description" 
                         aria-selected="false"
                         onClick={(e)=>{
                          self.setState({selectedTab:'description'})
                         }}>Description</a>
                    </li>
                    <li className="nav-item">
                      <a className={"nav-link" + (self.state.selectedTab==='photos'?' active':'')}
                         id="custom-content-photos-tab" 
                         data-toggle="pill" 
                         href="#custom-content-photos" 
                         role="tab" 
                         aria-controls="custom-content-photos" 
                         aria-selected="false"
                         onClick={(e)=>{
                          self.setState({selectedTab:'photos'})
                         }}>Photos</a>
                    </li>
                    <li className="nav-item">
                      <a className={"nav-link" + (self.state.selectedTab==='more'?' active':'')}
                         id="custom-content-more-tab" 
                         data-toggle="pill" 
                         href="#custom-content-more" 
                         role="tab" 
                         aria-controls="custom-content-more" 
                         aria-selected="false"
                         onClick={(e)=>{
                          self.setState({selectedTab:'more'})
                         }}>More...</a>
                    </li>
                  </ul>
                  <div className="tab-content" id="custom-content-below-tabContent">
                    <div className={"tab-pane fade show" + (self.state.selectedTab==='description'?' active':'')} 
                         id="custom-content-description" 
                         role="tabpanel" 
                         aria-labelledby="custom-content-description-tab">
                       
                            <textarea 
                              className="form-control" 
                              rows="10" 
                              onChange={(e)=>{self.updateField('description',e.target.value)}}
                              value={this.state.story.description}
                              placeholder="Description..."/>
                    </div>
                    <div className={"tab-pane fade show" + (self.state.selectedTab==='photos'?' active':'')} 
                         id="custom-content-photos" 
                         role="tabpanel" 
                         aria-labelledby="custom-content-photos-tab">
                       
                        <div className="col-2">
                  <PhotoEditor 
                  text="New Photo" 
                  onImageBlob={(data)=>{
                    self.newPhoto(data.blobFile)
                  }}/>
                </div>
                      {gallery}
                    </div>
                    <div className={"tab-pane fade show" + (self.state.selectedTab==='more'?' active':'')} 
                         id="custom-content-more" 
                         role="tabpanel" 
                         aria-labelledby="custom-content-more-tab">
                       
                      <button type="button" className="btn btn-block btn-danger" onClick={()=>{self.delete()}}>delete</button>
                    </div>
                  </div>
                </div>

            </form>
            </div>
	        {
	        //<!-- /.card-body -->
	        }
          </div>
          {
          	//<!-- /.card -->
      	  }
        </div>
        {
        //<!-- /.col -->
    	}
      </div>
      {
      //<!-- /.row -->
  }
    </section>
	</div>

      );
  }
}

export default CustomerStory;
