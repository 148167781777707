import React, { Component } from 'react';
import swal from "sweetalert";
import axios from "axios";

import './orders.css';

import DatePicker from "react-datepicker";
import setMinutes from "date-fns/setMinutes";
import setHours from "date-fns/setHours";
import "react-datepicker/dist/react-datepicker.css";


import Gallery from 'react-grid-gallery';

import PhotoEditor from "../photoEditor/photoEditor";

const trackingTemplate = {
	usps:'https://tools.usps.com/go/TrackConfirmAction?tLabels=XXXXXXXXXX',
	ups:'http://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=1ZXXXXXXXXXX',
	fedex:'http://www.fedex.com/Tracking?action=track&tracknumbers=XXXXXXXXXX',
	laser:'http://www.lasership.com/track/LSXXXXXXXXXX',
	dhlus:'http://track.dhl-usa.com/TrackByNbr.asp?ShipmentNumber=XXXXXXXXXX',
	dhlgl:'http://webtrack.dhlglobalmail.com/?trackingnumber=XXXXXXXXXX',
	ontrac:'http://www.ontrac.com/trackingdetail.asp?tracking=XXXXXXXXXX',
	icc:'http://iccworld.com/track.asp?txtawbno=XXXXXXXXXX',
	canadapost:'http://www.canadapost.ca/cpotools/apps/track/personal/findByTrackNumber?trackingNumber=XXXXXXXXXX',
	indiapost:'http://ipsweb.ptcmysore.gov.in/ipswebtracking/IPSWeb_item_events.asp?itemid=XXXXXXXXXX'
}


const DatepickerInput = ({ ...props }) => (
  <input type="text" {...props} readOnly />
);

class Order extends Component {

  constructor(props){
  	super(props)

  	let qvals={}
  	let selectedTab

  	(window.location.href.split('?')[1]||'').split('&').map((vv)=>{
    	let vx=vv.split('=')
    	qvals[vx[0]]=vx[1]
	})
	if(qvals.selectedTab){
		selectedTab = qvals.selectedTab
	}

  	this.state={
  		currentImage:null,
  		selectedTab:selectedTab||'description',
  		isDirty:false,
  		orderId:window.location.href.split('/order/')[1].split('/').slice(0)[0],
  		order:{},
  		photos:[],
      	ut:this.parseJwt()
  	}

  	this.fetch()
  }
  parseJwt() {
    let token = localStorage.getItem("jwtToken");
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function(c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  fetchPhotos(){
	let self=this

    axios
      .get("/api/photos/orders/"+self.state.orderId)
      .then(res => {
        if (res.data) {
        	self.setState({photos:res.data})
        }
        else{

        	self.setState({photos:{}})	
        }
        // else if (res.data.result === "error") {
        	console.log({photos:res});
        // }
      })
      .catch(error => {
        console.log(error);
      });

  }
  fetch(){
	let self=this

    axios
      .get("/api/orders/view/"+self.state.orderId)
      .then(res => {
        if (res.data) {
        	self.setState({order:res.data},self.fetchPhotos)
        }
        else{

        	self.setState({order:{}})	
        }
        // else if (res.data.result === "error") {
        	console.log({order:res});
        // }
      })
      .catch(error => {
        console.log(error);
      });

  }

  updateField(field,value){
    let order = this.state.order
    order[field]=value
    this.setState({order,isDirty:true})
  }
  updateFields(fields,values){
    let order = this.state.order
    fields.forEach((f,fx)=>{
    	order[f]=values[fx]	
    })
    
    this.setState({order,isDirty:true})
  }

  makeSale(){
  	let self=this

  	self.update(()=>{

  		let {type,title,description,sku,customer,price,transaction,_id} = self.state.order
		let user=self.state.ut.id
		//{type,title,description,instructions,customer,user,price}

		axios.post('/api/sales',{type,title,description,sku,customer,price,transaction,user,order:_id}).then( (data) => {
			let sale = data.data

console.log({sale})

			swal({
				title:"Success!",
				text:'Sale created successfully',
				type:"success",
				timer:1000
			}).then(value => {
        		window.location='/sale/'+sale._id
			});
	    }).catch((e) => {
	    	console.log('Creation failed, Error ',e)
			// self.setState({errorMessage:e})
		});

  		console.log('updated')
  	})

  }
  update(callback){
    let self=this
    let order = this.state.order

    axios.put('/api/orders/'+order._id,{order}).then( (data) => {
      console.log(`Customer updated successfully`);

		if(callback){
			callback()
		}
		else{
			swal({
				title:"Success!",
				text:'Order updated successfully',
				type:"success",
				timer:1000
			}).then(value => {
	      			window.location='/order/'+order._id+'?t='+new Date().getTime() + '&selectedTab='+self.state.selectedTab	
			});
		}
    }).catch((e) => {
    	console.log('Creation failed, Error ',e)
    	swal("Error!", e.message, "error");
		// self.setState({errorMessage:e})
	});
  }

  delete(){

  	let self=this
  	if(this.state.photos && this.state.photos.length){
  		swal("Error!", "You must first delete all photos associated with this order.", "error");
  		return
  	}

    swal("Delete this order?", {
  		icon: "warning",
      buttons: {
        nope: {
          text: "Cancel",
          value: "nope"
        },
        sure: {
          className: "btn-danger",
          text: "I'm, Sure",
          value: "sure"
        }
      }
    }).then(value => {

      switch (value) {
        case "sure":
          	console.log('Deleted')
				axios.delete('/api/orders/'+self.state.order._id, {})
				.then( (data) => {
			      console.log(`Order deleted successfully`);
		      		window.location='/orders'
			      // console.log(data)
			      	// self.props.addPhotoToModel(data.data.filename)
					// self.setState({
					// 	selectedNote:data.data,
					// },self.filterResults)
			    }).catch((e) => {
			    	console.log('Order delete failed, Error ',e)
					// self.setState({errorMessage:e})
				});
          break;
        case "nope":
          	console.log('Didnt Delete (cancel)')
          break;
        default:
          	console.log('Didnt Delete (dismissed)')
          // swal("Got away safely!");
          break;
      }
    });
  }

	updatePhoto(field,value){
		let photos = this.state.photos
		photos[this.state.currentImage][field]=value
		this.setState({photos})
		console.log({updatePhoto:{field,value}})
	}

  	savePhoto(){
  		let photo = this.state.photos[this.state.currentImage]


		axios.put('/api/photos/'+photo._id, {photo})
		.then( (data) => {
	      console.log(`Item updated successfully`);
	      // self.fetchPhotos()
	      console.log(data)
	      	// self.props.addPhotoToModel(data.data.filename)
			// self.setState({
			// 	selectedNote:data.data,
			// },self.filterResults)
	    }).catch((e) => {
	    	console.log('Photo update failed, Error ',e)
			// self.setState({errorMessage:e})
		});
  	}


	newPhoto(fileObj){
		let self=this
		console.log(this.state)

		var formData = new FormData();
		formData.append("file", fileObj);
		formData.append("type", 'orders');
		formData.append("refId", this.state.order._id);

		axios.post('/api/photos', formData, {
		    headers: {
		      'Content-Type': 'multipart/form-data'
		    }
		})
		.then( (data) => {
	      console.log(`Item created successfully`);
	      self.fetchPhotos()
	      console.log(data)
	      	// self.props.addPhotoToModel(data.data.filename)
			// self.setState({
			// 	selectedNote:data.data,
			// },self.filterResults)
	    }).catch((e) => {
	    	console.log('Creation failed, Error ',e)
			// self.setState({errorMessage:e})
		});

	}

	deletePhoto(){
		let self=this
  		let photo = this.state.photos[this.state.currentImage]


		axios.delete('/api/photos/'+photo._id, {})
		.then( (data) => {
	      console.log(`Photo deleted successfully`);
      		window.location='/order/'+self.state.order._id+'?t='+new Date().getTime() + '&selectedTab='+self.state.selectedTab
	      // console.log(data)
	      	// self.props.addPhotoToModel(data.data.filename)
			// self.setState({
			// 	selectedNote:data.data,
			// },self.filterResults)
	    }).catch((e) => {
	    	console.log('Photo delete failed, Error ',e)
			// self.setState({errorMessage:e})
		});
		// let photo = this.state.order.photos[this.state.currentImage]
		// this.props.deleteModelPhoto(photo)
		// console.log({deletePhoto:photo})
		// console.log({deletePhoto:this.state.currentImage})
	}

	onCurrentImageChange(index) {
		console.log({onCurrentImageChange:index})
        this.setState({ currentImage: index });
    }



  render() {
		let self=this


		let updateButton=null
		if(self.state.isDirty){
			updateButton=(

	          <ol key='update_button' className="breadcrumb float-sm-right">
	            <li style={{marginRight: '10px'}}>         	
	          		<button type="button" className="btn btn-block btn-success" onClick={()=>{self.update()}}>Update</button>
	            </li>
	          </ol>
			)
		}

		let converSaleButton = (

	          <ol key='transaction_button' className="breadcrumb float-sm-right">
	            <li style={{marginRight: '10px'}}>         	
	          		<button type="button" className="btn btn-block btn-primary" onClick={()=>{
    					self.makeSale()
    				}}>Make Sale</button>
	            </li>
	          </ol>

		)


        let galleryPhotos=(this.state.photos||[]).map((p,pid)=>{
			return {
		        src: axios.defaults.baseURL + "/api/photos/"+p.type+'/'+p.fileName,
		        thumbnail: axios.defaults.baseURL + "/api/photos/"+p.type+'/'+p.fileName,
		        // thumbnailWidth: 80,
		        // thumbnailHeight: 190,
		        // isSelected: true,
		        // caption: "After Rain (Jeshu John - designerspics.com)"
			}
		})

		let currentImageDetails = ''
		if(this.state.photos && this.state.photos.length){
			if(this.state.currentImage!=null && !isNaN(this.state.currentImage) && this.state.photos[this.state.currentImage] && this.state.photos[this.state.currentImage].details){
				currentImageDetails = this.state.photos[this.state.currentImage].details
			}
		}
		let gallery = null
		if(galleryPhotos.length){
			gallery = (

        		<Gallery key="_gallery"
        			rowHeight={100}
        			enableImageSelection={false} 
					backdropClosesModal={true}
					showLightboxThumbnails={true}
        			images={galleryPhotos}
					currentImageWillChange={(index)=>self.onCurrentImageChange(index)}
					customControls={[

                    	<button key="deleteImage" onClick={()=>self.deletePhoto()}>Delete</button>,

						<textarea 
							className="form-control photo-description-textarea" 
							rows="7" 
							onChange={(e)=>{self.updatePhoto('details',e.target.value)}}
							onBlur={()=>{self.savePhoto()}}
							value={currentImageDetails}
							placeholder="Photo Details..."/>
					]}/>
			)
		}

		let trackingLink = null;

		if((this.state.order.shippingMethod||'').trim().length && this.state.order.shippingMethod !== 'other' && (this.state.order.tracking||'').trim().length){
			let trackingUrl = trackingTemplate[this.state.order.shippingMethod].replace('XXXXXXXXXX',this.state.order.tracking)

			trackingLink = (
				<span>&nbsp;
					<a target="_blank" href={trackingUrl}>track</a>
				</span>
			)
		}

    return (
    <div className="content-wrapper">
	  {/* Content Header (Page header) */}
	  <div className="content-header">
	    <div className="container-fluid">
	      <div className="row mb-2">
	        <div className="col-sm-12">

	          <h1 className="m-0 text-dark float-sm-left">
            	{self.state.order.title}
	          </h1>
	          {converSaleButton}
	          {updateButton}
	        </div>{/* /.col */}
	      </div>{/* /.row */}
	    </div>{/* /.container-fluid */}
	  </div>
	  {/* /.content-header */}
	  {/* Main content */}



    <section className="content">
      <div className="row">
        <div className="col-12">

          <div className="card">
          	{
            // <div className="card-header">
            // 	<h3>
            // 		{name}
            // 	</h3>
            // </div>
        	}
            {
            // <!-- /.card-header -->
            //const newOrder = new Order({type,title,description,instructions,customer,user,price});
        	}
            <div className="card-body">

      			<div className="row">
        			<div className="col-6">

                <label>Title</label>

                      <input
                        onChange={(e)=>{self.updateField('title',e.target.value)}}
                        value={this.state.order.title||''}                
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off" 
                        type="text"
                        className="form-control"
                        id="title"
                        placeholder="Title"
                      />

                <label>Type</label>
                    <select 
                      className="form-control select2"
                        onChange={(e)=>{self.updateField('type',e.target.value||'')}}
                        value={this.state.order.type}>
                      <option value="">Choose Type</option>
                      <option value="bracelet">Bracelet</option>
                      <option value="earrings">Earrings</option>
                      <option value="ring">Ring</option>
                      <option value="necklace">Necklace</option>
                      <option value="watch">Watch</option>
                      <option value="other">Other</option>
                    </select>
	                  

        			</div>
        			<div className="col-6">

        			  <label>Price</label>
                      <input
                        onChange={(e)=>{self.updateField('price',e.target.value.replace(/[^0-9\.]/gi,''))}}
                        value={'$'+this.state.order.price||0}                
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off" 
                        type="text" pattern="\d*"
                        className="form-control"
                        id="price"
                        placeholder="Price"
                        onBlur={(e)=>{
                        	if(isNaN(+this.state.order.price)){
                        		self.updateField('price','0.00')
                        	}
                        	else{
                        		self.updateField('price',(+this.state.order.price).toFixed(2))	
                        	}
                        }}
                      />

                <label>Vendor</label>

                      <input
                        onChange={(e)=>{self.updateField('vendor',e.target.value)}}
                        value={this.state.order.vendor||''}                  
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off" 
                        type="text"
                        className="form-control"
                        id="vendor"
                        placeholder="vendor"
                      />

                <label>Sku</label>

                      <input
                        onChange={(e)=>{self.updateField('sku',e.target.value)}}
                        value={this.state.order.sku||''}                 
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off" 
                        type="text" pattern="\d*"
                        className="form-control"
                        id="sku"
                        placeholder="sku"
                      />
{
//         			  <label>Shipping Method</label>

// 	                  <select 
// 	                  	className="form-control select2"
//                         onChange={(e) => this.updateField('shippingMethod',e.target.value)}
//                         value={this.state.order.shippingMethod}>
// 	                    <option value="">Choose Shipping Method</option>
// 	                    <option value="usps">USPS</option>
// 	                    <option value="fedex">FedEx</option>
// 	                    <option value="ups">UPS</option>
// 	                    <option value="dhlus">DHL (US)</option>
// 	                    <option value="dhlgl">DHL (Global)</option>
// 	                    <option value="laser">Lasership</option>
// 	                    <option value="ontrac">OnTrac</option>
// 	                    <option value="icc">ICC World</option>
// 	                    <option value="canadapost">Canada Post</option>
// 	                    <option value="indiapost">India Post</option>
// 	                    <option value="other">Other</option>
// 	                  </select>


//         			  <label>Tracking Code</label>&nbsp;{trackingLink}

//                       <input
//                         onChange={(e)=>{self.updateField('tracking',e.target.value)}}
//                         value={this.state.order.tracking||''}                
//                         autoComplete="off"
//                         autoCorrect="off"
//                         autoCapitalize="off" 
//                         type="text"
//                         className="form-control"
//                         id="tracking"
//                         placeholder="Tracking Code"
//                       />


//         			  <label>Expected Delivery</label>

//                       <DatePicker
//             			selected={this.state.order.delivery?new Date(this.state.order.delivery):new Date()}
//                         onChange={date => this.updateField('delivery',date)}
//                         showTimeSelect
//                         timeFormat="HH:mm"
//                         timeIntervals={15}
//                         timeCaption="time"
//                         minTime={setHours(setMinutes(new Date(), 30), 9)}
//                         maxTime={setHours(setMinutes(new Date(), 0), 19)}
//                         dateFormat="yyyy-MM-dd h:mm aa"
//                         customInput={<DatepickerInput className="form-control" />}
//                       />
}
        			</div>

			          <div className="col-12">&nbsp;</div>

			          <div className="col-12">
			            <ul className="nav nav-tabs" id="custom-content-below-tab" role="tablist">
			              <li className="nav-item">
			                <a className={"nav-link" + (self.state.selectedTab==='description'?' active':'')}
			                   id="custom-content-description-tab" 
			                   data-toggle="pill" 
			                   href="#custom-content-description" 
			                   role="tab" 
			                   aria-controls="custom-content-description" 
			                   aria-selected="false"
			                   onClick={(e)=>{
			                   	self.setState({selectedTab:'description'})
			                   }}>Description</a>
			              </li>
			              <li className="nav-item">
			                <a className={"nav-link" + (self.state.selectedTab==='notes'?' active':'')}
			                   id="custom-content-notes-tab" 
			                   data-toggle="pill" 
			                   href="#custom-content-notes" 
			                   role="tab" 
			                   aria-controls="custom-content-notes" 
			                   aria-selected="false"
			                   onClick={(e)=>{
			                   	self.setState({selectedTab:'notes'})
			                   }}>Notes</a>
			              </li>
			              <li className="nav-item">
			                <a className={"nav-link" + (self.state.selectedTab==='photos'?' active':'')}
			                   id="custom-content-photos-tab" 
			                   data-toggle="pill" 
			                   href="#custom-content-photos" 
			                   role="tab" 
			                   aria-controls="custom-content-photos" 
			                   aria-selected="false"
			                   onClick={(e)=>{
			                   	self.setState({selectedTab:'photos'})
			                   }}>Photos</a>
			              </li>
			              <li className="nav-item">
			                <a className={"nav-link" + (self.state.selectedTab==='more'?' active':'')}
			                   id="custom-content-more-tab" 
			                   data-toggle="pill" 
			                   href="#custom-content-more" 
			                   role="tab" 
			                   aria-controls="custom-content-more" 
			                   aria-selected="false"
			                   onClick={(e)=>{
			                   	self.setState({selectedTab:'more'})
			                   }}>More...</a>
			              </li>
			            </ul>
			            <div className="tab-content" id="custom-content-below-tabContent">
			              <div className={"tab-pane fade show" + (self.state.selectedTab==='description'?' active':'')} 
			              	   id="custom-content-description" 
			              	   role="tabpanel" 
			              	   aria-labelledby="custom-content-description-tab">
			                 
			                      <textarea 
			                      	className="form-control" 
			                      	rows="10" 
			                        onChange={(e)=>{self.updateField('description',e.target.value)}}
			                        value={this.state.order.description}
			                      	placeholder="Description..."/>
			              </div>
			              <div className={"tab-pane fade show" + (self.state.selectedTab==='notes'?' active':'')} 
			              	   id="custom-content-notes" 
			              	   role="tabpanel" 
			              	   aria-labelledby="custom-content-notes-tab">
			                 
			                      <textarea 
			                      	className="form-control" 
			                      	rows="10" 
			                        onChange={(e)=>{self.updateField('notes',e.target.value)}}
			                        value={this.state.order.notes}
			                      	placeholder="Notes..."/>
			              </div>
			              <div className={"tab-pane fade show" + (self.state.selectedTab==='photos'?' active':'')} 
			              	   id="custom-content-photos" 
			              	   role="tabpanel" 
			              	   aria-labelledby="custom-content-photos-tab">
			                 
                        <div className="col-2">
                          <PhotoEditor 
                          text="New Photo" 
                          onImageBlob={(data)=>{
                            self.newPhoto(data.blobFile)
                          }}/>
                        </div>
			            		{gallery}
			              </div>
			              <div className={"tab-pane fade show" + (self.state.selectedTab==='more'?' active':'')} 
			              	   id="custom-content-more" 
			              	   role="tabpanel" 
			              	   aria-labelledby="custom-content-more-tab">
			                 
	          					<button type="button" className="btn btn-block btn-danger" onClick={()=>{self.delete()}}>delete</button>
			              </div>
			            </div>
			          </div>

        		</div>

            </div>
	        {
	        //<!-- /.card-body -->
	        }
          </div>
          {
          	//<!-- /.card -->
      	  }
        </div>
        {
        //<!-- /.col -->
    	}
      </div>
      {
      //<!-- /.row -->
  }
    </section>
	</div>

      );
  }
}

export default Order;
