import React, { Component } from 'react';
import swal from "sweetalert";
import axios from "axios";

import './sales.css';

class Sales extends Component {

  constructor(props){
  	super(props)

  	this.state={
      customerId:localStorage.getItem("currentCustomer"),
  		searchText:'',
  		sales:[],
      ut:this.parseJwt()
  	}

  	this.fetch()
  }
  parseJwt() {
    let token = localStorage.getItem("jwtToken");
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function(c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  fetch(){
	let self=this

	let apiUrl = null

	if(self.state.customerId){
		apiUrl = "/api/sales/customer/"+self.state.customerId


		if(self.state.searchText.length>0 && self.state.searchText.length<3){
			self.setState({sales:[]})
			return
		}
		else if(self.state.searchText.length>=3){

			apiUrl = "/api/sales/customer/"+self.state.customerId + '/' + self.state.searchText.trim()
		}
	}

    axios
      // .get("/api/sales/"+self.state.searchText)
      .get(apiUrl)
      .then(res => {
        if (res.data && res.data.length) {
        	self.setState({sales:res.data})
        }
        else{

        	self.setState({sales:[]})	
        }
        // else if (res.data.result === "error") {
        	console.log({sales:res});
        // }
      })
      .catch(error => {
        console.log(error);
      });

  }

  createSale(){

		let self=this
		let customer=self.state.customerId
		let user=self.state.ut.id
		//{type,title,description,instructions,customer,user,price}

		axios.post('/api/sales',{customer,user,title:'NEW SALE'}).then( (data) => {
			let sale = data.data

console.log({sale})
        swal({
          title:"Success!",
          text:'Sale created successfully',
          type:"success",
          timer:1000
        }).then(value => {
              window.location='/sale/'+sale._id
        });
	    }).catch((e) => {
	    	console.log('Creation failed, Error ',e)
			// self.setState({errorMessage:e})
		});
  }




  render() {
		let self=this
    return (
    <div className="content-wrapper">
	  {/* Content Header (Page header) */}
	  <div className="content-header">
	    <div className="container-fluid">
	      <div className="row mb-2">
	        <div className="col-sm-6">
	          <h1 className="m-0 text-dark">
	          Sales
	          </h1>
	        </div>{/* /.col */}

	        <div className="col-sm-6">
	          <ol className="breadcrumb float-sm-right">
	            <li style={{marginLeft:'20px'}}>           	
	          		<button type="button" className="btn btn-block btn-success" onClick={()=>{self.createSale()}}>New</button>
	            </li>
	          </ol>
	        </div>{/* /.col */}
	      </div>{/* /.row */}
	    </div>{/* /.container-fluid */}
	  </div>
	  {/* /.content-header */}
	  {/* Main content */}



    <section className="content">
      <div className="row">
        <div className="col-12">

          <div className="card">
            <div className="card-header">
		        <div className="col-sm-6">
		          <div className="input-group input-group-sm">
		            <input
		              id="customer-search"
		              className="form-control form-control-navbar"
		              style={{borderRightWidth: '1px'}}                
                  autoComplete="off"
                  autoCorrect="off"
                  autoCapitalize="off" 
		              type="search"
		              placeholder="Search"
		              aria-label="Search"
		              value={self.state.searchText}
		              onChange={(e)=>{self.setState({searchText:e.target.value},()=>{
		              	self.fetch()
		              })}}
		            />
		            <div className="input-group-append">
		              <div className="btn btn-navbar">
		                <i className="fas fa-search" />
		              </div>
		            </div>
		          </div>
	          	</div>
            </div>
            {
            // <!-- /.card-header -->
        	}
            <div className="card-body">
              <table id="example1" className="table table-bordered table-striped">
                <thead>
                <tr>
                  <th>SKU</th>
                  <th>Type</th>
                  <th>Title</th>
                  <th>Description</th>
                  <th>Price</th>
                </tr>
                </thead>
                <tbody>
                {self.state.sales.map((r,rx)=>{
                	let elipsis = {
                		maxWidth: '20vw',
    					overflow: 'hidden',
    					whiteSpace: 'nowrap',
    					textOverflow: 'ellipsis'
    				}
    				let row_bg = ''
    				if(!r.transaction){
    					row_bg = 'bg-pending'
    					if(rx%2==1){
    						row_bg = 'bg-pending-alt'
    					}
    				}
                	return(

		                <tr key={'r_'+r._id} className={row_bg} onClick={()=>{
		                	window.location='/sale/'+r._id
		                }}>
                      <td>{r.sku}</td>
                      <td>{r.type}</td>
		                  <td style={elipsis}>{r.title}</td>
		                  <td style={elipsis}>{r.description}</td>
		                  <td style={{textAlign: 'right'}}>${(r.price||0).toFixed(2)}</td>
		                </tr>
                	)
                })}
                </tbody>
                {
                // <tfoot>
                // <tr>
                //   <th>Name</th>
                //   <th>Address</th>
                //   <th>City</th>
                //   <th>State</th>
                //   <th>Zip</th>
                // </tr>
                // </tfoot>
            	}
              </table>
            </div>
	        {
	        //<!-- /.card-body -->
	        }
          </div>
          {
          	//<!-- /.card -->
      	  }
        </div>
        {
        //<!-- /.col -->
    	}
      </div>
      {
      //<!-- /.row -->
  }
    </section>
	</div>

      );
  }
}

export default Sales;
