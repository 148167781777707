import React, { Component } from 'react';
import swal from "sweetalert";
import axios from "axios";

// import './repairs.css';

class WebOrders extends Component {

    constructor(props) {
        super(props)

        this.state = {
            customerId: localStorage.getItem("currentCustomer"),
            layawayFilter: (window.location.href.toLowerCase().indexOf('/layaway')>=0),
            searchText: '',
            transactions: [],
            ut: this.parseJwt()
        }

        this.fetch()
    }
    parseJwt() {
        let token = localStorage.getItem("jwtToken");
        var base64Url = token.split(".")[1];
        var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        var jsonPayload = decodeURIComponent(
            atob(base64)
            .split("")
            .map(function(c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
        );

        return JSON.parse(jsonPayload);
    }

    fetch() {
        let self = this

        let apiUrl = "/api/transactions/web/all/"

        // if (self.state.customerId) {
        //     apiUrl = "/api/transactions/customer/" + self.state.customerId

	    //     if(self.state.layawayFilter){
	    //     	apiUrl += '/layaway'
	    //     }


        //     if (self.state.searchText.length > 0 && self.state.searchText.length < 3) {
        //         self.setState({
        //             transactions: []
        //         })
        //         return
        //     }
        //     else if (self.state.searchText.length >= 3) {
	    //     	apiUrl += ('/' + self.state.searchText.trim())
        //     }
        // }

        axios
        // .get("/api/repairs/"+self.state.searchText)
            .get(apiUrl)
            .then(res => {
                if (res.data && res.data.length) {
                    self.setState({
                        transactions: res.data
                    })
                }
                else {

                    self.setState({
                        transactions: []
                    })
                }
                // else if (res.data.result === "error") {
                console.log({
                    transactions: res
                });
                // }
            })
            .catch(error => {
                console.log(error);
            });

    }

    // createTransaction() {

    //     let self = this
    //     let customer = self.state.customerId
    //     let user = self.state.ut.id
    //     let layaway = self.state.layawayFilter
    //         //{type,title,description,instructions,customer,user,price}

    //     axios.post('/api/transactions', {
    //         customer,
    //         user,
    //         layaway
    //     }).then((data) => {
    //         let transaction = data.data

    //         console.log({
    //             transaction
    //         })
	// 		swal({
	// 			title:"Success!",
	// 			text:(layaway?'Layaway':'Transaction') + ' created successfully',
	// 			type:"success",
	// 			timer:1000
	// 		}).then(value => {
    //             self.fetch()
	// 		});
    //         // swal("Success!", transaction.status_message || 'transaction created successfully', "success").then(value => {
    //         //     // console.log({data})

    //         //     // window.location='/transaction/'+transaction._id
    //         //     // self.setState({searchText:'',repairs:[data.data]})
    //         //     self.fetch()
    //         // });
    //     }).catch((e) => {
    //         console.log('Creation failed, Error ', e)
    //             // self.setState({errorMessage:e})
    //     });
    // }




  render() {
		let self=this
    return (
    <div className="content-wrapper">
	  {/* Content Header (Page header) */}
	  <div className="content-header">
	    <div className="container-fluid">
	      <div className="row mb-2">
	        <div className="col-sm-6">
	          <h1 className="m-0 text-dark">
	           {self.state.layawayFilter?'All Layaways':'Web Orders'}
	          </h1>
	        </div>{/* /.col */}

	        <div className="col-sm-6">
	          <ol className="breadcrumb float-sm-right">
                <li style={{marginLeft:'20px'}}>            
                    {/* <button type="button" className="btn btn-block btn-primary" onClick={()=>{
                        window.location = '/transactions'
                    }}>Overview</button> */}
                </li>
	            <li style={{marginLeft:'20px'}}>           	
	          		{/* <button type="button" className="btn btn-block btn-success" onClick={()=>{self.createTransaction()}}>New</button> */}
	            </li>
	          </ol>
	        </div>{/* /.col */}
	      </div>{/* /.row */}
	    </div>{/* /.container-fluid */}
	  </div>
	  {/* /.content-header */}
	  {/* Main content */}



    <section className="content">
      <div className="row">
        <div className="col-12">

          <div className="card">
          {
          //   <div className="card-header">
		        // <div className="col-sm-6">
		        //   <div className="input-group input-group-sm">
		        //     <input
		        //       id="customer-search"
		        //       className="form-control form-control-navbar"
		        //       style={{borderRightWidth: '1px'}}
		        //       type="search"
		        //       placeholder="Search"
		        //       aria-label="Search"
		        //       value={self.state.searchText}
		        //       onChange={(e)=>{self.setState({searchText:e.target.value},()=>{
		        //       	self.fetch()
		        //       })}}
		        //     />
		        //     <div className="input-group-append">
		        //       <div className="btn btn-navbar">
		        //         <i className="fas fa-search" />
		        //       </div>
		        //     </div>
		        //   </div>
	         //  	</div>
          //   </div>
        	}
            {
            // <!-- /.card-header -->
        	}
            <div className="card-body">
              <table id="example1" className="table table-bordered table-striped">
                <thead>
                <tr>
                  <th>Ref</th>
                  <th>Created</th>
                  <th>Total</th>
                  <th>Balance</th>
                </tr>
                </thead>
                <tbody>
                {self.state.transactions.map((t,tx)=>{
                	let elipsis = {
                		maxWidth: '20vw',
    					overflow: 'hidden',
    					whiteSpace: 'nowrap',
    					textOverflow: 'ellipsis'
    				}
    				let row_bg = ''
    				// if(!t.balance>0){
    				// 	row_bg = 'bg-pending'
    				// 	if(tx%2==1){
    				// 		row_bg = 'bg-pending-alt'
    				// 	}
    				// }
                    let repairDesc= null
                    let saleDesc = null
                    if(t.repairDesc && t.repairDesc.length){
                        repairDesc = (
                            <div>
                                <b>Repairs:</b> {t.repairDesc}
                            </div>
                        )
                    }
                    if(t.saleDesc && t.saleDesc.length){
                        saleDesc = (
                            <div>
                                <b>Sales:</b> {t.saleDesc}
                            </div>
                        )
                    }
                	return(

		                <tr key={'t_'+t._id} className={row_bg} onClick={()=>{
                            localStorage.setItem("currentCustomer", t.customer._id);
		                	window.location='/transaction/'+t._id
		                }}>
		                  <td>
                            <div>{[t.customer.lastName,t.customer.firstName].join(', ')}</div>
                            <div>{t.refId}<b>{t.layaway?' (Layaway)':''}</b><br/>{t._id}</div>
                            {repairDesc}
                            {saleDesc}
                          </td>
		                  <td style={{whiteSpace: 'nowrap'}}>{(t.created||'').split('T')[0]}</td>
		                  <td style={{textAlign: 'right'}}>${(t.lineItemTotal+t.taxTotal).toFixed(2)}</td>
		                  <td style={{textAlign: 'right'}}>${t.balance.toFixed(2)}</td>
		                </tr>
                	)
                })}
                </tbody>
              </table>
            </div>
	        {
	        //<!-- /.card-body -->
	        }
          </div>
          {
          	//<!-- /.card -->
      	  }
        </div>
        {
        //<!-- /.col -->
    	}
      </div>
      {
      //<!-- /.row -->
  	}
    </section>
	</div>

      );
  }
}

export default WebOrders;
