import React, { Component } from "react";
import swal from "sweetalert";
import { withRouter, Link } from "react-router-dom";
class Header extends Component {


  constructor(props){
    super(props);

    this.state={
      searchText:'',
      ut:this.parseJwt()
    }
  }
  parseJwt() {
    let token = localStorage.getItem("jwtToken");
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function(c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }


  Logout = () => {
    swal("Are your sure SignOut?", {
      buttons: {
        nope: {
          text: "Let me back",
          value: "nope"
        },
        sure: {
          text: "I'm, Sure",
          value: "sure"
        }
      }
    }).then(value => {

      console.log({SignoutAnswer:value})
      switch (value) {
        case "sure":
            localStorage.removeItem("currentCustomer");
            localStorage.removeItem("jwtToken");
            window.location='/login'
          break;
        case "nope":
          // swal("Ok", "success");
          break;
        default:
          // swal("Got away safely!");
      }
    });
  };
  render() {
    let self=this

    let adminActions = []
      if(this.state.ut.admin){
        adminActions.push(
              <Link key="a_manage_users" to={'/admin/users'} className="dropdown-item">
                <i className="fas fa-user-alt mr-2" /> Admin: Manage Users
              </Link>
        )
        adminActions.push(
              <Link key="a_status" to={'/admin/status'} className="dropdown-item">
                <i className="fas fa-cogs mr-2" /> Admin: System Status
              </Link>
        )
        adminActions.push(
              <Link key="a_report" to={'/admin/reports'} className="dropdown-item">
                <i className="fas fa-chart-line mr-2" /> Admin: Reports
              </Link>
        )
    }
    return (
      <nav className="main-header navbar navbar-expand navbar-white navbar-light">
        {/* Left navbar links */}
        <ul className="navbar-nav">
          <li className="nav-item">
            <a className="nav-link" data-widget="pushmenu" href="#">
              <i className="fas fa-bars" />
            </a>
          </li>
        {
          // <li className="nav-item">
          //   <a href="/dashboard" className="nav-link">
          //     Home
          //   </a>
          // </li>
        }
          <li className="nav-item">
            <a href="/weborders" className="nav-link" onClick={(e)=>{
              localStorage.removeItem("currentCustomer")
            }}>
              Web Orders
            </a>
          </li>
          <li className="nav-item">
            <a href="/products" className="nav-link" onClick={(e)=>{
              localStorage.removeItem("currentCustomer")
            }}>
              Products
            </a>
          </li>
          <li className="nav-item">
            <a href="/customers" className="nav-link" onClick={(e)=>{
              localStorage.removeItem("currentCustomer")
            }}>
              Customers
            </a>
          </li>
          <li className="nav-item">
            <a href="/allTransactions" className="nav-link" onClick={(e)=>{
              localStorage.removeItem("currentCustomer")
            }}>
              Transactions
            </a>
          </li>
          <li className="nav-item">
            <a href="/repairs" className="nav-link" onClick={(e)=>{
              localStorage.removeItem("currentCustomer")
            }}>
              Repairs
            </a>
          </li>
          {/* <li className="nav-item">
            <a href="/customerStories" className="nav-link" onClick={(e)=>{
              localStorage.removeItem("currentCustomer")
            }}>
              Stories
            </a>
          </li> */}
          <li>
            
        <ul className="navbar-nav ml-auto">
          {/* Messages Dropdown Menu */}
          {/* Notifications Dropdown Menu */}

          <li className="nav-item dropdown">
            <a className="nav-link" data-toggle="dropdown" href="#">
              <span style={{marginRight:'4px'}}>Content</span>
            </a>

            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
              <span className="dropdown-item dropdown-header">CONTENT</span>
              <div className="dropdown-divider" />

              <a className="dropdown-item" href="#customItems" onClick={(e)=>{
                e.preventDefault(); 
                localStorage.removeItem("currentCustomer")               
                window.location='/customItems';
              }}>
                <i className="fas fa-cogs mr-2" /> Custom Items
              </a>

              <a className="dropdown-item" onClick={(e)=>{
                e.preventDefault(); 
                localStorage.removeItem("currentCustomer")               
                window.location='/homepageFeatures';
              }}>
                <i className="fas fa-home mr-2" /> Homepage Features
              </a>

              <a className="dropdown-item" href="#customerStories" onClick={(e)=>{
                e.preventDefault(); 
                localStorage.removeItem("currentCustomer")               
                window.location='/customerStories';
              }}>
                <i className="fas fa-users mr-2" /> Customer Stories
              </a>
              <div className="dropdown-divider" />
              <a className="dropdown-item" href="#coupons" onClick={(e)=>{
                e.preventDefault(); 
                localStorage.removeItem("currentCustomer")               
                window.location='/coupons';
              }}>
                <i className="fas fa-scissors mr-2" /> Coupons
              </a>
            </div>
          </li>
        </ul>
          </li>
          {/*
          <li className="nav-item">
            <a href="/allTransactions" className="nav-link" onClick={(e)=>{
              localStorage.removeItem("currentCustomer")
            }}>
              Transactions
            </a>
          </li>
*/}
        </ul>

        {/* Right navbar links */}
        <ul className="navbar-nav ml-auto">
          {/* Messages Dropdown Menu */}
          {/* Notifications Dropdown Menu */}

          <li className="nav-item dropdown">
            <a className="nav-link" data-toggle="dropdown" href="#">
              <span style={{marginRight:'4px'}}>{this.state.ut.initials}</span><i className="far fa-user" />
            </a>

            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
              <span className="dropdown-item dropdown-header">{this.state.ut.fullName}</span>
              <div className="dropdown-divider" />
              <a className="dropdown-item" onClick={()=>{
                window.location='/admin/user/'+this.state.ut.id+'?t='+new Date().getTime()
              }}>
                <i className="fas fa-user-alt mr-2" /> Update Profile
              </a>
              <div className="dropdown-divider" />
              {adminActions}
              <div className="dropdown-divider" />
              <a
                onClick={() => this.Logout()}
                className="dropdown-item"
              >
                <i className="fas fa-sign-out-alt mr-2" /> Logout
              </a>
            </div>
          </li>
        </ul>
      </nav>
    );
  }
}

export default withRouter(Header);
