import React, { Component } from 'react';
import swal from "sweetalert";
import axios from "axios";
import adminAxios from "../../utils/adminAxios";

class AdminStatus extends Component {

  constructor(props){
  	super(props)

  	this.state={
  		updating:false,
  		backingUp:false,
  		versionUpdate:null,
  		storage:null,
  		backups:null,
  		isShutDown:false
  	}

  	let self=this
	self.fetchStatus(()=>{
		self.checkStorage(()=>{
			self.checkBackups(()=>{
				self.checkForUpdates()
			})
		})
	})
  }


  fetchStatus(callback){
	let self=this


    adminAxios
      .get("/status")
      .then(res => {
        // if (res.data && res.data.length) {
        // 	self.setState({users:res.data})
        // }
        // else{

        // 	self.setState({users:[]})	
        // }
        // else if (res.data.result === "error") {
        	console.log({status:res});
        	if(callback){
        		callback()
        	}
        	// self.checkStorage()
        // }
      })
      .catch(error => {
        console.log(error);
      });

  }
  checkStorage(callback){
	let self=this


    adminAxios
      .get("/storage")
      .then(res => {
        if (res.data) {
        	self.setState({storage:res.data.storage},callback)
        }
        // else{

        // 	self.setState({users:[]})	
        // }
        // else if (res.data.result === "error") {
        	console.log({storage:res.data.storage});
        	// self.fetchTest()
        // }
      })
      .catch(error => {
        console.log(error);
      });

  }
  checkBackups(callback){
	let self=this


    adminAxios
      .get("/lastBackup")
      .then(res => {
        if (res.data) {
        	self.setState({backups:res.data},callback)
        }
        // else{

        // 	self.setState({users:[]})	
        // }
        // else if (res.data.result === "error") {
        	console.log({backups:res.data});
        	// self.fetchTest()
        // }
      })
      .catch(error => {
        console.log(error);
      });

  }
  checkForUpdates(callback){
	let self=this


    adminAxios
      .get("/checkForUpdate")
      .then(res => {
        if (res.data) {
        	self.setState({updating:false,versionUpdate:res.data},callback)
        }
        // else{

        // 	self.setState({users:[]})	
        // }
        // else if (res.data.result === "error") {
        	console.log({checkForUpdates:res});
        	// self.fetchTest()
        // }
      })
      .catch(error => {
        console.log(error);
      });

  }

  findFreeSpace(){
	let self=this

    adminAxios
      .put("/clearJournal")
      .then(res => {
        // else{

        // 	self.setState({users:[]})	
        // }
        // else if (res.data.result === "error") {
        	console.log({findFreeSpace:res});
        	self.checkStorage()
        // }
      })
      .catch(error => {
        console.log(error);
      });
  }

  installUpdates(){
	let self=this

	self.setState({updating:true},()=>{

	    adminAxios
	      .put("/installUpdate")
	      .then(res => {
	        // else{

	        // 	self.setState({users:[]})	
	        // }
	        // else if (res.data.result === "error") {
	        	console.log({checkForUpdates:res});
	        	self.checkForUpdates()
	        // }
	      })
	      .catch(error => {
	        console.log(error);
	      });
	})
  }

  backupDatabase(){
  	console.log('Backing up')
	let self=this

	self.setState({backingUp:true},()=>{

		// window.setTimeout(()=>{
		// 	self.setState({backingUp:false})
		// },3000)
	    adminAxios
	      .put("/backup")
	      .then(res => {
	        // else{

	        // 	self.setState({users:[]})	
	        // }
	        // else if (res.data.result === "error") {
	        	console.log({checkForUpdates:res});
	        	self.setState({backingUp:false},self.checkBackups)
	        // }
	      })
	      .catch(error => {
	        console.log(error);
	      });
	})

  }
  fetchTest(){
	let self=this


    axios
      .get("/test")
      .then(res => {
        // if (res.data && res.data.length) {
        // 	self.setState({users:res.data})
        // }
        // else{

        // 	self.setState({users:[]})	
        // }
        // else if (res.data.result === "error") {
        	console.log({status:res});
        // }
      })
      .catch(error => {
        console.log(error);
      });

  }

  shutDown(){

  	let self=this

    swal("Are you sure you want to shut down?", {
  		icon: "warning",
      buttons: {
        nope: {
          text: "Cancel",
          value: "nope"
        },
        sure: {
          className: "btn-danger",
          text: "I'm, Sure",
          value: "sure"
        }
      }
    }).then(value => {

      switch (value) {
        case "sure":


		    adminAxios
		      .put("/shutdown")
		      .then(res => {

		      		window.setTimeout(()=>{		      			
		        		self.setState({isShutDown:true})
		      		},120000)
					swal({
						title:"Shutting Down!",
						text:'The system has been set to shut down.  This may take a couple minutes.  A message will appear when it will be safe to turn the machine off.',
						type:"warning",
						timer:120000
					}).then(value => {
					});
		      })
		      .catch(error => {
		        console.log(error);
		      });
				// axios.delete('/api/orders/'+self.state.order._id, {})
				// .then( (data) => {
			 //      console.log(`Order deleted successfully`);
		  //     		window.location='/orders'
			 //      // console.log(data)
			 //      	// self.props.addPhotoToModel(data.data.filename)
				// 	// self.setState({
				// 	// 	selectedNote:data.data,
				// 	// },self.filterResults)
			 //    }).catch((e) => {
			 //    	console.log('Order delete failed, Error ',e)
				// 	// self.setState({errorMessage:e})
				// });

          break;
        case "nope":
          	// console.log('Didnt Delete (cancel)')
          break;
        default:
          	// console.log('Didnt Delete (dismissed)')
          // swal("Got away safely!");
          break;
      }
    });
  }


  render() {
	let self=this

	if(self.state.isShutDown){

		return(

		<div className="content-wrapper">
		  {/* Content Header (Page header) */}
		  <div className="content-header">
		    <div className="container-fluid">
		      <div className="row mb-2">
		        <div className="col-sm-6">
		          <h1 className="m-0 text-dark">
		          System Status
		          </h1>
		        </div>{/* /.col */}

		      </div>{/* /.row */}
		    </div>{/* /.container-fluid */}
		  </div>
		  {/* /.content-header */}
		  {/* Main content */}



		    <section className="content">


				<h1 style={{fontSize:'3em'}}>
					You may safely turn off the machine
				</h1>

		    </section>
		</div>
		)
	}


	let currentVersion=(
				<h2 className="lead text-right">
					<b>Loading...</b>
				</h2>
			)
	let newVersion=null
	let versionUpdateButton = null
	let viewChangelogButton = null

	let versionNumber = ''

	// this.state.versionUpdate={newVersion:'test',updateAvailable:true,changeLog:'blah'}

	if(this.state.versionUpdate){
		
		if(this.state.versionUpdate.currentVersion){
			versionNumber = ': ' + this.state.versionUpdate.currentVersion
			currentVersion = (
				<h2 className="lead text-right">
					<b>Current Version:</b>&nbsp;<b>{this.state.versionUpdate.currentVersion}</b>
				</h2>
			)
			newVersion = (
					<b>You are up-to-date!</b>
			)
		}

		if(this.state.versionUpdate.updateAvailable){

			newVersion = (
				<React.Fragment>
					<b>New Version:</b>&nbsp;{this.state.versionUpdate.newVersion}
				</React.Fragment>
			)
			if(this.state.updating){
				versionUpdateButton = (
	              <div className="text-right" style={{float:'right'}}>
	                <a onClick={()=>{}} className="btn btn-sm btn-gray" style={{color:'black',fontWeight:'bold'}}>
	                  Updating...
	                </a>
	              </div>
				)

			}
			else{			
				versionUpdateButton = (
	              <div className="text-right" style={{float:'right'}}>
	                <a onClick={()=>{self.installUpdates()}} className="btn btn-sm btn-primary" style={{color:'white',fontWeight:'bold'}}>
	                  Install
	                </a>
	              </div>
				)	
			}

			if(this.state.versionUpdate.changeLog){

				const changeLogVersion = this.state.versionUpdate.newVersion
				const changeLogContent = this.state.versionUpdate.changeLog

				viewChangelogButton = (
	              <div className="text-left">
	                <a onClick={()=>{
	                
						swal({
							html:true,
							title:"Changes: " + changeLogVersion,
							text:changeLogContent,
							
						}).then(value => {
						});
	                }} className="btn btn-sm btn-primary" style={{color:'white',fontWeight:'bold'}}>
	                  Changes
	                </a>
	              </div>
				)
			}
		}
	}


	let softwareVersionCard = (
            <div key={'sv_card'} className="col-12 col-sm-6 col-md-4 d-flex align-items-stretch">
              <div className="card bg-light" style={{width: '100%'}}>
                <div className="card-header text-muted border-bottom-0">
                  Software Version {versionNumber}
                </div>
                <div className="card-body pt-0">
                  <div className="row">
                    <div className="col-12">
                      {newVersion}
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                	{versionUpdateButton}
                	{viewChangelogButton}
                </div>
              </div>
            </div>
        )

	let storageCardDetails = (
		<h2 className="lead text-right">
			<b>Loading...</b>
		</h2>
	)

	if(this.state.storage){

		storageCardDetails = (

            <div className="row">
                <div className="col-6 text-right">
					<b>Size:</b><br/>
					<b>Used:</b><br/>
					<b>Available:</b><br/>
					<b>Capacity:</b>
                </div>
                <div className="col-6 text-left">
					{this.state.storage.size}<br/>
					{this.state.storage.used}<br/>
					{this.state.storage.avail}<br/>
					{this.state.storage.capacity}
                </div>
            </div>
        )
	}

	let storageCard = (
            <div key={'stor_card'} className="col-12 col-sm-6 col-md-4 d-flex align-items-stretch">
              <div className="card bg-light" style={{width: '100%'}}>
                <div className="card-header text-muted border-bottom-0">
                  Storage
                </div>
                <div className="card-body pt-0">
                  <div className="row">
                    <div className="col-12">
						{storageCardDetails}
                    </div>
                  </div>
                </div>
                <div className="card-footer">
	              <div className="text-right" style={{float:'right'}}>
	                <a onClick={()=>{self.findFreeSpace()}} className="btn btn-sm btn-primary" style={{color:'white',fontWeight:'bold'}}>
	                  Cleanup
	                </a>
	              </div>
                </div>
              </div>
            </div>
        )

	let backupButton = (		
          <div className="text-right">
            <a onClick={()=>{self.backupDatabase()}} className="btn btn-sm btn-success" style={{color:'white',fontWeight:'bold'}}>
              Back-up
            </a>
          </div>
	)
	if(self.state.backingUp){
		backupButton = (	
              <div className="text-right">
                <a onClick={()=>{}} className="btn btn-sm btn-gray" style={{color:'black',fontWeight:'bold'}}>
                  Backing Up...
                </a>
              </div>
		)
	}

	let latestBackups = []
	if(self.state.backups && self.state.backups.local){
		latestBackups.push(			
            <div key="back_loc" className="col-10" style={{fontSize:'.7em'}}>
					<b>LOCAL:</b><br/> {self.state.backups.local}<br/>&nbsp;
			</div>
		)
	}
	if(self.state.backups && self.state.backups.cloud){
		latestBackups.push(			
            <div key="back_clo" className="col-10" style={{fontSize:'.7em'}}>
					<b>CLOUD:</b><br/> {(self.state.backups.cloud ||'').replace('db-backup/','')}
			</div>
		)
	}

	let backupCard = (
            <div key={'bak_card'} className="col-12 col-sm-6 col-md-4 d-flex align-items-stretch">
              <div className="card bg-light" style={{width: '100%'}}>
                <div className="card-header text-muted border-bottom-0">
                  Recent DB Backups
                </div>
                <div className="card-body pt-0">
                  <div className="row">
                    <div className="col-12">
						{latestBackups}
                    </div>
                  </div>
                </div>
                <div className="card-footer">
	              {backupButton}
                </div>
              </div>
            </div>
        )

    return (
		<div className="content-wrapper">
		  {/* Content Header (Page header) */}
		  <div className="content-header">
		    <div className="container-fluid">
		      <div className="row mb-2">
		        <div className="col-sm-6">
		          <h1 className="m-0 text-dark">
		          System Status
		          </h1>
		        </div>{/* /.col */}

		        <div className="col-sm-6">
		          <ol className="breadcrumb float-sm-right">
		            <li style={{marginLeft:'20px'}}>           	
		          		<button type="button" className="btn btn-block btn-danger" onClick={()=>{self.shutDown()}}>Shut Down</button>
		            </li>
		          </ol>
		        </div>{/* /.col */}
		      </div>{/* /.row */}
		    </div>{/* /.container-fluid */}
		  </div>
		  {/* /.content-header */}
		  {/* Main content */}



		    <section className="content">


		      <div className="card card-solid">
		        <div className="card-body pb-0">
		          <div className="row d-flex align-items-stretch">

		          	{softwareVersionCard}
		          	{storageCard}
		          	{backupCard}

		          </div>
		        </div>
		      </div>

		    </section>
		</div>

      );
  }
}

export default AdminStatus;
