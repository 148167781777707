import React, { Component } from 'react';
import swal from "sweetalert";
import axios from "axios";


const CAN_CLEAR_STRIPE = true
class Customer extends Component {

  constructor(props){
  	super(props)

  	this.state={
  		isDirty:false,
  		customerId:localStorage.getItem("currentCustomer"),
  		customer:{}
  	}

  	this.fetch()
  }

  componentDidMount() {
    let self=this
    // console.log({lcustomer:'mounted'})
    window.addEventListener("beforeunload", (e)=>{
      self.handleNavigation(e)
    });
  }

  componentWillUnmount() {
    let self=this
    // console.log({lcustomer:'unmounting'})
    window.removeEventListener("beforeunload", (e)=>{
      self.handleNavigation(e)
    });
  }

  handleNavigation(e){
    // console.log({lcustomer:'handleNavigation',e,isDirty:this.state.isDirty})
    // return "Are you sure you want to leave?"
    if(this.state.isDirty){
      // e.returnValue =  "Are you sure you want to leave?"
      var confirmationMessage = "Are you sure you want to leave?";

      (e || window.event).returnValue = confirmationMessage;     // Gecko + IE
      return confirmationMessage;
    }
    
  }

  fetch(callback){
	  let self=this

    axios
      .get("/api/customers/view/"+self.state.customerId)
      .then(res => {
        if (res.data) {
        	self.setState({customer:res.data},callback)
        }
        else{

        	self.setState({customer:{}},callback)	
        }
        // else if (res.data.result === "error") {
        	console.log({customer:res});
        // }
      })
      .catch(error => {
        console.log(error);
      });

  }

  updateField(field,value){
    let customer = this.state.customer
    customer[field]=value
    this.setState({customer,isDirty:true})
  }
  updateFields(fields,values){
    let customer = this.state.customer
    fields.forEach((f,fx)=>{
      customer[f]=values[fx]  
    })
    
    this.setState({customer,isDirty:true})
  }

  update(){
        let self = this
        let customer = this.state.customer
        if(customer.password){
          delete customer.password
        }

        axios.put('/api/customers/' + customer._id, {
            customer
        }).then((data) => {
            console.log(`Customer updated successfully`);


            this.setState({customer,isDirty:false},()=>{
              self.fetch(()=>{
                self.props.onUpdate()
              })  
            })
            
            // swal({
            //   title:"Success!",
            //   text:'Customer updated successfully',
            //   type:"success",
            //   timer:1000
            // }).then(value => {
                // window.location = '/customer?t=' + new Date().getTime()
            // });
        }).catch((e) => {
            console.log('Creation failed, Error ', e)
            swal("Error!", e.message, "error");
            // self.setState({errorMessage:e})
        });
  }

  zipLookup(zipCode,callback){
        if (zipCode && zipCode.trim().length === 5) {

            axios.get('/zipLookup/' + zipCode).then((data) => {
                if (callback) {
                    callback(data.data)
                }

            }).catch((e) => {
                if (callback) {
                    callback({zip:zipCode})
                }

            });
        }
        else {
            if (callback) {
                callback({zip:zipCode})
            }
        }
  }


  resetPassword(){

    let self = this
    let customer = this.state.customer
    let password = prompt("Please enter new password", "");
    let cancelled = false;
    let confirmed = false;
    let count = 0

    if (password == null || password == "") {
      cancelled = true;
    } else {

      while(!cancelled && !confirmed && count<3){

        let confirmPassword = prompt("Please re-enter to confirm password", "");
        if (confirmPassword == null || confirmPassword == "") {
          cancelled = true;
        } else {

          if(confirmPassword!=password){
            alert('Passwords must match')
          }
          else{
            confirmed=true


            axios.put('/api/customers/' + customer._id, {
              customer:{password}
            }).then((data) => {
                console.log(`Customer updated successfully`);
    
    
                this.setState({customer,isDirty:false},()=>{
                  self.fetch(()=>{
                    self.props.onUpdate()
                  })  
                })
                
                // swal({
                //   title:"Success!",
                //   text:'Customer updated successfully',
                //   type:"success",
                //   timer:1000
                // }).then(value => {
                    // window.location = '/customer?t=' + new Date().getTime()
                // });
            }).catch((e) => {
                console.log('Creation failed, Error ', e)
                swal("Error!", e.message, "error");
                // self.setState({errorMessage:e})
            });
            // console.log({passwdSuccess:true})
          }
          count++
        } 
      }
    }
  }


  render() {
		let self=this
		let name=''

		if(self.state.customer._id){
			name = [self.state.customer.lastName,self.state.customer.firstName].filter((np)=>{return np.trim().length>0}).join(', ')
		}


		let updateButton=null
		if(self.state.isDirty){
			updateButton=(
        	
        <li> 
          <button type="button" className="btn btn-block btn-success" onClick={()=>{self.update()}}>Update</button>
        </li>
			)
		}
    return (
    <div className="content-wrapper">
	  {/* Content Header (Page header) */}
	  <div className="content-header">
	    <div className="container-fluid">
	      <div className="row mb-2">
	        <div className="col-sm-12">

	          <h1 className="m-0 text-dark float-sm-left">
            	{name}
	          </h1>

	          <ol key='update_button' className="breadcrumb float-sm-right">
	              {updateButton}
                <li> 
                  <button type="button" className="btn btn-block btn-primary" onClick={()=>{self.resetPassword()}}>Reset Password</button>
                </li>
	          </ol>
	        </div>{/* /.col */}
	      </div>{/* /.row */}
	    </div>{/* /.container-fluid */}
	  </div>
	  {/* /.content-header */}
	  {/* Main content */}



    <section className="content">
      <div className="row">
        <div className="col-12">

          <div className="card">
          	{
            // <div className="card-header">
            // 	<h3>
            // 		{name}
            // 	</h3>
            // </div>
        	}
            {
            // <!-- /.card-header -->
        	}
            <div className="card-body">
            <form autoComplete="none">
            <input type="hidden" value="dummy" />

            	 
      			<div className="row">
        			<div className="col-6">

                      <input
                        onChange={(e)=>{self.updateField('firstName',e.target.value)}}
                        value={this.state.customer.firstName||''}
                        autoComplete="none"
                        autoCorrect="off"
                        autoCapitalize="off" 
                        type="text"
                        className="form-control"
                        id="firstName"
                        placeholder="First Name"
                      />
                      <input
                        onChange={(e)=>{self.updateField('lastName',e.target.value)}}
                        value={this.state.customer.lastName||''}
                        autoComplete="none"
                        autoCorrect="off"
                        autoCapitalize="off" 
                        type="text"
                        className="form-control"
                        id="lastName"
                        placeholder="Last Name"
                      />
                      <input
                        onChange={(e)=>{self.updateField('phone',e.target.value)}}
                        value={this.state.customer.phone||''}                        
                        autoComplete="none"
                        autoCorrect="off"
                        autoCapitalize="off" 
                        type="text" pattern="\d*"
                        className="form-control"
                        id="phone"
                        placeholder="Home Phone"
                      />
                      <input
                        onChange={(e)=>{self.updateField('cellPhone',e.target.value)}}
                        value={this.state.customer.cellPhone||''}                      
                        autoComplete="none"
                        autoCorrect="off"
                        autoCapitalize="off" 
                        type="text" pattern="\d*"
                        className="form-control"
                        id="cellPhone"
                        placeholder="Cell Phone"
                      />
                      <input
                        onChange={(e)=>{self.updateField('workPhone',e.target.value)}}
                        value={this.state.customer.workPhone||''}                      
                        autoComplete="none"
                        autoCorrect="off"
                        autoCapitalize="off" 
                        type="text" pattern="\d*"
                        className="form-control"
                        id="workPhone"
                        placeholder="Work Phone"
                      />
                      <input
                        onChange={(e)=>{self.updateField('email',e.target.value)}}
                        value={this.state.customer.email||''}
                        autoComplete="none"
                        autoCorrect="off"
                        autoCapitalize="off" 
                        type="email"
                        className="form-control"
                        id="email"
                        placeholder="email"
                      />

        			</div>
        			<div className="col-6">

                      <input
                        onChange={(e)=>{self.updateField('address1',e.target.value)}}
                        value={this.state.customer.address1||''}                      
                        autoComplete="none"
                        autoCorrect="off"
                        autoCapitalize="off" 
                        type="text"
                        className="form-control"
                        id="address1"
                        placeholder="Address Line 1"
                      />
                      <input
                        onChange={(e)=>{self.updateField('address2',e.target.value)}}
                        value={this.state.customer.address2||''}                    
                        autoComplete="none"
                        autoCorrect="off"
                        autoCapitalize="off" 
                        type="text"
                        className="form-control"
                        id="address2"
                        placeholder="Address Line 2"
                      />
                      <input
                        onChange={(e)=>{self.updateField('address3',e.target.value)}}
                        value={this.state.customer.address3||''}                    
                        autoComplete="none"
                        autoCorrect="off"
                        autoCapitalize="off" 
                        type="text"
                        className="form-control"
                        id="address3"
                        placeholder="Address Line 3"
                      />
                      <input
                        onChange={(e)=>{self.updateField('city',e.target.value)}}
                        value={this.state.customer.city||''}                    
                        autoComplete="none"
                        autoCorrect="off"
                        autoCapitalize="off" 
                        type="text"
                        className="form-control"
                        id="city"
                        placeholder="City"
                      />
                      <input
                        onChange={(e)=>{self.updateField('state',e.target.value)}}
                        value={this.state.customer.state||''}                    
                        autoComplete="none"
                        autoCorrect="off"
                        autoCapitalize="off" 
                        type="text"
                        className="form-control"
                        id="state"
                        placeholder="State"
                      />
                      <input
                        onChange={(e)=>{
                          self.zipLookup(e.target.value,(zipResult)=>{
                            if(zipResult.city){
                              self.updateFields(['zip','city','state'],[zipResult.zip,zipResult.city,zipResult.state])  
                            }
                            else if(zipResult.zip){
                              self.updateField('zip',zipResult.zip)
                            }
                            else{
                              self.updateField('zip','')
                            }
                            // console.log(zipResult)
                            
                          })
                        }}
                        value={this.state.customer.zip||''}                    
                        autoComplete="none"
                        autoCorrect="off"
                        autoCapitalize="off" 
                        type="text" pattern="\d*"
                        className="form-control"
                        id="zip"
                        placeholder="Zip"
                      />
        			</div>
        		</div>
            <div className="row">
              <div className="col-12">
                &nbsp;<br/>
                &nbsp;<br/>
                  <b>Notes</b><br/>

                            <textarea 
                              className="form-control" 
                              rows="10" 
                              onChange={(e)=>{self.updateField('notes',e.target.value)}}
                              value={this.state.customer.notes}
                              placeholder="Notes..."/>
              </div>
            </div>
            </form>
            	<small>Old System #: {self.state.customer.refNumber}</small>
              {CAN_CLEAR_STRIPE && (
                <p>
                  <small>StripeID: {self.state.customer.stripeID}&nbsp;<a href="#clear" onClick={(e)=>{
                    e.preventDefault()
                    self.updateField('stripeID',null)
                  }}>clear</a></small>
                </p>
              )}
            </div>
	        {
	        //<!-- /.card-body -->
	        }
          </div>
          {
          	//<!-- /.card -->
      	  }
        </div>
        {
        //<!-- /.col -->
    	}
      </div>
      {
      //<!-- /.row -->
  }
    </section>
	</div>

      );
  }
}

export default Customer;
