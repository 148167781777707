import React, { Component } from 'react';
import swal from "sweetalert";
import axios from "axios";

class Customers extends Component {

  constructor(props){
  	super(props)

  	this.state={
  		searchText:'',
  		customers:[]
  	}

  	// this.fetch()
  }

  fetch(){
	let self=this

	if(self.state.searchText.length<3){
		self.setState({customers:[]})
		return
	}

    axios
      .get("/api/customers/"+self.state.searchText)
      .then(res => {
        if (res.data && res.data.length) {
        	self.setState({customers:res.data})
        }
        else{

        	self.setState({customers:[]})	
        }
        // else if (res.data.result === "error") {
        	console.log({customers:res});
        // }
      })
      .catch(error => {
        console.log(error);
      });

  }

  createCustomer(){

		let self=this
		let firstName=''
		let lastName=''

		axios.post('/api/customers',{firstName,lastName}).then( (data) => {
			let customer = data.data
			swal({
        title:"Success!",
        text:'Customer created successfully',
        type:"success",
        timer:1000
      }).then(value => {
				localStorage.setItem("currentCustomer", customer._id);
        		window.location='/customer/'//+c._id
        	// self.setState({searchText:'',customers:[data.data]})
          	// self.fetch()
        	});
	    }).catch((e) => {
	    	console.log('Creation failed, Error ',e)
			// self.setState({errorMessage:e})
		});
  }




  render() {
		let self=this
    return (
    <div className="content-wrapper">
	  {/* Content Header (Page header) */}
	  <div className="content-header">
	    <div className="container-fluid">
	      <div className="row mb-2">
	        <div className="col-sm-6">
	          <h1 className="m-0 text-dark">
	          Customers
	          </h1>
	        </div>{/* /.col */}

	        <div className="col-sm-6">
	          <ol className="breadcrumb float-sm-right">
	            <li style={{marginLeft:'20px'}}>           	
	          		<button type="button" className="btn btn-block btn-success" onClick={()=>{self.createCustomer()}}>New</button>
	            </li>
	          </ol>
	        </div>{/* /.col */}
	      </div>{/* /.row */}
	    </div>{/* /.container-fluid */}
	  </div>
	  {/* /.content-header */}
	  {/* Main content */}



    <section className="content">
      <div className="row">
        <div className="col-12">

          <div className="card">
            <div className="card-header">
		        <div className="col-sm-6">
		          <div className="input-group input-group-sm">
		            <input
		              id="customer-search"
		              className="form-control form-control-navbar"
		              style={{borderRightWidth: '1px'}}                  
						autoComplete="off"
						autoCorrect="off"
						autoCapitalize="off" 
		              type="search"
		              placeholder="Search"
		              aria-label="Search"
		              onChange={(e)=>{self.setState({searchText:e.target.value},self.fetch)}}
		            />
		            <div className="input-group-append">
		              <div className="btn btn-navbar">
		                <i className="fas fa-search" />
		              </div>
		            </div>
		          </div>
	          	</div>
            </div>
            {
            // <!-- /.card-header -->
        	}
            <div className="card-body" style={{maxHeight:'74vh',overflowY:'scroll'}}>
              <table id="example1" className="table table-bordered table-striped">
                <thead>
                <tr>
                  <th>Name</th>
                  <th>Address</th>
                  <th>City</th>
                  <th>State</th>
                  <th>Zip</th>
                </tr>
                </thead>
                <tbody>
                {self.state.customers.map((c)=>{
                	let name = [c.lastName,c.firstName].filter((np)=>{return (np || '').trim().length>0}).join(', ')
                	return(

		                <tr key={'c_'+c._id} onClick={()=>{
          					localStorage.setItem("currentCustomer", c._id);
		                	window.location='/customer/'//+c._id
		                }}>
		                  <td>{name}</td>
		                  <td>{c.address1}</td>
		                  <td>{c.city}</td>
		                  <td>{c.state}</td>
		                  <td>{c.zip}</td>
		                </tr>
                	)
                })}
                </tbody>
                {
                // <tfoot>
                // <tr>
                //   <th>Name</th>
                //   <th>Address</th>
                //   <th>City</th>
                //   <th>State</th>
                //   <th>Zip</th>
                // </tr>
                // </tfoot>
            	}
              </table>
            </div>
	        {
	        //<!-- /.card-body -->
	        }
          </div>
          {
          	//<!-- /.card -->
      	  }
        </div>
        {
        //<!-- /.col -->
    	}
      </div>
      {
      //<!-- /.row -->
  }
    </section>
	</div>

      );
  }
}

export default Customers;
