import React, { Component } from 'react';
import swal from "sweetalert";
import axios from "axios";

import './repairs.css';

class Repairs extends Component {

  constructor(props){
  	super(props)

  	this.state={
      customerId:localStorage.getItem("currentCustomer"),
  		searchText:'',
		dateRangeType:'week',
		dateRange:{start:null,end:null},
  		repairs:[],
      	ut:this.parseJwt()
  	}
  	// this.fetch()
  }

  
  componentDidMount() {
    let self=this
    // console.log({lcustomer:'mounted'})
	this.calculateDateRange('week')
  }
  parseJwt() {
    let token = localStorage.getItem("jwtToken");
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function(c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  fetch(){
	let self=this
	let apiUrl = null
	
	let start = self.state.dateRange.start.toISOString().split('T')[0]
	let end = self.state.dateRange.end.toISOString().split('T')[0]

	if(self.state.customerId){
		apiUrl = "/api/repairs/customer/"+self.state.customerId


		// if(self.state.searchText.length>0 && self.state.searchText.length<3){
		// 	self.setState({repairs:[]})
		// 	return
		// }
		// else if(self.state.searchText.length>=3){

		// 	apiUrl = "/api/repairs/customer/"+self.state.customerId + '/' + self.state.searchText.trim()
		// }
	}
	// else if(self.state.searchText.length>=3){
	// 	apiUrl = apiUrl = `/api/repairs/all/${start}/${end}/${self.state.searchText.trim()}`
	// }
	else{
	  apiUrl = `/api/repairs/all/${start}/${end}`
	//   self.setState({repairs:[]})
	// return
	}

	if(apiUrl){

		axios
		// .get("/api/repairs/"+self.state.searchText)
		.get(apiUrl)
		.then(res => {
			if (res.data && res.data.length) {
				self.setState({repairs:res.data})
			}
			else{

				self.setState({repairs:[]})	
			}
			// else if (res.data.result === "error") {
				console.log({repairs:res});
			// }
		})
		.catch(error => {
			console.log(error);
		});
	}

  }

  calculateDateRange(type,offset){
		let self=this;

		let now = new Date()
		let start = new Date()
		let end = new Date()

		switch(type){
			case 'year':
				start = new Date(now.getFullYear()+'-01-01T06:00:00.000Z')
				end = new Date(now.getFullYear()+'-12-31T11:59:59.999Z')
				break;
			case 'quarter':
				start = self.getQuarterStart()
				end = new Date()
				break;
			case 'month':
				start = new Date()
				start.setDate(1)
				end = new Date()
				end.setDate(end.getDate()+1)
				break;
			case 'week':
				start = new Date()
				start.setDate(start.getDate()-start.getDay())
				end = new Date()
				break;
				

		}
		start.setHours(6,0,0,0)
		console.log({dateRange:{start,end}})
		self.setState({
			dateRangeType:type,
			dateRange:{start,end}
		},()=>{
			
			console.log({dateRangeType:self.state.dateRangeType,dateRange:self.state.dateRange})
			self.fetch()
		})

	}
	

    getQuarterStart(){
	    let newDt = new Date()
	    let dd = Math.floor((newDt.getMonth())/3)*3

	    newDt.setMonth(dd)
	    newDt.setDate(1)
	    newDt.setHours(12,0,0,0)
	    return newDt
	}
  createRepair(){

  		let due = new Date()
  		due.setDate(due.getDate()+14)
  		due.setHours(17,0,0,0)

		let self=this
		let customer=self.state.customerId
		let user=self.state.ut.id
		//{type,title,description,instructions,customer,user,price}

		axios.post('/api/repairs',{due,customer,user,title:''}).then( (data) => {
			let repair = data.data

console.log({repair})
			swal({
				title:"Success!",
				text:'Repair created successfully',
				type:"success",
				timer:1000
			}).then(value => {
        		window.location='/repair/'+repair._id
			});
	    }).catch((e) => {
	    	console.log('Creation failed, Error ',e)
			// self.setState({errorMessage:e})
		});
  }




  render() {

	let self=this

    let newButton = null
    let customerHeader = null
    if(self.state.customerId){
      newButton = (
        <button type="button" className="btn btn-block btn-success" onClick={()=>{self.createRepair()}}>New</button>
      )
    }
    else{
      customerHeader = (
        <th>Customer</th>

      )
    }

	let lcSearch = `${self.state.searchText.trim()}`.toLowerCase()
	let fRepairs = self.state.repairs.filter((rr)=>{
		return `${(rr.type || '').toLowerCase()}`.indexOf(lcSearch)>=0
		|| `${(rr.title || '').toLowerCase()}`.indexOf(lcSearch)>=0
		|| `${(rr.description || '').toLowerCase()}`.indexOf(lcSearch)>=0
		|| `${(rr.transaction || '').toLowerCase()}`.indexOf(lcSearch)>=0
		|| `${((rr.customer || {}).lastName || '').toLowerCase()}`.indexOf(lcSearch)>=0
		|| `${((rr.customer || {}).firstName || '').toLowerCase()}`.indexOf(lcSearch)>=0
	})
	console.log({fRepairs,q:self.state.searchText})
    return (
    <div className="content-wrapper">
	  {/* Content Header (Page header) */}
	  <div className="content-header">
	    <div className="container-fluid">
	      <div className="row mb-2">
	        <div className="col-sm-6">
	          <h1 className="m-0 text-dark">
	          Repairs
	          </h1>
	        </div>{/* /.col */}

	        <div className="col-sm-6">
	          <ol className="breadcrumb float-sm-right">
	            <li style={{marginLeft:'20px'}}>           	
	          		{newButton}
	            </li>
	          </ol>
	        </div>{/* /.col */}
	      </div>{/* /.row */}
	    </div>{/* /.container-fluid */}
	  </div>
	  {/* /.content-header */}
	  {/* Main content */}



    <section className="content">
      <div className="row">
        <div className="col-12">

          <div className="card">
            <div className="card-header">
		        <div className="col-sm-6">
		          <div className="input-group input-group-sm">
		            <input
		              id="customer-search"
		              className="form-control form-control-navbar"
		              style={{borderRightWidth: '1px'}}                
						autoComplete="off"
						autoCorrect="off"
						autoCapitalize="off" 
		              type="search"
		              placeholder="Search"
		              aria-label="Search"
		              value={self.state.searchText}
		              onChange={(e)=>{self.setState({searchText:e.target.value},()=>{
		              	// self.fetch()
		              })}}
		            />
		            <div className="input-group-append">
		              <div className="btn btn-navbar">
		                <i className="fas fa-search" />
		              </div>
		            </div>
		          </div>
				  <div>
					  {(!self.state.customerId && (
						  
						<div style={{display:'block'}}>
							<a href="#ytd" className={"btn "+(self.state.dateRangeType==='year'?'btn-primary':'btn-secondary')} style={{margin:'3px'}} onClick={(e)=>{
								e.preventDefault()
								self.calculateDateRange('year')
							}}>Year to Date</a>
							<a href="#qtd" className={"btn "+(self.state.dateRangeType==='quarter'?'btn-primary':'btn-secondary')} style={{margin:'3px'}} onClick={(e)=>{
								e.preventDefault()
								self.calculateDateRange('quarter')
							}}>Quarter to Date</a>
							<a href="#mtd" className={"btn "+(self.state.dateRangeType==='month'?'btn-primary':'btn-secondary')} style={{margin:'3px'}} onClick={(e)=>{
								e.preventDefault()
								self.calculateDateRange('month')
							}}>Month to Date</a>
							<a href="#week" className={"btn "+(self.state.dateRangeType==='week'?'btn-primary':'btn-secondary')} style={{margin:'3px'}} onClick={(e)=>{
								e.preventDefault()
								self.calculateDateRange('week')
							}}>This Week</a>
						</div>
					  )) || null}
				  </div>
	          	</div>
            </div>
            {
            // <!-- /.card-header -->
        	}
            <div className="card-body">
              <table id="example1" className="table table-bordered table-striped">
                <thead>
                <tr>
                  {customerHeader}
                  <th>Type /<br/>Status</th>
                  <th>Title /<br/>Description</th>
                  <th>Created /<br/>Due</th>
                  <th style={{textAlign:'right'}}>Price /<br/>Status</th>
                </tr>
                </thead>
                <tbody>
                {fRepairs.map((r,rx)=>{

                  let customerVal = null
                  let linkQs = ''
                  if(!self.state.customerId && r && r.customer && r.customer._id){
                      linkQs = '?c=' + r.customer._id
                      customerVal = (
                        <td>
                          {[r.customer.lastName,r.customer.firstName].filter((vv)=>{ return vv && vv.trim().length}).join(', ')}
                          
                        </td>
                      )

                  }
                	let elipsis = {
                		maxWidth: '20vw',
    					overflow: 'hidden',
    					whiteSpace: 'nowrap',
    					textOverflow: 'ellipsis'
    				}
    				let row_bg = ''
    				if(!r.transaction){
    					row_bg = 'bg-pending'
    					if(rx%2==1){
    						row_bg = 'bg-pending-alt'
    					}
    				}
    				let status = []
    				let payStatus = []
    				if(r.status && r.status.length){
    					status.push(<br key={'rsbr_'+r._id}/>)
    					status.push(<span style={{fontWeight:'bold'}} key={'rs_'+r._id}>{r.status}</span>)
    				}
    				if(r.paymentStatus){
    					payStatus.push(<br key={'rbbr_'+r._id}/>)
    					payStatus.push(<span style={{fontWeight:'bold'}} key={'rb_'+r._id}>{r.paymentStatus||'unpaid'}</span>)
    				}
                	return(

		                <tr key={'r_'+r._id} className={row_bg} onClick={()=>{
							localStorage.setItem("currentCustomer", r.customer._id || r.customer);
		                	window.location='/repair/'+r._id+linkQs
		                }}>
                      {customerVal}
		                  <td>
		                  	{r.type}
		                  	{status}
		                  </td>
		                  <td style={elipsis}>	
		                  	{r.title}<br/>
		                  	{r.description}
		                  </td>
		                  <td style={{whiteSpace: 'nowrap'}}>
		                  	{(r.created||'').split('T')[0]}<br/>
		                  	<b>{(r.due||'').split('T')[0]}</b>
		                  </td>
		                  <td style={{textAlign: 'right'}}>
		                  	${(r.price||0).toFixed(2)}
		                  	{payStatus}
		                  </td>
		                </tr>
                	)
                })}
                </tbody>
                {
                // <tfoot>
                // <tr>
                //   <th>Name</th>
                //   <th>Address</th>
                //   <th>City</th>
                //   <th>State</th>
                //   <th>Zip</th>
                // </tr>
                // </tfoot>
            	}
              </table>
            </div>
	        {
	        //<!-- /.card-body -->
	        }
          </div>
          {
          	//<!-- /.card -->
      	  }
        </div>
        {
        //<!-- /.col -->
    	}
      </div>
      {
      //<!-- /.row -->
  }
    </section>
	</div>

      );
  }
}

export default Repairs;
