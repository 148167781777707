import React, { Component } from 'react';
import swal from "sweetalert";
import axios from "axios";

import './credits.css';

import DatePicker from "react-datepicker";
import setMinutes from "date-fns/setMinutes";
import setHours from "date-fns/setHours";
import "react-datepicker/dist/react-datepicker.css";


import Gallery from 'react-grid-gallery';

import PhotoEditor from "../photoEditor/photoEditor";

const DatepickerInput = ({ ...props }) => (
  <input type="text" {...props} readOnly />
);

class Credit extends Component {

  constructor(props){
  	super(props)

  	let qvals={}
  	let selectedTab

  	(window.location.href.split('?')[1]||'').split('&').map((vv)=>{
    	let vx=vv.split('=')
    	qvals[vx[0]]=vx[1]
	})
	if(qvals.selectedTab){
		selectedTab = qvals.selectedTab
	}

  	this.state={
  		currentImage:null,
  		selectedTab:selectedTab||'notes',
  		isDirty:false,
  		creditId:window.location.href.split('/credit/')[1].split('/').slice(0)[0],
  		credit:{},
  		photos:[]
  	}

  	this.fetch()
  }

  fetchPhotos(){
	let self=this

    axios
      .get("/api/photos/credits/"+self.state.creditId)
      .then(res => {
        if (res.data) {
        	self.setState({photos:res.data})
        }
        else{

        	self.setState({photos:{}})	
        }
        // else if (res.data.result === "error") {
        	console.log({photos:res});
        // }
      })
      .catch(error => {
        console.log(error);
      });

  }
  fetch(){
	let self=this

    axios
      .get("/api/credits/view/"+self.state.creditId)
      .then(res => {
        if (res.data) {
        	self.setState({credit:res.data},self.fetchPhotos)
        }
        else{

        	self.setState({credit:{}})	
        }
        // else if (res.data.result === "error") {
        	console.log({credit:res});
        // }
      })
      .catch(error => {
        console.log(error);
      });

  }

  updateField(field,value){
    let credit = this.state.credit
    credit[field]=value
    this.setState({credit,isDirty:true})
  }
  updateFields(fields,values){
    let credit = this.state.credit
    fields.forEach((f,fx)=>{
    	credit[f]=values[fx]	
    })
    
    this.setState({credit,isDirty:true})
  }

  update(callback){
    let self=this
    let credit = this.state.credit

    axios.put('/api/credits/'+credit._id,{credit}).then( (data) => {
      console.log(`Credit updated successfully`);
      	if(callback){
      		callback()
      	}
      	else{

			swal({
				title:"Success!",
				text:'Credit updated successfully',
				type:"success",
				timer:1000
			}).then(value => {
      		window.location='/credit/'+credit._id+'?t='+new Date().getTime() + '&selectedTab='+self.state.selectedTab
			});    		
      	}
    }).catch((e) => {
    	console.log('Creation failed, Error ',e)
    	swal("Error!", e.message, "error");
		// self.setState({errorMessage:e})
	});
  }
  detach(){
  		let self = this
	  	let additionalWarning=''

  	  	if(this.state.credit.amount>=0.01 || this.state.credit.amount<=-0.01){
		  	if(this.state.credit.transaction && (this.state.credit.transaction.balance>=0.01 || this.state.credit.transaction.balance<=-0.01)){
		  		additionalWarning = 'This transaction associated with this credit has a balance.  Deleting this may mess up the balance of the associated transaction.  '
		  	}
		  	else if(this.state.credit.transaction && (this.state.credit.transaction.paymentTotal>=0.01 || this.state.credit.transaction.paymentTotal<=-0.01)){
		  		additionalWarning = 'This transaction associated with this credit has a paid balance.  Deleting this may mess up the balance of the associated transaction.  '
		  	}
		}

	    swal(additionalWarning+ "Are you sure you want to save this credit for later?", {
	  		icon: "warning",
	      buttons: {
	        nope: {
	          text: "Cancel",
	          value: "nope"
	        },
	        sure: {
	          className: "btn-danger",
	          text: "I'm, Sure",
	          value: "sure"
	        }
	      }
	    }).then(value => {

	      switch (value) {
	        case "sure":
					let transactionId = this.state.credit.transaction?self.state.credit.transaction._id:null

			        let credit = self.state.credit
			        credit.transaction=null
			        self.setState({credit}, () => {
			        	self.update(()=>{

							swal({
								title:"Success!",
								text:'Credits saved for later.',
								type:"success",
								timer:1000
							}).then(value => {
				      			window.location=(transactionId?'/transaction/'+transactionId:'/credits')
							}); 
			        	})
			        })
	          break;
	        case "nope":
	          	console.log('Didnt Delete (cancel)')
	          break;
	        default:
	          	console.log('Didnt Delete (dismissed)')
	          // swal("Got away safely!");
	          break;
	      }
	    });

  }

  delete(){

  	let self=this
  	console.log(this.state.credit)

  	let additionalWarning=''

  	if(this.state.credit.amount>=0.01 || this.state.credit.amount<=-0.01){
	  	if(this.state.credit.returned){
	  		additionalWarning += 'This credit has been returned.  Deleting this may mess up the balance of the associated transaction.  '
	  	}
	  	if(this.state.credit.transaction && (this.state.credit.transaction.balance>=0.01 || this.state.credit.transaction.balance<=-0.01)){
	  		additionalWarning += 'This transaction associated with this credit has a balance.  Deleting this may mess up the balance of the associated transaction.  '
	  	}
	  	else if(this.state.credit.transaction && (this.state.credit.transaction.paymentTotal>=0.01 || this.state.credit.transaction.paymentTotal<=-0.01)){
	  		additionalWarning = 'This transaction associated with this credit has a paid balance.  Deleting this may mess up the balance of the associated transaction.  '
	  	}
	}

    swal(additionalWarning+ "Are you sure you want to delete this credit?", {
  		icon: "warning",
      buttons: {
        nope: {
          text: "Cancel",
          value: "nope"
        },
        sure: {
          className: "btn-danger",
          text: "I'm, Sure",
          value: "sure"
        }
      }
    }).then(value => {
	  	if(this.state.photos && this.state.photos.length){
	  		swal("Error!", "You must first delete all photos associated with this credit.", "error");
	  		return
	  	}

      switch (value) {
        case "sure":
        	let transactionId = this.state.credit.transaction?self.state.credit.transaction._id:null

          	console.log('Deleted')
				axios.delete('/api/credits/'+self.state.credit._id, {})
				.then( (data) => {
			      console.log(`Credit deleted successfully`);
			      	if(transactionId){
		      			window.location='/transaction/'+transactionId	
			      	}
			      	else{
		      			window.location='/credits'	
			      	}
			      // console.log(data)
			      	// self.props.addPhotoToModel(data.data.filename)
					// self.setState({
					// 	selectedNote:data.data,
					// },self.filterResults)
			    }).catch((e) => {
			    	console.log('Credit delete failed, Error ',e)
					// self.setState({errorMessage:e})
				});
          break;
        case "nope":
          	console.log('Didnt Delete (cancel)')
          break;
        default:
          	console.log('Didnt Delete (dismissed)')
          // swal("Got away safely!");
          break;
      }
    });
  }

	updatePhoto(field,value){
		let photos = this.state.photos
		photos[this.state.currentImage][field]=value
		this.setState({photos})
		console.log({updatePhoto:{field,value}})
	}

  	savePhoto(){
  		let photo = this.state.photos[this.state.currentImage]


		axios.put('/api/photos/'+photo._id, {photo})
		.then( (data) => {
	      console.log(`Item updated successfully`);
	      // self.fetchPhotos()
	      console.log(data)
	      	// self.props.addPhotoToModel(data.data.filename)
			// self.setState({
			// 	selectedNote:data.data,
			// },self.filterResults)
	    }).catch((e) => {
	    	console.log('Photo update failed, Error ',e)
			// self.setState({errorMessage:e})
		});
  	}


	newPhoto(fileObj){
		let self=this
		console.log(this.state)

		var formData = new FormData();
		formData.append("file", fileObj);
		formData.append("type", 'credits');
		formData.append("refId", this.state.credit._id);

		axios.post('/api/photos', formData, {
		    headers: {
		      'Content-Type': 'multipart/form-data'
		    }
		})
		.then( (data) => {
	      console.log(`Item created successfully`);
	      self.fetchPhotos()
	      console.log(data)
	      	// self.props.addPhotoToModel(data.data.filename)
			// self.setState({
			// 	selectedNote:data.data,
			// },self.filterResults)
	    }).catch((e) => {
	    	console.log('Creation failed, Error ',e)
			// self.setState({errorMessage:e})
		});

	}

	deletePhoto(){
		let self=this
  		let photo = this.state.photos[this.state.currentImage]


		axios.delete('/api/photos/'+photo._id, {})
		.then( (data) => {
	      console.log(`Photo deleted successfully`);
      		window.location='/credit/'+self.state.credit._id+'?t='+new Date().getTime() + '&selectedTab='+self.state.selectedTab
	      // console.log(data)
	      	// self.props.addPhotoToModel(data.data.filename)
			// self.setState({
			// 	selectedNote:data.data,
			// },self.filterResults)
	    }).catch((e) => {
	    	console.log('Photo delete failed, Error ',e)
			// self.setState({errorMessage:e})
		});
		// let photo = this.state.credit.photos[this.state.currentImage]
		// this.props.deleteModelPhoto(photo)
		// console.log({deletePhoto:photo})
		// console.log({deletePhoto:this.state.currentImage})
	}

	onCurrentImageChange(index) {
		console.log({onCurrentImageChange:index})
        this.setState({ currentImage: index });
    }



  render() {
		let self=this


		let updateButton=null
		if(self.state.isDirty){
			updateButton=(

	          <ol key='update_button' className="breadcrumb float-sm-right">
	            <li style={{marginRight: '10px'}}>         	
	          		<button type="button" className="btn btn-block btn-success" onClick={()=>{self.update()}}>Update</button>
	            </li>
	          </ol>
			)
		}

		let transactionButton = null
		// let payLaterButton = null
		if(this.state.credit.transaction){
			transactionButton = (

	          <ol key='transaction_button' className="breadcrumb float-sm-right">
	            <li style={{marginRight: '10px'}}>         	
	          		<button type="button" className="btn btn-block btn-primary" onClick={()=>{
    					window.location = '/transaction/'+this.state.credit.transaction._id
    				}}>See Transaction</button>
	            </li>
	          </ol>
			)

			// if(!this.state.credit.returned){
			// 	payLaterButton = (
			// 		<button type="button" className="btn btn-block btn-warning" onClick={()=>{self.detach()}}>Pay for this later</button>
			// 	)
			// }
		}


        let galleryPhotos=(this.state.photos||[]).map((p,pid)=>{
			return {
		        src: axios.defaults.baseURL + "/api/photos/"+p.type+'/'+p.fileName,
		        thumbnail: axios.defaults.baseURL + "/api/photos/"+p.type+'/'+p.fileName,
		        // thumbnailWidth: 80,
		        // thumbnailHeight: 190,
		        // isSelected: true,
		        // caption: "After Rain (Jeshu John - designerspics.com)"
			}
		})

		let currentImageDetails = ''
		if(this.state.photos && this.state.photos.length){
			if(this.state.currentImage!=null && !isNaN(this.state.currentImage) && this.state.photos[this.state.currentImage] && this.state.photos[this.state.currentImage].details){
				currentImageDetails = this.state.photos[this.state.currentImage].details
			}
		}
		let gallery = null
		if(galleryPhotos.length){
			gallery = (

        		<Gallery key="_gallery"
        			rowHeight={100}
        			enableImageSelection={false} 
					backdropClosesModal={true}
					showLightboxThumbnails={true}
        			images={galleryPhotos}
					currentImageWillChange={(index)=>self.onCurrentImageChange(index)}
					customControls={[

                    	<button key="deleteImage" onClick={()=>self.deletePhoto()}>Delete</button>,

						<textarea 
							className="form-control photo-description-textarea" 
							rows="7" 
							onChange={(e)=>{self.updatePhoto('details',e.target.value)}}
							onBlur={()=>{self.savePhoto()}}
							value={currentImageDetails}
							placeholder="Photo Details..."/>
					]}/>
			)
		}
    return (
    <div className="content-wrapper">
	  {/* Content Header (Page header) */}
	  <div className="content-header">
	    <div className="container-fluid">
	      <div className="row mb-2">
	        <div className="col-sm-12">

	          <h1 className="m-0 text-dark float-sm-left">
            	{self.state.credit.title}
	          </h1>
	          {transactionButton}
	          {updateButton}
	        </div>{/* /.col */}
	      </div>{/* /.row */}
	    </div>{/* /.container-fluid */}
	  </div>
	  {/* /.content-header */}
	  {/* Main content */}



    <section className="content">
      <div className="row">
        <div className="col-12">

          <div className="card">
          	{
            // <div className="card-header">
            // 	<h3>
            // 		{name}
            // 	</h3>
            // </div>
        	}
            {
            // <!-- /.card-header -->
            //const newCredit = new Credit({type,title,description,instructions,customer,user,amount});
        	}
            <div className="card-body">

      			<div className="row">
        			<div className="col-6">

        			  <label>Transaction</label>

                      <input
                        onChange={(e)=>{self.updateField('transaction',e.target.value)}}
                        value={this.state.credit.transaction||''}                
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off" 
                        type="text" pattern="\d*"
                        className="form-control"
                        id="transaction"
                        placeholder="transaction"
                      />

        			  <label>Type</label>
	                  <select 
	                  	className="form-control select2"
                        onChange={(e)=>{self.updateField('type',e.target.value||'')}}
                        value={this.state.credit.type}>
	                    <option value="">Choose Type</option>
	                    <option value="other">Other</option>
	                  </select>
	                  

        			</div>
        			<div className="col-6">

        			  <label>Amount</label>
                      <input
                        onChange={(e)=>{self.updateField('amount',e.target.value.replace(/[^0-9\.\-]/gi,''))}}
                        value={'$'+this.state.credit.amount||0}                
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off" 
                        type="text" pattern="\d*"
                        className="form-control"
                        id="amount"
                        placeholder="Amount"
                        onBlur={(e)=>{
                        	if(isNaN(+this.state.credit.amount)){
                        		self.updateField('amount','0.00')
                        	}
                        	else{
                        		self.updateField('amount',(+this.state.credit.amount).toFixed(2))	
                        	}
                        }}
                      />


        			</div>

			          <div className="col-12">&nbsp;</div>

			          <div className="col-12">
			            <ul className="nav nav-tabs" id="custom-content-below-tab" role="tablist">
			              
			              {/*<li className="nav-item">
			                <a className={"nav-link" + (self.state.selectedTab==='description'?' active':'')}
			                   id="custom-content-description-tab" 
			                   data-toggle="pill" 
			                   href="#custom-content-description" 
			                   role="tab" 
			                   aria-controls="custom-content-description" 
			                   aria-selected="false"
			                   onClick={(e)=>{
			                   	self.setState({selectedTab:'description'})
			                   }}>Description</a>
			              </li>*/}

			              <li className="nav-item">
			                <a className={"nav-link" + (self.state.selectedTab==='notes'?' active':'')}
			                   id="custom-content-notes-tab" 
			                   data-toggle="pill" 
			                   href="#custom-content-notes" 
			                   role="tab" 
			                   aria-controls="custom-content-notes" 
			                   aria-selected="false"
			                   onClick={(e)=>{
			                   	self.setState({selectedTab:'notes'})
			                   }}>Notes</a>
			              </li>
{/*
			              <li className="nav-item">
			                <a className={"nav-link" + (self.state.selectedTab==='photos'?' active':'')}
			                   id="custom-content-photos-tab" 
			                   data-toggle="pill" 
			                   href="#custom-content-photos" 
			                   role="tab" 
			                   aria-controls="custom-content-photos" 
			                   aria-selected="false"
			                   onClick={(e)=>{
			                   	self.setState({selectedTab:'photos'})
			                   }}>Photos</a>
			              </li>
			              */}
			              <li className="nav-item">
			                <a className={"nav-link" + (self.state.selectedTab==='more'?' active':'')}
			                   id="custom-content-more-tab" 
			                   data-toggle="pill" 
			                   href="#custom-content-more" 
			                   role="tab" 
			                   aria-controls="custom-content-more" 
			                   aria-selected="false"
			                   onClick={(e)=>{
			                   	self.setState({selectedTab:'more'})
			                   }}>More...</a>
			              </li>
			            </ul>
			            <div className="tab-content" id="custom-content-below-tabContent">
			              
			              {/*<div className={"tab-pane fade show" + (self.state.selectedTab==='description'?' active':'')} 
			              	   id="custom-content-description" 
			              	   role="tabpanel" 
			              	   aria-labelledby="custom-content-description-tab">
			                 
			                      <textarea 
			                      	className="form-control" 
			                      	rows="10" 
			                        onChange={(e)=>{self.updateField('description',e.target.value)}}
			                        value={this.state.credit.description}
			                      	placeholder="Description..."/>
			              </div>*/}

			              <div className={"tab-pane fade show" + (self.state.selectedTab==='notes'?' active':'')} 
			              	   id="custom-content-notes" 
			              	   role="tabpanel" 
			              	   aria-labelledby="custom-content-notes-tab">
			                 
			                      <textarea 
			                      	className="form-control" 
			                      	rows="10" 
			                        onChange={(e)=>{self.updateField('note',e.target.value)}}
			                        value={this.state.credit.note}
			                      	placeholder="Notes..."/>
			              </div>

			              {/*<div className={"tab-pane fade show" + (self.state.selectedTab==='photos'?' active':'')} 
			              	   id="custom-content-photos" 
			              	   role="tabpanel" 
			              	   aria-labelledby="custom-content-photos-tab">
			                 
			                 	<div className="col-2">
									<PhotoEditor 
									text="New Photo" 
									onImageBlob={(data)=>{
										self.newPhoto(data.blobFile)
									}}/>
								</div>
			            		{gallery}
			              </div>
			              */}
			              <div className={"tab-pane fade show" + (self.state.selectedTab==='more'?' active':'')} 
			              	   id="custom-content-more" 
			              	   role="tabpanel" 
			              	   aria-labelledby="custom-content-more-tab">
			                 
	          					<button type="button" className="btn btn-block btn-danger" onClick={()=>{self.delete()}}>delete</button>
			              </div>
			            </div>
			          </div>

        		</div>

            </div>
	        {
	        //<!-- /.card-body -->
	        }
          </div>
          {
          	//<!-- /.card -->
      	  }
        </div>
        {
        //<!-- /.col -->
    	}
      </div>
      {
      //<!-- /.row -->
  }
    </section>
	</div>

      );
  }
}

export default Credit;
