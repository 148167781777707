import React, { Component } from 'react';
import swal from "sweetalert";
import axios from "axios";


import Gallery from 'react-grid-gallery';

import PhotoEditor from "../photoEditor/photoEditor";

const ALL_TYPES = {
    "cart-percent":"cart-percent",
    "fixed-amount":"fixed-amount",
}

class Coupon extends Component {

  constructor(props){
    super(props)

    let qvals={}
    let selectedTab

        (window.location.href.split('?')[1] || '').split('&').map((vv) => {
        let vx = vv.split('=')
        qvals[vx[0]] = vx[1]
    })
    if (qvals.selectedTab) {
        selectedTab = qvals.selectedTab
    }
  	this.state={
      selectedTab:selectedTab||'description',
  		isDirty:false,
  		couponId:window.location.href.toLowerCase().split('/coupon/')[1].split('/').slice(0)[0],
  		coupon:{}
  	}

  	this.fetch()
  }

  componentDidMount() {
    let self=this
    // console.log({lstory:'mounted'})
    window.addEventListener("beforeunload", (e)=>{
      self.handleNavigation(e)
    });
  }

  componentWillUnmount() {
    let self=this
    // console.log({lstory:'unmounting'})
    window.removeEventListener("beforeunload", (e)=>{
      self.handleNavigation(e)
    });
  }

  handleNavigation(e){
    // console.log({lstory:'handleNavigation',e,isDirty:this.state.isDirty})
    // return "Are you sure you want to leave?"
    if(this.state.isDirty){
      // e.returnValue =  "Are you sure you want to leave?"
      var confirmationMessage = "Are you sure you want to leave?";

      (e || window.event).returnValue = confirmationMessage;     // Gecko + IE
      return confirmationMessage;
    }
    
  }

  fetch(callback){
    let self=this

    let cb = callback || function(){}

    axios
      .get("/api/coupons/view/"+self.state.couponId)
      .then(res => {
        if (res.data) {
          self.setState({
            coupon:res.data
          },()=>{

            // self.fetchPhotos(cb)
          })
        }
        else{

          self.setState({coupon:{}},()=>{
            // self.fetchPhotos(cb)
          })  
        }
        // else if (res.data.result === "error") {
          console.log({coupon:res});
        // }
      })
      .catch(error => {
        console.log(error);
        // self.fetchPhotos(cb)
      });

  }

  updateField(field,value,callback){
    let coupon = this.state.coupon
    coupon[field]=value
    this.setState({coupon,isDirty:true},callback)
  }
  updateFields(fields,values,callback){
    let coupon = this.state.coupon
    fields.forEach((f,fx)=>{
        coupon[f]=values[fx]  
    })
    
    this.setState({coupon,isDirty:true},callback)
  }

  update(callback){
        let self = this
        let coupon = this.state.coupon
        let {code,type,modifier,description,minCartAmount,active} = self.state.coupon

        console.log({code,type,modifier,description,minCartAmount,active})

        axios.put('/api/coupons/' + coupon._id, {
            coupon:{code,type,modifier,description,minCartAmount,active}
        }).then((data) => {
            console.log(`Coupon updated successfully`);


            this.setState({coupon,isDirty:false},()=>{
              self.fetch(()=>{
                if(callback){
                  callback()
                }
                // self.props.onUpdate()
              })  
            })
            
            // swal({
            //   title:"Success!",
            //   text:'CustomerStory updated successfully',
            //   type:"success",
            //   timer:1000
            // }).then(value => {
                // window.location = '/product?t=' + new Date().getTime()
            // });
        }).catch((e) => {
            console.log('Creation failed, Error ', e)
            swal("Error!", e.message, "error");
            // self.setState({errorMessage:e})
        });
  }

  delete(){
    let self=this
    console.log(this.state.sale)

    let additionalWarning=''

    swal(additionalWarning+ "Are you sure you want to delete this coupon?", {
      icon: "warning",
      buttons: {
        nope: {
          text: "Cancel",
          value: "nope"
        },
        sure: {
          className: "btn-danger",
          text: "I'm, Sure",
          value: "sure"
        }
      }
    }).then(value => {
      

      switch (value) {
        case "sure":
          
            console.log('Deleted')
        axios.delete('/api/coupons/'+self.state.coupon._id, {})
        .then( (data) => {
            console.log(`coupon deleted successfully`);
                window.location='/coupons'
            // console.log(data)
              // self.props.addPhotoToModel(data.data.filename)
          // self.setState({
          //  selectedNote:data.data,
          // },self.filterResults)
          }).catch((e) => {
            console.log('coupon delete failed, Error ',e)
          // self.setState({errorMessage:e})
        });
          break;
        case "nope":
            console.log('Didnt Delete (cancel)')
          break;
        default:
            console.log('Didnt Delete (dismissed)')
          // swal("Got away safely!");
          break;
      }
    });

  }




  render() {
		let self=this
		let code=''



		let updateButton=null
		if(self.state.isDirty){
			updateButton=(

	            <li key='update_button' id='sadas'>         	
	          		<button type="button" className="btn btn-block btn-success" onClick={()=>{self.update()}}>update</button>
	            </li>
			)
		}

    let activeButton=null

    if(self.state.coupon._id){
      code = self.state.coupon.code
     
      if(self.state.coupon.active){
        activeButton=(

              <li key='deactivate_button'>          
                <button type="button" className="btn btn-block btn-danger" onClick={(e)=>{
                  self.updateField('active',false,()=>{
                    self.update()
                  })
                }}>Make Inactive</button>
              </li>
        )
      }
      else{
        activeButton=(

              <li key='activate_button'>          
                <button type="button" className="btn btn-block btn-primary" onClick={(e)=>{
                  self.updateField('active',true,()=>{
                    self.update()
                  })
                }}>Make Active</button>
              </li>
        )

      }
    }


    return (
    <div className="content-wrapper">
	  {/* Content Header (Page header) */}
	  <div className="content-header">
	    <div className="container-fluid">
	      <div className="row mb-2">
	        <div className="col-sm-12">

	          <h1 className="m-0 text-dark float-sm-left">
            	<a href="/coupons">All Coupons</a> &gt; {code}
	          </h1>

            <ol className="breadcrumb float-sm-right">
            {updateButton}

              <li key='li-space' id='sadas'>           
                &nbsp;&nbsp;&nbsp;
              </li>
            {activeButton}
            </ol>
	        </div>{/* /.col */}
	      </div>{/* /.row */}
	    </div>{/* /.container-fluid */}
	  </div>
	  {/* /.content-header */}
	  {/* Main content */}



    <section className="content">
      <div className="row">
        <div className="col-12">

          <div className="card">
          	{
            // <div className="card-header">
            // 	<h3>
            // 		{name}
            // 	</h3>
            // </div>
        	}
            {
            // <!-- /.card-header -->
        	}
            <div className="card-body">
            <form autoComplete="none">
            <input type="hidden" value="dummy" />

            	 
      			<div className="row">
        			<div className="col-6">

                <label>Code</label>
                <input
                  onChange={(e)=>{self.updateField('code',`${e.target.value}`.trim().toUpperCase())}}
                  value={this.state.coupon.code||''}
                  autoComplete="none"
                  autoCorrect="off"
                  autoCapitalize="off" 
                  type="text"
                  className="form-control"
                  id="code"
                  placeholder="Ex: EJR-20"
                />

                <label>Type</label>
                <select
                    onChange={(e)=>{self.updateField('type',e.target.value)}}
                    value={this.state.coupon.type||''}
                    className="form-control"
                    id="coupon"
                    >
                    <option value="">Choose Type</option>
                    {Object.keys(ALL_TYPES).map((tt)=>{
                        return (
                        <option key={`ptype_${tt}`} value={tt}>{ALL_TYPES[tt]}</option>
                        )
                    })}
                </select>

                <label>Modifier</label>
                <input
                  onChange={(e)=>{self.updateField('modifier',e.target.value)}}
                  value={this.state.coupon.modifier||'0'}
                  autoComplete="none"
                  autoCorrect="off"
                  autoCapitalize="off" 
                  type="text"
                  className="form-control"
                  id="modifier"
                  placeholder="..modifier"
                />

                <label>Minimum Cart Amount</label>
                <input
                  onChange={(e)=>{self.updateField('minCartAmount',e.target.value)}}
                  value={this.state.coupon.minCartAmount||'0'}
                  autoComplete="none"
                  autoCorrect="off"
                  autoCapitalize="off" 
                  type="text"
                  className="form-control"
                  id="minCartAMount"
                  placeholder="minimum cart amount to activate"
                />


        			</div>
        			<div className="col-6">

        			</div>
        		</div>

              <div className="col-12">&nbsp;</div>

                <div className="col-12">
                  <ul className="nav nav-tabs" id="custom-content-below-tab" role="tablist">
                    <li className="nav-item">
                      <a className={"nav-link" + (self.state.selectedTab==='description'?' active':'')}
                         id="custom-content-description-tab" 
                         data-toggle="pill" 
                         href="#custom-content-description" 
                         role="tab" 
                         aria-controls="custom-content-description" 
                         aria-selected="false"
                         onClick={(e)=>{
                          self.setState({selectedTab:'description'})
                         }}>Description</a>
                    </li>
                    <li className="nav-item">
                      <a className={"nav-link" + (self.state.selectedTab==='more'?' active':'')}
                         id="custom-content-more-tab" 
                         data-toggle="pill" 
                         href="#custom-content-more" 
                         role="tab" 
                         aria-controls="custom-content-more" 
                         aria-selected="false"
                         onClick={(e)=>{
                          self.setState({selectedTab:'more'})
                         }}>More...</a>
                    </li>
                  </ul>
                  <div className="tab-content" id="custom-content-below-tabContent">
                    <div className={"tab-pane fade show" + (self.state.selectedTab==='description'?' active':'')} 
                         id="custom-content-description" 
                         role="tabpanel" 
                         aria-labelledby="custom-content-description-tab">
                       
                            <textarea 
                              className="form-control" 
                              rows="10" 
                              onChange={(e)=>{self.updateField('description',e.target.value)}}
                              value={this.state.coupon.description}
                              placeholder="Description..."/>
                    </div>
                    <div className={"tab-pane fade show" + (self.state.selectedTab==='more'?' active':'')} 
                         id="custom-content-more" 
                         role="tabpanel" 
                         aria-labelledby="custom-content-more-tab">
                       
                      <button type="button" className="btn btn-block btn-danger" onClick={()=>{self.delete()}}>delete</button>
                    </div>
                  </div>
                </div>

            </form>
            </div>
	        {
	        //<!-- /.card-body -->
	        }
          </div>
          {
          	//<!-- /.card -->
      	  }
        </div>
        {
        //<!-- /.col -->
    	}
      </div>
      {
      //<!-- /.row -->
  }
    </section>
	</div>

      );
  }
}

export default Coupon;
