import React, { Component } from 'react';
import axios from "axios";

class AdminUsers extends Component {

  constructor(props){
  	super(props)

  	this.state={
  		users:[],
      	ut:this.parseJwt()
  	}

  	this.fetch()
  }
  parseJwt() {
    let token = localStorage.getItem("jwtToken");
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function(c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  fetch(){
	let self=this

	if(!this.state.ut.admin){
		window.location = '/admin/user/'+this.state.ut.id
		return;
	}

    axios
      .get("/api/users")
      .then(res => {
        if (res.data && res.data.length) {
			let users = res.data
			
            users.sort((a,b)=>{
                if(a.priority<b.priority){
                    return -1
                }
                else if(a.priority>b.priority){
                    return 1
                }
                else{
                    return 0
                }
            })
        	self.setState({users})
        }
        else{

        	self.setState({users:[]})	
        }
        // else if (res.data.result === "error") {
        	console.log({users:res});
        // }
      })
      .catch(error => {
        console.log(error);
      });

  }

  createUser(){

		let self=this
		let password=new Date().getTime().toFixed(0)
		let username='user_'+password
		let firstName='FIRST'
		let lastName='LAST'

		axios.post('/api/users',{username,password,firstName,lastName}).then( (data) => {
	      console.log(`User created successfully`);
	      self.fetch()
	    }).catch((e) => {
	    	console.log('Creation failed, Error ',e)
			// self.setState({errorMessage:e})
		});
  }


  setUserIsOnWebsite(userId,isOnWebsite){
	  
	let self=this
	axios.put(`/api/users/${userId}`,{user:{isOnWebsite}}).then( (data) => {
		console.log(`User updated successfully`);
		self.fetch()
		}).catch((e) => {
			console.log('Update failed, Error ',e)
			// self.setState({errorMessage:e})
		});
  }

  setUserPriority(userId,priority){
	  
	let self=this
	axios.put(`/api/users/${userId}`,{user:{priority}}).then( (data) => {
		console.log(`User updated successfully`);
		self.fetch()
		}).catch((e) => {
			console.log('Update failed, Error ',e)
			// self.setState({errorMessage:e})
		});
  }



  render() {
		let self=this
    return (
    <div className="content-wrapper">
  {/* Content Header (Page header) */}
  <div className="content-header">
    <div className="container-fluid">
      <div className="row mb-2">
        <div className="col-sm-6">
          <h1 className="m-0 text-dark">
          Users
          </h1>
        </div>{/* /.col */}

        <div className="col-sm-6">
          <ol className="breadcrumb float-sm-right">
    {
            // <li className="breadcrumb-item"><a href="/admin">Admin</a></li>
            // <li className="breadcrumb-item active">Users</li>
    }
            <li style={{marginLeft:'20px'}}>           	
          		<button type="button" className="btn btn-block btn-success" onClick={()=>{self.createUser()}}>New</button>
            </li>
          </ol>
        </div>
    	{/* /.col */}
      </div>{/* /.row */}
    </div>{/* /.container-fluid */}
  </div>
  {/* /.content-header */}
  {/* Main content */}



    <section className="content">


      <div className="card card-solid">
        <div className="card-body pb-0">
          <div className="col-12 row d-flex align-items-stretch">
          {self.state.users.map((u,ux)=>{

          		let userTypes=[]
          		let activeColor = '#888888'
				let enabledColor = '#007bff'
				  
				userTypes.push(
					<i key={`uwww_${ux}_${u._id}`} className="fas fa-globe" style={{marginRight:'3px',color:u.isOnWebsite?enabledColor:activeColor}} onClick={(e)=>{
						this.setUserIsOnWebsite(u._id,!u.isOnWebsite)
					}}></i>
				)
          		if(!u.active){
          			activeColor = '#CCCCCC'
          			userTypes.push(
          				<i key={'utype_'+ux} className="fas fa-user-alt-slash" style={{marginRight:'3px',color:activeColor}}></i>
          			)
          		}
          		else if(u.admin){
          			userTypes.push(
          				<i key={'utype_'+ux} className="fas fa-user-shield" style={{marginRight:'3px',color:activeColor}}></i>
          			)
          		}
          		else{
          			userTypes.push(
          				<i key={'utype_'+ux} className="fas fa-user" style={{marginRight:'3px',color:activeColor}}></i>
          			)
          		}
          		if(u.sales){
          			userTypes.push(
          				<i key={'us_'+ux} className="fas fa-gem" style={{marginRight:'5px',color:activeColor}}></i>
          			)
          		}
          		if(u.jeweler){
          			userTypes.push(
          				<i key={'uj_'+ux} className="fas fa-tools" style={{marginRight:'3px',color:activeColor}}></i>
          			)
          		}
          		return(
		            <div key={'u_'+u._id} className="col-12 col-sm-6 col-md-4 d-flex align-items-stretch">
		              <div className="card bg-light" style={{width: '100%'}}>
		                <div className="card-header text-muted border-bottom-0">
		                  {u.title} 
		                </div>
		                <div className="card-body pt-0">
		                  <div className="row">
		                    <div className="col-7">
		                      <h2 className="lead">
		                      	<b>{u.firstName.trim()}</b><br/>
		                      	<b>{u.lastName.trim()}</b>
		                      </h2>
		                    </div>
		                    <div className="col-5 text-center">
		                      <img src={u.image} alt="" className="img-circle img-fluid" />
		                    </div>
		                  </div>
		                </div>
		                <div className="card-footer">
		                  <div className="text-left" style={{float:'left'}}>
		                		{userTypes}
		                  </div>
		                  <div className="text-right">
		                    <a href={'#'} className="btn btn-sm btn-success" onClick={(e)=>{
								e.preventDefault()
								self.setUserPriority(u._id,u.priority-1)
							}}>
								<i key={'utype_'+ux} className="fas fa-arrow-up" style={{color:'white'}}></i>
		                    </a>
		                    <a href={'#'} className="btn btn-sm btn-warning" style={{marginRight:'10px'}} onClick={(e)=>{
								e.preventDefault()
								self.setUserPriority(u._id,u.priority+1)
							}}>
								<i key={'utype_'+ux} className="fas fa-arrow-down" style={{color:'white'}}></i>
		                    </a>
		                    <a href={'/admin/user/'+u._id} className="btn btn-sm btn-primary">
		                      View Profile
		                    </a>
		                  </div>
		                </div>
		              </div>
		            </div>

          		)

          })}


          </div>
        </div>
{
        // <div className="card-footer">
        //   <nav aria-label="Contacts Page Navigation">
        //     <ul className="pagination justify-content-center m-0">
        //       <li className="page-item active"><a className="page-link" href="#">1</a></li>
        //       <li className="page-item"><a className="page-link" href="#">2</a></li>
        //       <li className="page-item"><a className="page-link" href="#">3</a></li>
        //       <li className="page-item"><a className="page-link" href="#">4</a></li>
        //       <li className="page-item"><a className="page-link" href="#">5</a></li>
        //       <li className="page-item"><a className="page-link" href="#">6</a></li>
        //       <li className="page-item"><a className="page-link" href="#">7</a></li>
        //       <li className="page-item"><a className="page-link" href="#">8</a></li>
        //     </ul>
        //   </nav>
        // </div>
}
      </div>

    </section>
</div>

      );
  }
}

export default AdminUsers;
