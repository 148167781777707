import React, { Component } from 'react';
import swal from "sweetalert";
import axios from "axios";

class CustomItems extends Component {

  constructor(props){
  	super(props)

  	this.state={
  		searchText:'',
  		customItems:[]
  	}

  	this.fetch()
  }

  fetch(){
    let self=this

    let searchText = (self.state.searchText||'').trim()

	  if(self.state.searchText.length<3){
      searchText=''
		  // self.setState({customItems:[]})
		  // return
	  }

    axios
      .get("/api/customItems/"+self.state.searchText)
      .then(res => {
        if (res.data && res.data.length) {
        	self.setState({customItems:res.data})
        }
        else{

        	self.setState({customItems:[]})	
        }
        // else if (res.data.result === "error") {
        	console.log({customItems:res});
        // }
      })
      .catch(error => {
        console.log(error);
      });

  }

  toggleActive(customItem){
    let self=this
    if(customItem){

        customItem.active = !customItem.active 
        axios.put('/api/customItems/' + customItem._id, {
            customItem
        }).then((data) => {
         self.fetch()
        }).catch((e) => {
            console.log('Update failed, Error ', e)
            swal("Error!", e.message, "error");
            // self.setState({errorMessage:e})
        });

      
    }
  }


  createCustomItem(){
    let self=this
    axios.post('/api/customItems',{
      title:'New CustomItem',
      description:'[description]'
    })
    .then( (data) => {
      window.location='/customItems/'+data.id
	  })
    .catch((e) => {
	    	console.log('Creation failed, Error ',e)
			// self.setState({errorMessage:e})
		});
  }




  render() {
		let self=this
    return (
    <div className="content-wrapper">
	  {/* Content Header (Page header) */}
	  <div className="content-header">
	    <div className="container-fluid">
	      <div className="row mb-2">
	        <div className="col-sm-6">
	          <h1 className="m-0 text-dark">
	          Custom Items
	          </h1>
	        </div>{/* /.col */}

	        <div className="col-sm-6">
	          <ol className="breadcrumb float-sm-right">
	            <li style={{marginLeft:'20px'}}>           	
	          		<button type="button" className="btn btn-block btn-success" onClick={()=>{self.createCustomItem()}}>New</button>
	            </li>
	          </ol>
	        </div>{/* /.col */}
	      </div>{/* /.row */}
	    </div>{/* /.container-fluid */}
	  </div>
	  {/* /.content-header */}
	  {/* Main content */}



    <section className="content">
      <div className="row">
        <div className="col-12">

          <div className="card">
            <div className="card-header">
		        <div className="col-sm-6">
		          <div className="input-group input-group-sm">
		            <input
		              id="product-search"
		              className="form-control form-control-navbar"
		              style={{borderRightWidth: '1px'}}                  
						autoComplete="off"
						autoCorrect="off"
						autoCapitalize="off" 
		              type="search"
		              placeholder="Search"
		              aria-label="Search"
		              onChange={(e)=>{self.setState({searchText:e.target.value},self.fetch)}}
		            />
		            <div className="input-group-append">
		              <div className="btn btn-navbar">
		                <i className="fas fa-search" />
		              </div>
		            </div>
		          </div>
	          	</div>
            </div>
            {
            // <!-- /.card-header -->
        	}
            <div className="card-body" style={{maxHeight:'74vh',overflowY:'scroll'}}>
              <table id="example1" className="table table-bordered table-striped">
                <thead>
                <tr>
                  {/* <th>&nbsp;</th> */}
                  <th style={{minWidth:'100px',maxWidth:'100px',width:'100px'}}>Image</th>
                  <th style={{minWidth:'20%',maxWidth:'20%',width:'20%'}}>Title</th>
                  <th>Description</th>
                </tr>
                </thead>
                <tbody>
                {self.state.customItems.map((c)=>{
                	return(

		                <tr style={c.active?{}:{background:"#f6d4d4"}} key={'c_'+c._id} >
                            <td onClick={()=>{
                            // localStorage.setItem("currentProduct", c._id);
                                window.location='/customItem/'+c._id
                                }}>
                                {c.cid && c.cid.length && (
                                    <div>{c.cid}</div>
                                ) || null}
                                {c.images && c.images.length && (
                                    <img src={c.images[0]} style={{width:"100px"}} />
                                ) || null}
                            </td>
                            <td onClick={()=>{
                            // localStorage.setItem("currentProduct", c._id);
                                window.location='/customItem/'+c._id
                            }}>
                                <div>{c.title || '[MISSING]'}</div>
                            </td>
                            <td onClick={()=>{
                            // localStorage.setItem("currentProduct", c._id);
                                window.location='/customItem/'+c._id
                            }}>
                                {c.description && (
                                <div>{c.description}</div>
                            ) || null}
                            </td>
		                </tr>
                	)
                })}
                </tbody>
                {
                // <tfoot>
                // <tr>
                //   <th>Name</th>
                //   <th>Address</th>
                //   <th>City</th>
                //   <th>State</th>
                //   <th>Zip</th>
                // </tr>
                // </tfoot>
            	}
              </table>
            </div>
	        {
	        //<!-- /.card-body -->
	        }
          </div>
          {
          	//<!-- /.card -->
      	  }
        </div>
        {
        //<!-- /.col -->
    	}
      </div>
      {
      //<!-- /.row -->
  }
    </section>
	</div>

      );
  }
}

export default CustomItems;
