import axios from "axios";

const machine = window.location.href.replace('http://','').split('/')[0]
const apiMachineAddress = 'http://'+ machine + ':8080'


const adminAxios = axios.create({
	baseURL: apiMachineAddress
});

export default adminAxios;
