import React, { Component } from 'react';
import swal from "sweetalert";
import axios from "axios";

class Products extends Component {

  constructor(props){
  	super(props)

  	this.state={
  		searchText:'',
  		products:[],
      topFilter: 'standard'
  	}

  	this.fetch()
  }

  fetch(){
    let self=this

    let searchText = (self.state.searchText||'').trim()

	  if(self.state.searchText.length<3){
      searchText=''
		  // self.setState({products:[]})
		  // return
	  }

    axios
      .get("/api/products/web/"+self.state.searchText)
      .then(res => {
        if (res.data && res.data.length) {
        	self.setState({products:res.data})
        }
        else{

        	self.setState({products:[]})	
        }
        // else if (res.data.result === "error") {
        	console.log({products:res});
        // }
      })
      .catch(error => {
        console.log(error);
      });

  }

  toggleActive(product){
    let self=this
    if(product){

        product.active = !product.active 
        axios.put('/api/products/' + product._id, {
            product
        }).then((data) => {
         self.fetch()
        }).catch((e) => {
            console.log('Update failed, Error ', e)
            swal("Error!", e.message, "error");
            // self.setState({errorMessage:e})
        });

      
    }
  }

  generateSku(){
    let self=this
    let xx=new Date()

     let p1 = xx.getMilliseconds().toFixed(0).padStart(3,'0')
     let p2 = ((xx.getDate() * xx.getDay())%100).toFixed(0).padStart(2,'0')
     let p3 = ((xx.getMinutes()*xx.getHours()+xx.getSeconds())%100).toFixed(0).padStart(2,'0')
    
    return p1+'-'+p2+p3
  }

  createProduct(){
    let self=this
    let sku = self.generateSku()
    axios.post('/api/products',{
      sku,
      name:'New Product',
      description:'[description]',
      metadata:{
        WHOLESALE:self.state.topFilter==='wholesale'?'true':'false'
      },
      price:100,
      inventory:0
    })
    .then( (data) => {
      window.location='/product/'+sku
	  })
    .catch((e) => {
	    	console.log('Creation failed, Error ',e)
			// self.setState({errorMessage:e})
		});
  }




  render() {
		let self=this
    return (
    <div className="content-wrapper">
	  {/* Content Header (Page header) */}
	  <div className="content-header">
	    <div className="container-fluid">
	      <div className="row mb-2">
	        <div className="col-sm-6">
	          <h1 className="m-0 text-dark">
	          Products
	          </h1>
	        </div>{/* /.col */}

	        <div className="col-sm-6">
	          <ol className="breadcrumb float-sm-right">
	            <li style={{marginLeft:'20px'}}>           	
	          		<button type="button" className="btn btn-block btn-success" onClick={()=>{self.createProduct()}}>New</button>
	            </li>
	          </ol>
	        </div>{/* /.col */}
	      </div>{/* /.row */}
	    </div>{/* /.container-fluid */}
	  </div>
	  {/* /.content-header */}
	  {/* Main content */}



    <section className="content">
      <div className="row">
        <div className="col-12">

          <div className="card">
            <div className="card-header">
		        <div className="col-sm-6">
		          <div className="input-group input-group-sm">
                <div style={{display:'inline-block'}}>
                  
                    <input
                      id="product-search"
                      className="form-control form-control-navbar"
                      style={{borderRightWidth: '1px'}}                  
                      autoComplete="off"
                      autoCorrect="off"
                      autoCapitalize="off" 
                      type="search"
                      placeholder="Search"
                      aria-label="Search"
                      onChange={(e)=>{self.setState({searchText:e.target.value},self.fetch)}}
                    />
                </div>
		            <div className="input-group-append">
		              <div className="btn btn-navbar">
		                <i className="fas fa-search" />
		              </div>
		            </div>
                <div style={{display:'inline-block',marginLeft:'20px'}}>
                  <input 
                    type="checkbox"
                    checked={self.state.topFilter==='standard'}
                    onChange={(e)=>{
                      if(e.target.checked){
                        self.setState({topFilter:'standard'})
                      }                      
                    }} />
                    <label style={{marginLeft:'10px'}}>Standard</label>
                </div>
                <div style={{display:'inline-block',marginLeft:'20px'}}>
                  <input 
                    type="checkbox"
                    checked={self.state.topFilter==='wholesale'}
                    onChange={(e)=>{
                      if(e.target.checked){
                        self.setState({topFilter:'wholesale'})
                      } 
                    }} />
                    <label style={{marginLeft:'10px'}}>Wholesale</label>
                </div>
                <div style={{display:'inline-block',marginLeft:'20px'}}>
                  <input 
                    type="checkbox"
                    checked={self.state.topFilter==='all'}
                    onChange={(e)=>{
                      if(e.target.checked){
                        self.setState({topFilter:'all'})
                      } 
                    }} />
                    <label style={{marginLeft:'10px'}}>All</label>
                </div>
		          </div>
	          	</div>
            </div>
            {
            // <!-- /.card-header -->
        	}
            <div className="card-body" style={{maxHeight:'74vh',overflowY:'scroll'}}>
              <table id="example1" className="table table-bordered table-striped">
                <thead>
                <tr>
                  {/* <th>&nbsp;</th> */}
                  <th style={{minWidth:'110px'}}>SKU</th>
                  <th style={{minWidth:'100px',maxWidth:'100px'}}>Image</th>
                  <th>Name/Description</th>
                  <th>Price</th>
                </tr>
                </thead>
                <tbody>
                {self.state.products.filter((pf)=>{
                  switch(self.state.topFilter){
                    case 'standard':
                      return !pf.wholesale;
                    case 'wholesale':
                      return pf.wholesale;
                    case 'all':
                      return true;
                  }
                }).map((c)=>{
                	return(

		                <tr style={c.active?{}:{background:"#f6d4d4"}} key={'c_'+c._id} >
{/*                       
                      <td><a href="#" onClick={(e)=>{
                        console.log(c.active?'deactivate':'activate' + ': ' +c._id)
                        e.preventDefault()
                        self.toggleActive(c)
                      }}>{c.active?'deactivate':'activate'}</a></td> */}

                      <td onClick={()=>{
                    // localStorage.setItem("currentProduct", c._id);
                        window.location='/product/'+c.id
                      }}>
                        <div>{c.sku || '[MISSING]'}</div>
                        
                        {c.vendorSku && (
                          <div>{c.vendorSku}</div>
                        ) || null}
                        {!c.active && (
                          <div>INACTIVE</div>
                        ) || null}
                        {c.wholesale && (
                          <div>WHOLESALE</div>
                        ) || null}
                      </td>
                      <td onClick={()=>{
                    // localStorage.setItem("currentProduct", c._id);
                        window.location='/product/'+c.id
                      }}>
                        {c.image && c.image.length && (
                        <img src={c.image[0]} style={{width:"100px"}} />
                        ) || null}
                      </td>
                      <td onClick={()=>{
                    // localStorage.setItem("currentProduct", c._id);
                        window.location='/product/'+c.id
                      }}>
                        <div>{c.name || '[MISSING]'}</div>
                        {c.shortDescription && (
                        <div>{c.shortDescription}</div>
                        ) || null}
                      </td>
		                  <td onClick={()=>{
                    // localStorage.setItem("currentProduct", c._id);
                      window.location='/product/'+c.id
                    }}>{c.price || '[MISSING]'}</td>
		                </tr>
                	)
                })}
                </tbody>
                {
                // <tfoot>
                // <tr>
                //   <th>Name</th>
                //   <th>Address</th>
                //   <th>City</th>
                //   <th>State</th>
                //   <th>Zip</th>
                // </tr>
                // </tfoot>
            	}
              </table>
            </div>
	        {
	        //<!-- /.card-body -->
	        }
          </div>
          {
          	//<!-- /.card -->
      	  }
        </div>
        {
        //<!-- /.col -->
    	}
      </div>
      {
      //<!-- /.row -->
  }
    </section>
	</div>

      );
  }
}

export default Products;
