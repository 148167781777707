import React, { Component } from "react";
import "react-toastify/dist/ReactToastify.css";
import Header from "./components/header";
import Sidebar from "./components/sidebar";
import Footer from "./components/footer";
import Register from "./components/register";
import Login from "./components/login";
import Dashboard from "./components/dashboard";
import Profile from "./components/profile";
import Passwordreset from "./components/passwordreset";
import Passwordforgot from "./components/passwordforgot";


import AdminCustomers from "./components/admin/customers";
import AdminUsers from "./components/admin/users";
import AdminStatus from "./components/admin/status";
import AdminReports from "./components/admin/reports";
import UserProfile from "./components/admin/userProfile";
import ShoutOut from "./components/admin/shoutOut";

import Customers from "./components/customers/customers";
import Customer from "./components/customers/customer";


import Repairs from "./components/repairs/repairs";
import Repair from "./components/repairs/repair";

import Sales from "./components/sales/sales";
import Sale from "./components/sales/sale";

import Credits from "./components/credits/credits";
import Credit from "./components/credits/credit";

import Orders from "./components/orders/orders";
import Order from "./components/orders/order";

import Appraisals from "./components/appraisals/appraisals";
import Appraisal from "./components/appraisals/appraisal";

import Products from "./components/products/products";
import Product from "./components/products/product";

import CustomerStories from "./components/customerStories/customerStories";
import CustomerStory from "./components/customerStories/customerStory";

import CustomItems from "./components/customItems/customItems";
import CustomItem from "./components/customItems/customItem";

import HomepageFeatures from "./components/homepageFeatures/homepageFeatures";
import HomepageFeature from "./components/homepageFeatures/homepageFeature";


import Coupons from "./components/coupons/coupons";
import Coupon from "./components/coupons/coupon";


import AllTransactions from "./components/transactions/allTransactions";
import Transactions from "./components/transactions/transactions";
import Transaction from "./components/transactions/transaction";
import WebOrders from "./components/transactions/webOrders";


import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";


import axios from "axios";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";

// Check for token to keep user logged in

if (!localStorage.currentShop || (localStorage.currentShop!=='willow' && localStorage.currentShop!=='endless' )) {
  localStorage.setItem("currentShop", 'ejr');

  // localStorage.removeItem("currentCustomer");
  // localStorage.getItem("jwtToken")
}

console.log({localStorage})

if (localStorage.jwtToken) {

  console.log('Valid Token')
    // // Set auth token header auth
    const token = localStorage.jwtToken;
    setAuthToken(token);
    // // Decode token and get user info and exp
    const decoded = jwt_decode(token);
    // // Set user and isAuthenticated
    // store.dispatch(setCurrentUser(decoded));
    // // Check for expired token
    const currentTime = Date.now() / 1000; // to get in milliseconds
    if (decoded.exp < currentTime) {
        // Logout user
        // store.dispatch(logoutUser());
        // Redirect to login
        localStorage.removeItem("currentCustomer");
        localStorage.removeItem("jwtToken");
        window.location='/login'
        window.location.href = "./login";
    }
}
else{
  console.log('No Token')

}
const isLoggedIn = () => {
  return localStorage.getItem("jwtToken") != null;
};

// Protected Route
const SecuredRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>{
      let ili = isLoggedIn()
      // console.log({isLoggedIn})

      return  isLoggedIn ? (
        <Component {...props} />
      ) : (
        <Redirect to="/login" />
      )

    }
      // ternary condition

    }
  />
);
export default class App extends Component {
  constructor(props) {
      super(props)

      this.state={
        tick:new Date().getTime()
          // customer:null
      }
      this.checkDatabase()
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    console.log("update");
  }

  checkDatabase(){
      axios.get('/api/db/test').then((data) => {
          console.log({details:data});
      }).catch((e) => {
          console.log('Error Connecting', e)
      });     
  }

  // componentDidMount() {
  //   // history.pushState(null, null, location.href);
  //   window.onpopstate = (event) => {
  //     // history.go(1);

  //     window.confirm('are you sure');
  //     event.preventDefault()
  //   };
  //   window.history.pushState({}, document.title, window.location.href);
  // }
  // 
  // componentDidUpdate() {
  //   window.history.pushState(null, document.title, window.location.href);
  //   window.addEventListener('popstate', function(event) {
  //     window.history.pushState(null, document.title, window.location.href);
  //   });
  //   history.pushState({}, '', '/newpage');
  // }
  
  
  render() {
    let self=this
    // const {pathname} = this.props.location;
    // //
            // {isLoggedIn() && <Footer />}

    return (
      <Router>
        <Switch>
          <React.Fragment>          
          <div>
            {isLoggedIn() && <Header />}
            {isLoggedIn() && <Sidebar tick={this.state.tick} />}

            <Route exact path="/" component={Login} />
            <Route path="/register" component={Register} />
            <Route path="/login/:notify?" component={Login} />
            <Route path="/password/reset/:token" component={Passwordreset} />
            <Route path="/password/forgot" component={Passwordforgot} />
            <SecuredRoute path="/dashboard" component={Dashboard} />

            <SecuredRoute path="/customers" component={Customers} />
            <Route path="/customer"
              render={(props) => <Customer {...props} onUpdate={()=>{
                console.log({AppUpdating:true})
                self.setState({tick:new Date().getTime()})
              }} />
            }
            />

            <SecuredRoute path="/layaways" component={Transactions} />
            <SecuredRoute path="/allTransactions" component={AllTransactions} />
            <SecuredRoute path="/transactions" component={Transactions} />
            <SecuredRoute path="/transaction" component={Transaction} />
            <SecuredRoute path="/webOrders" component={WebOrders} />

            <SecuredRoute path="/repairs" component={Repairs} />
            <SecuredRoute path="/repair" component={Repair} />

            <SecuredRoute path="/sales" component={Sales} />
            <SecuredRoute path="/sale" component={Sale} />

            <SecuredRoute path="/credits" component={Credits} />
            <SecuredRoute path="/credit" component={Credit} />

            <SecuredRoute path="/orders" component={Orders} />
            <SecuredRoute path="/order" component={Order} />

            <SecuredRoute path="/appraisals" component={Appraisals} />
            <SecuredRoute path="/appraisal" component={Appraisal} />

            <SecuredRoute path="/products" component={Products} />
            <SecuredRoute path="/product" component={Product} />

            <SecuredRoute path="/customerStories" component={CustomerStories} />
            <SecuredRoute path="/customerStory" component={CustomerStory} />

            <SecuredRoute path="/customItems" component={CustomItems} />
            <SecuredRoute path="/customItem" component={CustomItem} />

            <SecuredRoute path="/homepageFeatures" component={HomepageFeatures} />
            <SecuredRoute path="/homepageFeature" component={HomepageFeature} />

            
            <SecuredRoute path="/coupons" component={Coupons} />
            <SecuredRoute path="/coupon" component={Coupon} />
            
            <SecuredRoute path="/profile" component={Profile} />
            <SecuredRoute path="/admin/customers" component={AdminCustomers} />
            <SecuredRoute path="/admin/users" component={AdminUsers} />
            <SecuredRoute path="/admin/status" component={AdminStatus} />
            <SecuredRoute path="/admin/reports" component={AdminReports} />
            <SecuredRoute path="/admin/user" component={UserProfile} />
            <SecuredRoute path="/admin/shoutout" component={ShoutOut} />
          </div>

          </React.Fragment>
        </Switch>
      </Router>
    );
  }
}
