import React, { Component } from 'react';
import swal from "sweetalert";
import axios from "axios";

// import './repairs.css';

class AllTransactions extends Component {

    constructor(props) {
        super(props)

        this.state = {
            customerId: localStorage.getItem("currentCustomer"),
            layawayFilter: (window.location.href.toLowerCase().indexOf('/layaway')>=0),
            searchText:'',
            sortFilter:'created',
            sortDirection:'dec',
            withBalance:false,
            dateRangeType:'week',
            dateRange:{start:null,end:null},
            transactions: [],
            ut: this.parseJwt()
        }

        // this.fetch()
    }
    
    componentDidMount() {
        let self=this
        // console.log({lcustomer:'mounted'})
        this.calculateDateRange('week')
    }
    parseJwt() {
        let token = localStorage.getItem("jwtToken");
        var base64Url = token.split(".")[1];
        var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        var jsonPayload = decodeURIComponent(
            atob(base64)
            .split("")
            .map(function(c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
        );

        return JSON.parse(jsonPayload);
    }

    fetch() {
        let self = this

        // let apiUrl = "/api/transactions/customer/all/"
        let apiUrl = null
        
        let start = self.state.dateRange.start.toISOString().split('T')[0]
        let end = self.state.dateRange.end.toISOString().split('T')[0]

        if (self.state.customerId) {
            apiUrl = "/api/transactions/customer/" + self.state.customerId

	        if(self.state.layawayFilter){
	        	apiUrl += '/layaway'
	        }


            if (self.state.searchText.length > 0 && self.state.searchText.length < 3) {
                self.setState({
                    transactions: []
                })
                return
            }
            else if (self.state.searchText.length >= 3) {
	        	apiUrl += ('/' + self.state.searchText.trim())
            }
        }
        else if(self.state.withBalance){
            apiUrl = `/api/transactions/withBalance`
        }
        else{
          apiUrl = `/api/transactions/all/${start}/${end}`
        //   self.setState({repairs:[]})
        // return
        }

        axios
        // .get("/api/repairs/"+self.state.searchText)
            .get(apiUrl)
            .then(res => {
                if (res.data && res.data.length) {
                    self.setState({
                        transactions: res.data
                    })
                }
                else {

                    self.setState({
                        transactions: []
                    })
                }
                // else if (res.data.result === "error") {
                console.log({
                    transactions: res
                });
                // }
            })
            .catch(error => {
                console.log(error);
            });

    }

    createTransaction() {

        let self = this
        let customer = self.state.customerId
        let user = self.state.ut.id
        let layaway = self.state.layawayFilter
            //{type,title,description,instructions,customer,user,price}

        axios.post('/api/transactions', {
            customer,
            user,
            layaway
        }).then((data) => {
            let transaction = data.data

            console.log({
                transaction
            })
			swal({
				title:"Success!",
				text:(layaway?'Layaway':'Transaction') + ' created successfully',
				type:"success",
				timer:1000
			}).then(value => {
                self.fetch()
			});
            // swal("Success!", transaction.status_message || 'transaction created successfully', "success").then(value => {
            //     // console.log({data})

            //     // window.location='/transaction/'+transaction._id
            //     // self.setState({searchText:'',repairs:[data.data]})
            //     self.fetch()
            // });
        }).catch((e) => {
            console.log('Creation failed, Error ', e)
                // self.setState({errorMessage:e})
        });
    }

    calculateDateRange(type,offset){
		let self=this;

		let now = new Date()
		let start = new Date()
		let end = new Date()

        let withBalance = false
		switch(type){
			case 'year':
				start = new Date(now.getFullYear()+'-01-01T06:00:00.000Z')
				end = new Date(now.getFullYear()+'-12-31T11:59:59.999Z')
				break;
			case 'quarter':
				start = self.getQuarterStart()
				end = new Date()
				break;
			case 'month':
				start = new Date()
				start.setDate(1)
				end = new Date()
				end.setDate(end.getDate()+1)
				break;
			case 'week':
				start = new Date()
				start.setDate(start.getDate()-start.getDay())
				end = new Date()
				break;
			case 'balance':
                withBalance = true
				start = new Date('2016-01-01T06:00:00.000Z')
				end = new Date()
				break;
				

		}
		start.setHours(6,0,0,0)
		console.log({dateRange:{start,end}})
		self.setState({
            withBalance,
			dateRangeType:type,
			dateRange:{start,end}
		},()=>{
			
			console.log({dateRangeType:self.state.dateRangeType,dateRange:self.state.dateRange})
			self.fetch()
		})

	}
	

    getQuarterStart(){
	    let newDt = new Date()
	    let dd = Math.floor((newDt.getMonth())/3)*3

	    newDt.setMonth(dd)
	    newDt.setDate(1)
	    newDt.setHours(12,0,0,0)
	    return newDt
	}



  render() {
	let self=this
        
    let balancesDue = 0
    let transactions = self.state.transactions || []
    transactions.forEach((tx)=>{
        balancesDue += tx.balance || 0
    })


	let lcSearch = `${self.state.searchText.trim()}`.toLowerCase()
	let fTransactions = transactions.filter((tx)=>{
		return `${((tx.customer || {}).lastName || '').toLowerCase()}`.indexOf(lcSearch)>=0
		|| `${((tx.customer || {}).firstName || '').toLowerCase()}`.indexOf(lcSearch)>=0
        || `${(tx.refId || '').toLowerCase()}`.indexOf(lcSearch)>=0
		// || `${(tx.title || '').toLowerCase()}`.indexOf(lcSearch)>=0
		// || `${(tx.description || '').toLowerCase()}`.indexOf(lcSearch)>=0
		// || `${(tx.transaction || '').toLowerCase()}`.indexOf(lcSearch)>=0
	})
    if(self.state.sortFilter && self.state.sortDirection){
        const sfield = self.state.sortFilter
        const sDir = self.state.sortDirection
        fTransactions.sort((a,b)=>{
            if(a[sfield]<b[sfield]){
                return -1
            }
            else if(a[sfield]>b[sfield]){
                return 1
            }
            else{
                return 0
            }
        })
        if(sDir==='dec'){
            fTransactions.reverse()
        }
    }
    return (
    <div className="content-wrapper">
	  {/* Content Header (Page header) */}
	  <div className="content-header">
	    <div className="container-fluid">
	      <div className="row mb-2">
	        <div className="col-sm-6">
	          <h1 className="m-0 text-dark">
	           {self.state.layawayFilter?'All Layaways':'All Transactions'}
	          </h1>
	        </div>{/* /.col */}

	        <div className="col-sm-6">
	          <ol className="breadcrumb float-sm-right">
                <li style={{marginLeft:'20px'}}>            
                    <button type="button" className="btn btn-block btn-primary" onClick={()=>{
                        window.location = '/transactions'
                    }}>Overview</button>
                </li>
	            <li style={{marginLeft:'20px'}}>           	
	          		<button type="button" className="btn btn-block btn-success" onClick={()=>{self.createTransaction()}}>New</button>
	            </li>
	          </ol>
	        </div>{/* /.col */}
	      </div>{/* /.row */}
	    </div>{/* /.container-fluid */}
	  </div>
	  {/* /.content-header */}
	  {/* Main content */}



    <section className="content">
      <div className="row">
        <div className="col-12">

          <div className="card">
            {(!self.state.customerId && (
                <>
                
                    <div className="card-header">
                        <div className="col-sm-6">
                        <div className="input-group input-group-sm">
                            <input
                            id="customer-search"
                            className="form-control form-control-navbar"
                            style={{borderRightWidth: '1px'}}
                            type="search"
                            placeholder="Search"
                            aria-label="Search"
                            value={self.state.searchText}
                            onChange={(e)=>{self.setState({searchText:e.target.value},()=>{
                                self.fetch()
                            })}}
                            />
                            <div className="input-group-append">
                            <div className="btn btn-navbar">
                                <i className="fas fa-search" />
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div style={{display:'block'}}>
                        <a href="#ytd" className={"btn "+(self.state.dateRangeType==='year'?'btn-primary':'btn-secondary')} style={{margin:'3px'}} onClick={(e)=>{
                            e.preventDefault()
                            self.calculateDateRange('year')
                        }}>Year to Date</a>
                        <a href="#qtd" className={"btn "+(self.state.dateRangeType==='quarter'?'btn-primary':'btn-secondary')} style={{margin:'3px'}} onClick={(e)=>{
                            e.preventDefault()
                            self.calculateDateRange('quarter')
                        }}>Quarter to Date</a>
                        <a href="#mtd" className={"btn "+(self.state.dateRangeType==='month'?'btn-primary':'btn-secondary')} style={{margin:'3px'}} onClick={(e)=>{
                            e.preventDefault()
                            self.calculateDateRange('month')
                        }}>Month to Date</a>
                        <a href="#week" className={"btn "+(self.state.dateRangeType==='week'?'btn-primary':'btn-secondary')} style={{margin:'3px'}} onClick={(e)=>{
                            e.preventDefault()
                            self.calculateDateRange('week')
                        }}>This Week</a>
                        <a href="#balance" className={"btn "+(self.state.dateRangeType==='balance'?'btn-primary':'btn-secondary')} style={{margin:'3px'}} onClick={(e)=>{
                            e.preventDefault()
                            self.calculateDateRange('balance')
                        }}>With Balance</a>
                        {(self.state.dateRangeType==='balance' && (
                            <div className={"btn"} style={{display:'inline-block',marginLeft:"20px"}}>
                                <div style={{fontSize:'1em',lineHeight:'1.3em'}}>Total Balances Due: ${balancesDue.toLocaleString('en-US',2)}</div>
                                <div style={{fontSize:'.7em',lineHeight:'.7em'}}>*Includes Layaways and Ordered Merchandise</div>
                            </div>
                        )) || null}
                    </div>
                </>
            )) || null}
            {
            // <!-- /.card-header -->
        	}
            <div className="card-body">
              <table id="example1" className="table table-bordered table-striped">
                <thead>
                <tr>
                {(!self.state.customerId && (
                  <th>Customer</th>
                )) || null}
                  <th>Ref</th>
                  <th>
                      <a href="#" onClick={(e)=>{
                          e.preventDefault()
                          let sortFilter='created'
                          let sortDirection=self.state.sortDirection==='dec'?'inc':'dec'
                          self.setState({sortFilter,sortDirection})                      
                        }}>Created {self.state.sortFilter==='created'?(self.state.sortDirection==='dec'?'↓':'↑'):''}</a>
                  </th>
                  <th>
                      <a href="#" onClick={(e)=>{
                          e.preventDefault()
                          let sortFilter='lineItemTotal'
                          let sortDirection=self.state.sortDirection==='dec'?'inc':'dec'
                          self.setState({sortFilter,sortDirection})
                        }}>Total {self.state.sortFilter==='lineItemTotal'?(self.state.sortDirection==='dec'?'↓':'↑'):''}</a>
                  </th>
                  <th>
                      <a href="#" onClick={(e)=>{
                          e.preventDefault()
                          let sortFilter='balance'
                          let sortDirection=self.state.sortDirection==='dec'?'inc':'dec'
                          self.setState({sortFilter,sortDirection})
                        }}>Balance {self.state.sortFilter==='balance'?(self.state.sortDirection==='dec'?'↓':'↑'):''}</a>
                  </th>
                </tr>
                </thead>
                <tbody>
                {fTransactions.map((t,tx)=>{
                	let elipsis = {
                		maxWidth: '20vw',
    					overflow: 'hidden',
    					whiteSpace: 'nowrap',
    					textOverflow: 'ellipsis'
    				}
    				let row_bg = ''
    				// if(!t.balance>0){
    				// 	row_bg = 'bg-pending'
    				// 	if(tx%2==1){
    				// 		row_bg = 'bg-pending-alt'
    				// 	}
    				// }
                    let repairDesc= null
                    let saleDesc = null
                    if(t.repairDesc && t.repairDesc.length){
                        repairDesc = (
                            <div>
                                <b>Repairs:</b> {t.repairDesc}
                            </div>
                        )
                    }
                    if(t.saleDesc && t.saleDesc.length){
                        saleDesc = (
                            <div>
                                <b>Sales:</b> {t.saleDesc}
                            </div>
                        )
                    }
                	return(

		                <tr key={'t_'+t._id} className={row_bg} onClick={()=>{
                            localStorage.setItem("currentCustomer", t.customer._id);
		                	window.location='/transaction/'+t._id
		                }}>
                        {(!self.state.customerId &&  (
                            <td>
                                <div>{[(t.customer || {}).lastName,(t.customer || {}).firstName].join(', ')}</div>
                                {(t.customer && t.customer.phone && (
                                    <div>P: {t.customer.phone}</div>
                                )) || null}
                                {(t.customer && t.customer.cellPhone && (
                                    <div>C: {t.customer.cellPhone}</div>
                                )) || null}
                                {(t.customer && t.customer.workPhone && (
                                    <div>W: {t.customer.workPhone}</div>
                                )) || null}
                                {(t.customer && t.customer.email && (
                                    <div>E: {t.customer.email}</div>
                                )) || null}
                            </td>
                        )) || null}
		                  <td>
                            <div>{t.refId}<b>{t.layaway?' (Layaway)':''}</b><br/>{t._id}</div>
                            {repairDesc}
                            {saleDesc}
                          </td>
		                  <td style={{whiteSpace: 'nowrap'}}>{(t.created||'').split('T')[0]}<br/>{new Date(t.created||'').toLocaleTimeString()}</td>
		                  <td style={{textAlign: 'right'}}>${(t.lineItemTotal+t.taxTotal).toFixed(2)}</td>
		                  <td style={{textAlign: 'right'}}>${t.balance.toFixed(2)}</td>
		                </tr>
                	)
                })}
                </tbody>
              </table>
            </div>
	        {
	        //<!-- /.card-body -->
	        }
          </div>
          {
          	//<!-- /.card -->
      	  }
        </div>
        {
        //<!-- /.col -->
    	}
      </div>
      {
      //<!-- /.row -->
  	}
    </section>
	</div>

      );
  }
}

export default AllTransactions;
