import React, { Component } from 'react';
import swal from "sweetalert";
import axios from "axios";
import adminAxios from "../../utils/adminAxios";

import Modal from "../../modal";

import DatePicker from "react-datepicker";
import setMinutes from "date-fns/setMinutes";
import setHours from "date-fns/setHours";
import "react-datepicker/dist/react-datepicker.css";

const DatepickerInput = ({ ...props }) => (
  <input type="text" {...props} readOnly />
);

class TimeSheet extends Component {

  constructor(props){
  	super(props)


	let weekOf = new Date()
	weekOf.setDate(weekOf.getDate()-weekOf.getDay())
	weekOf.setHours(0,0,0,0)

  	this.state={
  		weekOf,
  		editDate:null,
  		showModal:false,
  		thisWeek:new Date(weekOf),
  		userId:this.props.userId,
  		users:[],
      	ut:this.parseJwt(),
      	timeSheets:[]
  	}
    this.fetchUsers()
  }
  parseJwt() {
    let token = localStorage.getItem("jwtToken");
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function(c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  fetchUsers(callback){
	let self=this

    axios
      .get("/api/users")
      .then(res => {
        if (res.data && res.data.length) {
        	self.setState({users:res.data},()=>{
        		self.fetchTimeSheets(callback)
        	})
        }
        else{

        	self.setState({users:[]},()=>{
        		self.fetchTimeSheets(callback)
        	})	
        }
        // else if (res.data.result === "error") {
        	console.log({users:res});
        // }
      })
      .catch(error => {
        console.log(error);
      });

  }

  addEntry(dt){
  	let self=this
    axios
      .post("/api/timeSheet/"+self.state.userId,{punchDate:dt})
      .then(res => {
        self.fetchTimeSheets()
      })
      .catch(error => {
        console.log(error);
      });
  }
  editTimeSheetEntry(timeSheet,dt){
  	let self=this

  	console.log({timeSheet,dt})
    axios
      .put("/api/timeSheet/"+timeSheet._id,{timeSheet:{punchDate:dt}})
      .then(res => {
        self.fetchTimeSheets()
      })
      .catch(error => {
        console.log(error);
      });
  }
  deleteTimeSheetEntry(timeSheet){
  	let self=this

  	// console.log({timeSheet,dt})
    axios
      .delete("/api/timeSheet/"+timeSheet._id)
      .then(res => {
        self.fetchTimeSheets()
      })
      .catch(error => {
        console.log(error);
      });
  }

  fetchTimeSheets(callback){
    let self=this
    let wDate = new Date().toISOString().split('T')[0]
    console.log({fetch:"/api/timeSheet/"+this.state.userId})
    axios
      .get("/api/timeSheet/view/"+self.props.userId+"/"+self.state.weekOf.toISOString().split('T')[0])
      .then(res => {
        if (res.data) {
          console.log({ts:res.data,u:self.state.userId});
          self.setState({timeSheets:res.data.filter((ts)=>{
          	return ts.user===self.state.userId
          })},callback)
        }
        else{
          self.setState({timeSheets:[]},callback) 
        }
        // else if (res.data.result === "error") {
          // console.log({timesheets:res.data});
        // }
      })
      .catch(error => {
        console.log(error);
      });

  }

  getPunchUser(id){
  	console.log({getPunchUser:id})
  }

  punchClock(callback){
    let self=this
    axios
      .post("/api/timeSheet")
      .then(res => {
      	self.fetchTimeSheets(callback)
      })
      .catch(error => {
        console.log(error);
      });

  }

  isCurrentWeek(){
  	return this.state.thisWeek.getDate() === this.state.weekOf.getDate()
  }
  lastWeek(){
  	let self = this
  	let weekOf = self.state.weekOf
  	weekOf.setDate(weekOf.getDate()-7)
  	self.setState({weekOf},self.fetchTimeSheets)
  }
  nextWeek(){
  	let self = this
  	let weekOf = self.state.weekOf
  	weekOf.setDate(weekOf.getDate()+7)
  	self.setState({weekOf},self.fetchTimeSheets)
  }

  getEditButton(day,dt){
  	let self= this
  	if(self.state.ut.admin){
  		return (
  			<input type="button" value={day + ' ' + dt.getDate()} className="btn btn-block btn-primary" onClick={()=>{
		          self.setState({editDate:dt,showModal:true})
		    }} />
		)
  	}
  	else{
  		return day + ' ' + dt.getDate() 
  	}

  }

  render(){
  	let self = this


	let editorModal = null



  	let days=[]
  	let punches=[]
  	for(let i=0;i<7;i++){
  		let dd = new Date(self.state.weekOf)
  		dd.setDate(dd.getDate()+i)
  		days.push(dd)

  		// punches.push([])
  		punches.push(self.state.timeSheets.filter((ts)=>{
            let pd=new Date(ts.punchDate)
  			return pd.toLocaleString().split(',')[0] === dd.toLocaleString().split(',')[0]
  		}))
  	}
  	let tally = []
  	let total=0
  	punches.forEach((p)=>{
  		let time = 0
  		let pArr = [].concat(p)

  		if(pArr.length %2===1){
  			pArr = pArr.slice(0,pArr.length-1)
  		}
  		for(let pi=0;pi<pArr.length;pi+=2){
  			// console.log(pArr[pi])
  			time += new Date(pArr[pi+1].punchDate).getTime()-new Date(pArr[pi].punchDate).getTime()
  		}
  		tally.push(time)
  		total+=time
  	})
  	if(self.state.timeSheets[0]){

  		console.log(self.state.timeSheets[0].punchDate)	
  	}
  	console.log({punches,ts:self.state.timeSheets})

  	let punchButton=null
  	if(self.isCurrentWeek()){

	  	let todayPunches=self.state.timeSheets.filter((ts)=>{
	    	let pd=new Date(ts.punchDate)
			return pd.toLocaleString().split(',')[0] === new Date().toLocaleString().split(',')[0]
		})
		if(self.state.ut.id===self.state.userId){

		  	if(todayPunches.length%2==0){
		  		punchButton=(
			        <input type="button" value={'Clock In'} className="btn btn-block btn-success" onClick={()=>{
			          self.punchClock()
			        }} />
		  		)
		  	}
		  	else{
		  		punchButton=(
			        <input type="button" value={'Clock Out'} className="btn btn-block btn-warning" onClick={()=>{
			          self.punchClock()
			        }} />
		  		)

		  	}
		}
  	}
	if(self.state.showModal){

		editorModal = (

			<Modal>
				<div className="modal">

					<div style={{position:'absolute',top:0,left:0,bottom:0,right:0,background:'rgba(0,0,0,.01)',color:'white',zIndex:999}}
						 onClick={(e)=>{
						 	self.setState({showModal:false})
					}}></div>
					<div style={{position:'relative',background:'white',width:'70vw',height:'70vmin',zIndex:1000}}>
						
						  <div className="content-header">
						    <div className="container-fluid">
						      <div className="row mb-2">
						        <div className="col-12">
						          <h1 className="m-0 text-dark">
						          Edit Timesheet for {self.state.editDate.toString().split(' ')[0].trim()}, {self.state.editDate.toLocaleString().split(',')[0]}
						          </h1>
						        </div>{/* /.col */}
						      </div>{/* /.row */}
						    </div>{/* /.container-fluid */}
						  </div>


						    <section className="content">
						      <div className="row">
						        <div className="col-2">
						        </div>
						        <div className="col-6">
						        {punches[self.state.editDate.getDay()].map((p,px)=>{
	                            	let pd=new Date(p.punchDate)
	                            	return(
	                            		<div className="row" key={'dp_'+px} style={{marginTop:'3px'}}>
						        			<div className="col-8">
						                      <DatePicker
						            			selected={pd}
						                        onChange={date => {
						                        	self.editTimeSheetEntry(p,date)
						                        }}
						                        showTimeSelect
						                        showTimeSelectOnly
						                        timeIntervals={15}
						                        timeCaption="time"
						                        minTime={setHours(setMinutes(new Date(), 0), 8)}
						                        maxTime={setHours(setMinutes(new Date(), 0), 20)}
						                        dateFormat="h:mm aa"
						                        customInput={<DatepickerInput className="form-control" />}
						                      />
	                            			</div>
						        			<div className="col-4">

										        <input style={{marginLeft:'10px'}} type="button" value={'Delete'} className="btn btn-block btn-danger" onClick={()=>{
										          self.deleteTimeSheetEntry(p)
										        }} />
	                            			</div>
	                            		</div>
	                            	)
	                            })}
						        </div>
						        <div className="col-1">

						        </div>
					          </div>
						      <div className="row" style={{marginTop:'10px'}}>
						        <div className="col-2">
						        </div>
						        <div className="col-3">

							        <input type="button" value={'Add Entry'} className="btn btn-block btn-warning" onClick={()=>{
							          self.addEntry(self.state.editDate)
							        }} />
						        </div>
						      </div>
					        </section>
					        <section style={{position:'absolute',bottom:'10px',width:'100%'}}>
						      <div className="row">
						        <div className="col-4">
						        </div>
						        <div className="col-4">
						          	<button type="button" className="btn btn-block btn-primary" onClick={()=>{self.setState({showModal:false})}}>Close</button>
						        </div>
						      </div>
					        </section>

			        </div>
		        </div>
		    </Modal>
		)
	}

  	console.log({ts:self.state.timeSheets})

  	return (

            <div className="row">

                <div className="col-12 d-flex align-items-stretch">
                  <div className="card card-primary" style={{width: '100%',background:'#f8f8f8'}}>
                    <div className="card-header" style={{background:'#343a40'}}>

                      <div className="row">
                        <div className="col-2 pl-0 pr-0 pt-1" style={{width:'100%',fontWeight:'bold',fontSize:'1.2em',paddingRight:'10px'}}>
	                        <span>Time Sheet</span>
                        </div>
                        <div className="col-8 pl-0 pr-0 text-center" style={{width:'100%',fontWeight:'bold',fontSize:'1.2em',paddingRight:'10px'}}>

                        	<input type="button" value={'PREV'} className="btn btn-success" onClick={()=>{self.lastWeek()}} style={{marginRight:'10px'}} />
                        	<span>Week Of: {self.state.weekOf.toISOString().split('T')[0]}</span>
                        	<input type="button" value={'NEXT'} className="btn btn-success" onClick={()=>{self.nextWeek()}} style={{marginLeft:'10px'}} />
                        </div>
                        <div className="col-4 pl-0 pr-0">
                        </div>
                      </div>
                    </div>
                    <div className="card-body">

                      <div className="row">
                        <div className="col-6 pl-0 pr-0">

		                      <div className="row">
		                        <div className="col-3 pl-0 pr-0">
		                          <div className="text-right" style={{width:'100%',fontWeight:'bold',fontSize:'1.2em',paddingRight:'5px',paddingLeft:'10px'}}>
		                            {punchButton}
		                          </div>
		                        </div>
		                        <div className="col-3 pl-0 pr-0">
		                          <div className="text-right" style={{width:'100%',fontWeight:'bold',fontSize:'1.2em',paddingRight:'5px',paddingLeft:'10px'}}>
		                            {self.getEditButton('Sun',days[0])}
		                          </div>
		                        </div>
		                        <div className="col-3 pl-0 pr-0">
		                          <div className="text-right" style={{width:'100%',fontWeight:'bold',fontSize:'1.2em',paddingRight:'5px',paddingLeft:'10px'}}>
		                            {self.getEditButton('Mon',days[1])}
		                          </div>
		                        </div>
		                        <div className="col-3 pl-0 pr-0">
		                          <div className="text-right" style={{width:'100%',fontWeight:'bold',fontSize:'1.2em',paddingRight:'10px',paddingLeft:'10px'}}>
		                            {self.getEditButton('Tue',days[2])}
		                          </div>
		                        </div>
		                      </div>
                        </div>
                        <div className="col-6 pl-0 pr-0">
		                      <div className="row">
		                        <div className="col-3 pl-0 pr-0">
		                          <div className="text-right" style={{width:'100%',fontWeight:'bold',fontSize:'1.2em',paddingRight:'5px',paddingLeft:'15px'}}>
		                            {self.getEditButton('Wed',days[3])}
		                          </div>
		                        </div>
		                        <div className="col-3 pl-0 pr-0">
		                          <div className="text-right" style={{width:'100%',fontWeight:'bold',fontSize:'1.2em',paddingRight:'5px',paddingLeft:'10px'}}>
		                            {self.getEditButton('Thu',days[4])}
		                          </div>
		                        </div>
		                        <div className="col-3 pl-0 pr-0">
		                          <div className="text-right" style={{width:'100%',fontWeight:'bold',fontSize:'1.2em',paddingRight:'5px',paddingLeft:'10px'}}>
		                            {self.getEditButton('Fri',days[5])}
		                          </div>
		                        </div>
		                        <div className="col-3 pl-0 pr-0">
		                          <div className="text-right" style={{width:'100%',fontWeight:'bold',fontSize:'1.2em',paddingRight:'5px',paddingLeft:'10px'}}>
		                            {self.getEditButton('Sat',days[6])}
		                          </div>
		                        </div>
		                      </div>
                        </div>
                      </div>




                      <div className="row">
                        <div className="col-6 pl-0 pr-0">
	                      <div className="row">
	                        <div className="col-3 pl-0 pr-0">
	                          <div className="text-center" style={{width:'100%',fontWeight:'bold',fontSize:'1.2em',paddingRight:'10px'}}>
	                            
	                          </div>
	                        </div>
	                        <div className="col-3 pl-0 pr-0">
	                          <div className="text-right" style={{width:'100%',paddingRight:'10px'}}>
	                            {punches[0].map((p,px)=>{
	                            	let pd=new Date(p.punchDate)
	                            			// <i style={{color:(px%2==0?'green':'red')}} className={"fas fa-"+ (px%2==0?'play-circle':'stop-circle')+" ml-1 mr-2"} />
	                            	return(
	                            		<div key={'p_0_'+px}>
	                            			{self.getPunchUser(p.enterUser)}
	                            			
	                            			{pd.toLocaleTimeString().replace(/\:[0-9][0-9]\ /,'')}
	                            		</div>
	                            	)
	                            })} 
	                          </div>
	                        </div>
	                        <div className="col-3 pl-0 pr-0">
	                          <div className="text-right" style={{width:'100%',paddingRight:'10px'}}>
	                            {punches[1].map((p,px)=>{
	                            	let pd=new Date(p.punchDate)
	                            	return(
	                            		<div key={'p_1_'+px}>
	                            			{self.getPunchUser(p.enterUser)}
	                            			
	                            			{pd.toLocaleTimeString().replace(/\:[0-9][0-9]\ /,'')}
	                            		</div>
	                            	)
	                            })} 
	                          </div>
	                        </div>
	                        <div className="col-3 pl-0 pr-0">
	                          <div className="text-right" style={{width:'100%',paddingRight:'10px'}}>
	                            {punches[2].map((p,px)=>{
	                            	let pd=new Date(p.punchDate)
	                            	return(
	                            		<div key={'p_2_'+px}>
	                            			{self.getPunchUser(p.enterUser)}
	                            			
	                            			{pd.toLocaleTimeString().replace(/\:[0-9][0-9]\ /,'')}
	                            		</div>
	                            	)
	                            })} 
	                          </div>
	                        </div>
	                      </div>
                        </div>
                        <div className="col-6 pl-0 pr-0">
	                      <div className="row">
	                        <div className="col-3 pl-0 pr-0">
	                          <div className="text-right" style={{width:'100%',paddingRight:'10px'}}>
	                            {punches[3].map((p,px)=>{
	                            	let pd=new Date(p.punchDate)
	                            	return(
	                            		<div key={'p_3_'+px}>
	                            			{self.getPunchUser(p.enterUser)}
	                            			
	                            			{pd.toLocaleTimeString().replace(/\:[0-9][0-9]\ /,'')}
	                            		</div>
	                            	)
	                            })}
	                          </div>
	                        </div>
	                        <div className="col-3 pl-0 pr-0">
	                          <div className="text-right" style={{width:'100%',paddingRight:'10px'}}>
	                            {punches[4].map((p,px)=>{
	                            	let pd=new Date(p.punchDate)
	                            	return(
	                            		<div key={'p_4_'+px}>
	                            			{self.getPunchUser(p.enterUser)}
	                            			
	                            			{pd.toLocaleTimeString().replace(/\:[0-9][0-9]\ /,'')}
	                            		</div>
	                            	)
	                            })}
	                          </div>
	                        </div>
	                        <div className="col-3 pl-0 pr-0">
	                          <div className="text-right" style={{width:'100%',paddingRight:'10px'}}>
	                            {punches[5].map((p,px)=>{
	                            	let pd=new Date(p.punchDate)
	                            	return(
	                            		<div key={'p_5_'+px}>
	                            			{self.getPunchUser(p.enterUser)}
	                            			
	                            			{pd.toLocaleTimeString().replace(/\:[0-9][0-9]\ /,'')}
	                            		</div>
	                            	)
	                            })}
	                          </div>
	                        </div>
	                        <div className="col-3 pl-0 pr-0">
	                          <div className="text-right" style={{width:'100%',paddingRight:'10px'}}>
	                            {punches[6].map((p,px)=>{
	                            	let pd=new Date(p.punchDate)
	                            	return(
	                            		<div key={'p_6_'+px}>
	                            			{self.getPunchUser(p.enterUser)}
	                            			
	                            			{pd.toLocaleTimeString().replace(/\:[0-9][0-9]\ /,'')}
	                            		</div>
	                            	)
	                            })}
	                          </div>
	                        </div>
	                      </div>
                        </div>
                      </div>



                    </div>
                    <div className="card-footer">
                      
                      <div className="row">
                        <div className="col-6 pl-0 pr-0">

	                      <div className="row">
	                        <div className="col-3 pl-0 pr-0">
	                          <div className="text-right" style={{width:'100%',fontWeight:'bold',fontSize:'1.2em',paddingRight:'10px'}}>
	                            {(total/(60*60*1000)).toFixed(2)}
	                            
	                          </div>
	                        </div>
	                        <div className="col-3 pl-0 pr-0">
	                          <div className="text-right" style={{width:'100%',paddingRight:'10px'}}>
	                          	{(tally[0]/(60*60*1000)).toFixed(2)}
	                            {
	                            //	new Date(tally[0]).toISOString().split('T')[1].split('.')[0].slice(0,5)
	                            } 
	                          </div>
	                        </div>
	                        <div className="col-3 pl-0 pr-0">
	                          <div className="text-right" style={{width:'100%',paddingRight:'10px'}}>
	                          	{(tally[1]/(60*60*1000)).toFixed(2)}
	                          </div>
	                        </div>
	                        <div className="col-3 pl-0 pr-0">
	                          <div className="text-right" style={{width:'100%',paddingRight:'10px'}}>
	                          	{(tally[2]/(60*60*1000)).toFixed(2)}
	                          </div>
	                        </div>
	                      </div>
                        </div>
                        <div className="col-6 pl-0 pr-0">

	                      <div className="row">
	                        <div className="col-3 pl-0 pr-0">
	                          <div className="text-right" style={{width:'100%',paddingRight:'10px'}}>
	                          	{(tally[3]/(60*60*1000)).toFixed(2)}
	                          </div>
	                        </div>
	                        <div className="col-3 pl-0 pr-0">
	                          <div className="text-right" style={{width:'100%',paddingRight:'10px'}}>
	                          	{(tally[4]/(60*60*1000)).toFixed(2)}
	                          </div>
	                        </div>
	                        <div className="col-3 pl-0 pr-0">
	                          <div className="text-right" style={{width:'100%',paddingRight:'10px'}}>
	                          	{(tally[5]/(60*60*1000)).toFixed(2)}
	                          </div>
	                        </div>
	                        <div className="col-3 pl-0 pr-0">
	                          <div className="text-right" style={{width:'100%',paddingRight:'10px'}}>
	                          	{(tally[6]/(60*60*1000)).toFixed(2)}
	                          </div>
	                        </div>
	                      </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                {editorModal}
            </div>
  	)
  }
}

export default TimeSheet;