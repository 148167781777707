import React, { Component } from 'react';
import swal from "sweetalert";
import axios from "axios";

class Customers extends Component {

  constructor(props){
  	super(props)

  	this.state={
  		searchText:'',
  		customers:[]
  	}

  	// this.fetch()
  }

  fetch(){
	let self=this

	if(self.state.searchText.length<3){
		self.setState({customers:[]})
		return
	}

    axios
      .get("/api/customers/"+self.state.searchText)
      .then(res => {
        if (res.data && res.data.length) {
        	self.setState({customers:res.data})
        }
        else{

        	self.setState({customers:[]})	
        }
        // else if (res.data.result === "error") {
        	console.log({customers:res});
        // }
      })
      .catch(error => {
        console.log(error);
      });

  }

    importCustomers(data) {

            axios.post('/api/customers/import', {
                data
            }).then((data) => {
                    console.log({
                        data
                    })
                    swal("Success!", 'Customers imported successfully', "success").then(value => {


                    }).catch((e) => {
                        console.log('Import failed, Error ', e)
                            // self.setState({errorMessage:e})
                    });
                })
    }
  createCustomer(){

		let self=this
		let firstName='FIRST'
		let lastName='LAST'

		axios.post('/api/customers',{firstName,lastName}).then( (data) => {
			let customer = data.data
			swal("Success!", 'Customer created successfully', "success").then(value => {
            

				localStorage.setItem("currentCustomer", customer._id);
        		window.location='/customer/'//+c._id
        	// self.setState({searchText:'',customers:[data.data]})
          	// self.fetch()
        	});
	    }).catch((e) => {
	    	console.log('Creation failed, Error ',e)
			// self.setState({errorMessage:e})
		});
  }




  render() {
		let self=this
    return (
    <div className="content-wrapper">
	  {/* Content Header (Page header) */}
	  <div className="content-header">
	    <div className="container-fluid">
	      <div className="row mb-2">
	        <div className="col-sm-6">
	          <h1 className="m-0 text-dark">
	          Customers
	          </h1>
	        </div>{/* /.col */}

	        <div className="col-sm-6">
	          <ol className="breadcrumb float-sm-right">
              <li style={{marginLeft:'20px'}}>            
                
                        <div className="custom-file">
                          <input
                            type="file"
                            onChange={e => {
                              e.preventDefault();

                              let file = e.target.files[0]
                              console.log({file})
                              var reader = new FileReader();
                              reader.onload = function(e) {
                                
                                var text = e.target.result.replace(/N\/A/gi,'').replace(/NULL/gi,'').replace(/\r\n/gi,'\n');
                                var lines = text.split('\n')
                                var arrs = lines.map((l)=>{
                                  return l.split(',').map((v)=>{return v.trim()})
                                })
                                var headers= arrs[0]
                                var oarr = arrs.slice(1).map((a)=>{
                                  var obj = {}
                                  headers.forEach((h,hx)=>{
                                    if(h && h.trim().length && a[hx] && a[hx].trim().length){
                                      obj[h]=a[hx] 
                                    }
                                  })
                                  if(obj['PHONE1'] && obj['PHONE2'] && obj['PHONE3']){

                                    var phone = obj['PHONE1'].padStart(3,'0').slice(0,3) + obj['PHONE2'].padStart(3,'0').slice(0,3) + obj['PHONE3'].padStart(4,'0').slice(0,4)

                                    delete obj['PHONE1']
                                    delete obj['PHONE2']
                                    delete obj['PHONE3']
                                    if(obj['PHONE TYPE']=='Cell'){
                                      obj.cellPhone = phone
                                    }
                                    else if(obj['PHONE TYPE']=='Work'){
                                      obj.workPhone = phone
                                      
                                    }
                                    else{
                                      obj.phone = phone
                                    }
                                  }
                                  
                                  delete obj['MI']
                                  delete obj['ADDED']
                                  delete obj['PHONE TYPE']
                                  delete obj['XX']
                                  delete obj['ZIP4']
                                  return obj
                                })

                                self.importCustomers(oarr)
                              };
                              reader.readAsText(file);
                              
                            }}
                            
                            className="custom-file-input"
                            id="exampleInputFile"
                          />
                          <label className="custom-file-label" htmlFor="exampleInputFile">
                            Import
                          </label>
                        </div>
              </li>
              <li style={{marginLeft:'20px'}}>            
                <button type="button" className="btn btn-block btn-success" onClick={()=>{self.createCustomer()}}>New</button>
              </li>
	          </ol>
	        </div>{/* /.col */}
	      </div>{/* /.row */}
	    </div>{/* /.container-fluid */}
	  </div>
	  {/* /.content-header */}
	  {/* Main content */}



    <section className="content">
      <div className="row">
        <div className="col-12">

          <div className="card">
            <div className="card-header">
		        <div className="col-sm-6">
		          <div className="input-group input-group-sm">
		            <input
		              id="customer-search"
		              className="form-control form-control-navbar"
		              style={{borderRightWidth: '1px'}}
		              type="search"
		              placeholder="Search"
		              aria-label="Search"
		              onChange={(e)=>{self.setState({searchText:e.target.value},self.fetch)}}
		            />
		            <div className="input-group-append">
		              <div className="btn btn-navbar">
		                <i className="fas fa-search" />
		              </div>
		            </div>
		          </div>
	          	</div>
            </div>
            {
            // <!-- /.card-header -->
        	}
            <div className="card-body">
              <table id="example1" className="table table-bordered table-striped">
                <thead>
                <tr>
                  <th>Name</th>
                  <th>Address</th>
                  <th>Phone</th>
                  <th>Email</th>
                  <th></th>
                </tr>
                </thead>
                <tbody>
                {self.state.customers.map((c)=>{
                	let name = [c.lastName,c.firstName].filter((np)=>{return np.trim().length>0}).join(', ')
                	return(

		                <tr key={'c_'+c._id} onClick={()=>{
          					localStorage.setItem("currentCustomer", c._id);
		                	window.location='/customer/'//+c._id
		                }}>
		                  <td>{name}</td>
		                  <td>
                        {c.address1}<br/>
                        {c.address2}<br/>
                        {c.address3}<br/>
                        {c.city}, {c.state} {c.zip}
                      </td>
		                  <td>
                        {c.phone}<br/>  
                        {c.cellPhone}<br/>  
                        {c.workPhone}<br/>  
                      </td>
		                  <td>{c.email}</td>
		                </tr>
                	)
                })}
                </tbody>
                {
                // <tfoot>
                // <tr>
                //   <th>Name</th>
                //   <th>Address</th>
                //   <th>City</th>
                //   <th>State</th>
                //   <th>Zip</th>
                // </tr>
                // </tfoot>
            	}
              </table>
            </div>
	        {
	        //<!-- /.card-body -->
	        }
          </div>
          {
          	//<!-- /.card -->
      	  }
        </div>
        {
        //<!-- /.col -->
    	}
      </div>
      {
      //<!-- /.row -->
  }
    </section>
	</div>

      );
  }
}

export default Customers;
