import React, { Component } from 'react';
import swal from "sweetalert";
import axios from "axios";

import './sales.css';

import DatePicker from "react-datepicker";
import setMinutes from "date-fns/setMinutes";
import setHours from "date-fns/setHours";
import "react-datepicker/dist/react-datepicker.css";


import Modal from "../../modal";

import Gallery from 'react-grid-gallery';

import PhotoEditor from "../photoEditor/photoEditor";
// const Buffer = require('buffer').Buffer 
// const fs = require('fs');

const DatepickerInput = ({ ...props }) => (
  <input type="text" {...props} readOnly />
);

class Sale extends Component {

  constructor(props){
  	super(props)

  	let qvals={}
  	let selectedTab

  	(window.location.href.split('?')[1]||'').split('&').map((vv)=>{
    	let vx=vv.split('=')
    	qvals[vx[0]]=vx[1]
	})
	if(qvals.selectedTab){
		selectedTab = qvals.selectedTab
	}

  	this.state={
  		currentImage:null,
		searchText:'',
		showLookupModal:false,
  		selectedTab:selectedTab||'description',
  		isDirty:false,
  		saleId:window.location.href.split('/sale/')[1].split('/').slice(0)[0],
  		sale:{},
  		photos:[],
  		products:[]
  	}

  	this.fetchSale()
  }

  fetchPhotos(){
	let self=this

    axios
      .get("/api/photos/sales/"+self.state.saleId)
      .then(res => {
        if (res.data) {
        	self.setState({photos:res.data})
        }
        else{

        	self.setState({photos:{}})	
        }
        // else if (res.data.result === "error") {
        	console.log({photos:res});
        // }
      })
      .catch(error => {
        console.log(error);
      });

  }
  fetchSale(){
	let self=this

    axios
      .get("/api/sales/view/"+self.state.saleId)
      .then(res => {
        if (res.data) {
        	self.setState({sale:res.data},self.fetchPhotos)
        }
        else{

        	self.setState({sale:{}})	
        }
        // else if (res.data.result === "error") {
        	console.log({sale:res});
        // }
      })
      .catch(error => {
        console.log(error);
      });
  }
  
  
  fetchProducts(){
    let self=this

    let searchText = (self.state.searchText||'').trim()

	  if(self.state.searchText.length<3){
    	//   searchText=''
		  self.setState({products:[]})
		  return
	  }

    axios
      .get("/api/products/web/"+self.state.searchText)
      .then(res => {
        if (res.data && res.data.length) {
        	self.setState({products:res.data})
        }
        else{

        	self.setState({products:[]})	
        }
        // else if (res.data.result === "error") {
        	console.log({products:res});
        // }
      })
      .catch(error => {
        console.log(error);
      });

  }

  updateField(field,value){
    let sale = this.state.sale
    sale[field]=value
    this.setState({sale,isDirty:true})
  }
  updateFields(fields,values){
    let sale = this.state.sale
    fields.forEach((f,fx)=>{
    	sale[f]=values[fx]	
    })
    
    this.setState({sale,isDirty:true})
  }

  update(callback){
    let self=this
    let sale = this.state.sale

    axios.put('/api/sales/'+sale._id,{sale}).then( (data) => {
      console.log(`Sale updated successfully`);
      	if(callback){
      		callback()
      	}
      	else{

			swal({
				title:"Success!",
				text:'Sale updated successfully',
				type:"success",
				timer:1000
			}).then(value => {
      		window.location='/sale/'+sale._id+'?t='+new Date().getTime() + '&selectedTab='+self.state.selectedTab
			});    		
      	}
    }).catch((e) => {
    	console.log('Creation failed, Error ',e)
    	swal("Error!", e.message, "error");
		// self.setState({errorMessage:e})
	});
  }
  detach(){
  		let self = this
	  	let additionalWarning=''

  	  	if(this.state.sale.price>=0.01 || this.state.sale.price<=-0.01){
		  	if(this.state.sale.transaction && (this.state.sale.transaction.balance>=0.01 || this.state.sale.transaction.balance<=-0.01)){
		  		additionalWarning = 'This transaction associated with this sale has a balance.  Deleting this may mess up the balance of the associated transaction.  '
		  	}
		  	else if(this.state.sale.transaction && (this.state.sale.transaction.paymentTotal>=0.01 || this.state.sale.transaction.paymentTotal<=-0.01)){
		  		additionalWarning = 'This transaction associated with this sale has a paid balance.  Deleting this may mess up the balance of the associated transaction.  '
		  	}
		}

	    swal(additionalWarning+ "Are you sure you want to save this sale for later?", {
	  		icon: "warning",
	      buttons: {
	        nope: {
	          text: "Cancel",
	          value: "nope"
	        },
	        sure: {
	          className: "btn-danger",
	          text: "I'm, Sure",
	          value: "sure"
	        }
	      }
	    }).then(value => {

	      switch (value) {
	        case "sure":
					let transactionId = this.state.sale.transaction?self.state.sale.transaction._id:null

			        let sale = self.state.sale
			        sale.transaction=null
			        self.setState({sale}, () => {
			        	self.update(()=>{

							swal({
								title:"Success!",
								text:'Sales saved for later.',
								type:"success",
								timer:1000
							}).then(value => {
				      			window.location=(transactionId?'/transaction/'+transactionId:'/sales')
							}); 
			        	})
			        })
	          break;
	        case "nope":
	          	console.log('Didnt Delete (cancel)')
	          break;
	        default:
	          	console.log('Didnt Delete (dismissed)')
	          // swal("Got away safely!");
	          break;
	      }
	    });

  }

  delete(){

  	let self=this
  	console.log(this.state.sale)

  	let additionalWarning=''

  	if(this.state.sale.price>=0.01 || this.state.sale.price<=-0.01){
	  	if(this.state.sale.returned){
	  		additionalWarning += 'This sale has been returned.  Deleting this may mess up the balance of the associated transaction.  '
	  	}
	  	if(this.state.sale.transaction && (this.state.sale.transaction.balance>=0.01 || this.state.sale.transaction.balance<=-0.01)){
	  		additionalWarning += 'This transaction associated with this sale has a balance.  Deleting this may mess up the balance of the associated transaction.  '
	  	}
	  	else if(this.state.sale.transaction && (this.state.sale.transaction.paymentTotal>=0.01 || this.state.sale.transaction.paymentTotal<=-0.01)){
	  		additionalWarning = 'This transaction associated with this sale has a paid balance.  Deleting this may mess up the balance of the associated transaction.  '
	  	}
	}

    swal(additionalWarning+ "Are you sure you want to delete this sale?", {
  		icon: "warning",
      buttons: {
        nope: {
          text: "Cancel",
          value: "nope"
        },
        sure: {
          className: "btn-danger",
          text: "I'm, Sure",
          value: "sure"
        }
      }
    }).then(value => {
	  	if(this.state.photos && this.state.photos.length){
	  		swal("Error!", "You must first delete all photos associated with this sale.", "error");
	  		return
	  	}

      switch (value) {
        case "sure":
        	let transactionId = this.state.sale.transaction?self.state.sale.transaction._id:null

          	console.log('Deleted')
				axios.delete('/api/sales/'+self.state.sale._id, {})
				.then( (data) => {
			      console.log(`Sale deleted successfully`);
			      	if(transactionId){
		      			window.location='/transaction/'+transactionId	
			      	}
			      	else{
		      			window.location='/sales'	
			      	}
			      // console.log(data)
			      	// self.props.addPhotoToModel(data.data.filename)
					// self.setState({
					// 	selectedNote:data.data,
					// },self.filterResults)
			    }).catch((e) => {
			    	console.log('Sale delete failed, Error ',e)
					// self.setState({errorMessage:e})
				});
          break;
        case "nope":
          	console.log('Didnt Delete (cancel)')
          break;
        default:
          	console.log('Didnt Delete (dismissed)')
          // swal("Got away safely!");
          break;
      }
    });
  }

	updatePhoto(field,value){
		let photos = this.state.photos
		photos[this.state.currentImage][field]=value
		this.setState({photos})
		console.log({updatePhoto:{field,value}})
	}

  	savePhoto(){
  		let photo = this.state.photos[this.state.currentImage]


		axios.put('/api/photos/'+photo._id, {photo})
		.then( (data) => {
	      console.log(`Item updated successfully`);
	      // self.fetchPhotos()
	      console.log(data)
	      	// self.props.addPhotoToModel(data.data.filename)
			// self.setState({
			// 	selectedNote:data.data,
			// },self.filterResults)
	    }).catch((e) => {
	    	console.log('Photo update failed, Error ',e)
			// self.setState({errorMessage:e})
		});
  	}


	clonePhotos(product){
		let self=this
		console.log({clonePhotos:product})

		// console.log({clonePhotos:product})
		if(product && product.image && product.image.length){


			axios.post('/api/photos/cloneFromUrl', {
				urls:product.image,
				type:'sales',
				refId:this.state.sale._id
			}, {
				// headers: {
				// 'Content-Type': 'multipart/form-data'
				// }
			})
			.then( (data) => {
				console.log(`Item created successfully`);
				self.fetchPhotos()
				console.log(data)
				// self.props.addPhotoToModel(data.data.filename)
				// self.setState({
				// 	selectedNote:data.data,
				// },self.filterResults)
			}).catch((e) => {
				console.log('Creation failed, Error ',e)
				// self.setState({errorMessage:e})
			});
			// axios.get(product.image[0], { responseType:"blob" })
			// .then(function (response) {

			// 	self.newPhoto(response.data)
			// 	// var reader = new window.FileReader();
			// 	// reader.readAsDataURL(response.data); 
			// 	// reader.onload = function() {

			// 	// 	var imageDataUrl = reader.result;
			// 	// 	imageElement.setAttribute("src", imageDataUrl);

			// 	// }
			// });
			// fetch(product.image[0], {
			// 	"headers": {},
			// 	"method": "GET"
			// 	}).then(function(resp) {
			// 		return resp.blob();
			// 	}).then(async function(blob) {

			// 		self.newPhoto(blob)
			// 		// var buffer = await blob.arrayBuffer();
			// 		// buffer = Buffer.from(buffer)
			// 		// fs.createWriteStream('file.mp3').write(buffer);
			// 	})
		}

  	}


	newPhoto(fileObj){
		let self=this
		console.log({newPhoto:this.state})

		var formData = new FormData();
		formData.append("file", fileObj);
		formData.append("type", 'sales');
		formData.append("refId", this.state.sale._id);

		axios.post('/api/photos', formData, {
		    headers: {
		      'Content-Type': 'multipart/form-data'
		    }
		})
		.then( (data) => {
	      console.log(`Item created successfully`);
	      self.fetchPhotos()
	      console.log(data)
	      	// self.props.addPhotoToModel(data.data.filename)
			// self.setState({
			// 	selectedNote:data.data,
			// },self.filterResults)
	    }).catch((e) => {
	    	console.log('Creation failed, Error ',e)
			// self.setState({errorMessage:e})
		});

	}

	deletePhoto(){
		let self=this
  		let photo = this.state.photos[this.state.currentImage]


		axios.delete('/api/photos/'+photo._id, {})
		.then( (data) => {
	      console.log(`Photo deleted successfully`);
      		window.location='/sale/'+self.state.sale._id+'?t='+new Date().getTime() + '&selectedTab='+self.state.selectedTab
	      // console.log(data)
	      	// self.props.addPhotoToModel(data.data.filename)
			// self.setState({
			// 	selectedNote:data.data,
			// },self.filterResults)
	    }).catch((e) => {
	    	console.log('Photo delete failed, Error ',e)
			// self.setState({errorMessage:e})
		});
		// let photo = this.state.sale.photos[this.state.currentImage]
		// this.props.deleteModelPhoto(photo)
		// console.log({deletePhoto:photo})
		// console.log({deletePhoto:this.state.currentImage})
	}

	onCurrentImageChange(index) {
		console.log({onCurrentImageChange:index})
        this.setState({ currentImage: index });
    }



  render() {
	let self=this


	let updateButton=null
	if(self.state.isDirty){
		updateButton=(

			<ol key='update_button' className="breadcrumb float-sm-right">
			<li style={{marginRight: '10px'}}>         	
				<button type="button" className="btn btn-block btn-success" onClick={()=>{self.update()}}>Update</button>
			</li>
			</ol>
		)
	}

	let transactionButton = null
	let payLaterButton = null
	if(this.state.sale.transaction){
		transactionButton = (

			<ol key='transaction_button' className="breadcrumb float-sm-right">
			<li style={{marginRight: '10px'}}>         	
				<button type="button" className="btn btn-block btn-primary" onClick={()=>{
					window.location = '/transaction/'+this.state.sale.transaction._id
				}}>See Transaction</button>
			</li>
			</ol>
		)

		if(!this.state.sale.returned){
			payLaterButton = (
				<button type="button" className="btn btn-block btn-warning" onClick={()=>{self.detach()}}>Pay for this later</button>
			)
		}
		else{
			payLaterButton = (
				<button type="button" className="btn btn-block btn-warning" onClick={()=>{self.updateField('returned',false)}}>Mark as NOT returned</button>
			)

		}
	}


	let galleryPhotos=(this.state.photos||[]).map((p,pid)=>{
		return {
			src: axios.defaults.baseURL + "/api/photos/"+p.type+'/'+p.fileName,
			thumbnail: axios.defaults.baseURL + "/api/photos/"+p.type+'/'+p.fileName,
			// thumbnailWidth: 80,
			// thumbnailHeight: 190,
			// isSelected: true,
			// caption: "After Rain (Jeshu John - designerspics.com)"
		}
	})

	let currentImageDetails = ''
	if(this.state.photos && this.state.photos.length){
		if(this.state.currentImage!=null && !isNaN(this.state.currentImage) && this.state.photos[this.state.currentImage] && this.state.photos[this.state.currentImage].details){
			currentImageDetails = this.state.photos[this.state.currentImage].details
		}
	}
	let gallery = null
	if(galleryPhotos.length){
		gallery = (

			<Gallery key="_gallery"
				rowHeight={100}
				enableImageSelection={false} 
				backdropClosesModal={true}
				showLightboxThumbnails={true}
				images={galleryPhotos}
				currentImageWillChange={(index)=>self.onCurrentImageChange(index)}
				customControls={[

					<button key="deleteImage" onClick={()=>self.deletePhoto()}>Delete</button>,

					<textarea 
						className="form-control photo-description-textarea" 
						rows="7" 
						onChange={(e)=>{self.updatePhoto('details',e.target.value)}}
						onBlur={()=>{self.savePhoto()}}
						value={currentImageDetails}
						placeholder="Photo Details..."/>
				]}/>
		)
	}

	let lookupModal = null


	if(self.state.showLookupModal){

		lookupModal = (

						<Modal>
							<div className="modal">

								<div style={{position:'absolute',top:0,left:0,bottom:0,right:0,background:'rgba(0,0,0,.01)',color:'white',zIndex:999}}
									 onClick={(e)=>{
									 	self.setState({showLookupModal:false})
								}}></div>
								<div style={{position:'relative',background:'white',width:'70vw',height:'70vmin',zIndex:1000}}>
									
									  <div className="content-header">
									    <div className="container-fluid">
									      <div className="row mb-2">
									        <div className="col-sm-6">
									          <h1 className="m-0 text-dark">
									          Product Lookup
									          </h1>
									        </div>{/* /.col */}
									      </div>{/* /.row */}
									    </div>{/* /.container-fluid */}
									  </div>


									    <section className="content">
									      <div className="row">
									        <div className="col-12">
											<input
												id="product-search"
												className="form-control form-control-navbar"
												style={{borderRightWidth: '1px'}}                  
													autoComplete="off"
													autoCorrect="off"
													autoCapitalize="off" 
												type="search"
												placeholder="Search"
												aria-label="Search"
												onChange={(e)=>{self.setState({searchText:e.target.value},()=>{
													self.fetchProducts()
												})}}
												/>
												<div className="card-body" style={{height:'50vmin',overflowY:'scroll'}}>
												<table id="example1" className="table table-bordered table-striped">
													<thead>
													<tr>
														<th style={{minWidth:'100px'}}>SKU</th>
														<th style={{minWidth:'100px'}}>Price</th>
														<th style={{minWidth:'100px'}}>QTY</th>
														<th style={{width:'100%'}}>Name/Description</th>
													</tr>
													</thead>
													<tbody>
													{self.state.products.map((p,px)=>{
														let elipsis = {
															maxWidth: '100%',
															overflow: 'hidden',
															whiteSpace: 'nowrap',
															textOverflow: 'ellipsis'
														}
														let row_bg = ''
														// if(!t.balance>0){
														// 	row_bg = 'bg-pending'
														// 	if(tx%2==1){
														// 		row_bg = 'bg-pending-alt'
														// 	}
														// }

														return(

															<tr className="product-row" key={'p_'+p.sku} onClick={()=>{
																let newDesc = p.shortDescription
																if(p.vendorSku){
																	newDesc = [`Vendor SKU: ${p.vendorSku}`,p.shortDescription].join('\n')
																}
																self.updateFields(["sku","title","type","price","description"],[
																	p.sku,
																	p.name,
																	p.type,
																	p.price,
																	newDesc
																])
																self.setState({showLookupModal:false},()=>{
																	self.clonePhotos(p)
																})
															}}>
																<td>
																	<div>{p.sku}</div>
																	{p.vendorSku && (
																		<div>{p.vendorSku}</div>
																	) || null}
																	{(p.image && p.image.length && (
																		<div>
																			<img style={{width:'100px'}} src={p.image[0]} />
																		</div>
																	)) || null}
																</td>
																<td style={{textAlign: 'right'}}>${p.price.toFixed(2)}</td>
																<td style={{textAlign: 'right'}}>{p.stock}</td>
																{/* <td style={{whiteSpace: 'nowrap'}}>{2}</td> */}
																<td>
																	<div style={elipsis}>{p.name}</div>
																	<div style={elipsis}>{p.shortDescription}</div>
																</td>
															</tr>
														)
													})}
													</tbody>
												</table>
												</div>
									        </div>
									      </div>
									      <div className="row" style={{marginTop:'3vmin'}}>
									        <div className="col-4"/>
									        <div className="col-4">
									          	<button type="button" className="btn btn-block btn-danger" onClick={()=>{self.setState({showLookupModal:false})}}>Close</button>
									        </div>
									      </div>
									    </section>


								</div>
							</div>
						</Modal>
		)
	}
    return (
    <div className="content-wrapper">
	  {/* Content Header (Page header) */}
	  <div className="content-header">
	    <div className="container-fluid">
	      <div className="row mb-2">
	        <div className="col-sm-12">

	          <h1 className="m-0 text-dark float-sm-left">
            	{self.state.sale.title}
	          </h1>
	          {transactionButton}
	          {updateButton}
	        </div>{/* /.col */}
	      </div>{/* /.row */}
	    </div>{/* /.container-fluid */}
	  </div>
	  {/* /.content-header */}
	  {/* Main content */}



    <section className="content">
      <div className="row">
        <div className="col-12">

          <div className="card">
          	{
            // <div className="card-header">
            // 	<h3>
            // 		{name}
            // 	</h3>
            // </div>
        	}
            {
            // <!-- /.card-header -->
            //const newSale = new Sale({type,title,description,instructions,customer,user,price});
        	}
            <div className="card-body">

      			<div className="row">
        			<div className="col-6">

        			  <label>Sku</label>
					  <a style={{marginLeft:'20px'}} href="#" onClick={(e)=>{
						  e.preventDefault()
						  self.setState({showLookupModal:true})
					  }}>LOOKUP</a>

                      <input
                        onChange={(e)=>{self.updateField('sku',e.target.value)}}
                        value={this.state.sale.sku||''}                
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off" 
                        type="text" pattern="\d*"
                        className="form-control"
                        id="sku"
                        placeholder="sku"
                      />

        			  <label>Title</label>

                      <input
                        onChange={(e)=>{self.updateField('title',e.target.value)}}
                        value={this.state.sale.title||''}                
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off" 
                        type="text"
                        className="form-control"
                        id="title"
                        placeholder="Title"
                      />

        			  <label>Type</label>
	                  <select 
	                  	className="form-control select2"
                        onChange={(e)=>{self.updateField('type',e.target.value||'')}}
                        value={this.state.sale.type}>
	                    <option value="">Choose Type</option>
	                    <option value="bracelet">Bracelet</option>
	                    <option value="earrings">Earrings</option>
	                    <option value="ring">Ring</option>
	                    <option value="necklace">Necklace</option>
	                    <option value="pendant">Pendant</option>
	                    <option value="watch">Watch</option>
	                    <option value="appraisal">Appraisal</option>
	                    <option value="scrapPurchase">Scrap Purchase</option>
	                    <option value="other">Other</option>
	                  </select>
	                  

        			</div>
        			<div className="col-6">

        			  <label>Price</label>
                      <input
                        onChange={(e)=>{self.updateField('price',e.target.value.replace(/[^0-9\.\-]/gi,''))}}
                        value={'$'+this.state.sale.price||0}                
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off" 
                        type="text" pattern="\d*"
                        className="form-control"
                        id="price"
                        placeholder="Price"
                        onBlur={(e)=>{
                        	if(isNaN(+this.state.sale.price)){
                        		self.updateField('price','0.00')
                        	}
                        	else{
                        		self.updateField('price',(+this.state.sale.price).toFixed(2))	
                        	}
                        }}
                      />

        			  <label>Tax</label>

	                  <select 
	                  	className="form-control select2"
                        onChange={(e)=>{
                        	switch(e.target.value){
                        		case 'yes':
                        			self.updateFields(['taxable', 'taxInPrice'],[true,false])
                        			break;
                        		case 'included':
                        			self.updateFields(['taxable', 'taxInPrice'],[true,true])
                        			break;
                        		default:
                        			self.updateFields(['taxable', 'taxInPrice'],[false,false])
                        			break;

                        	}
                        }}
                        value={this.state.sale.taxable?(this.state.sale.taxInPrice?'included':'yes'):'no'}>
	                    <option value="no">no</option>
	                    <option value="yes">yes</option>
	                    <option value="included">included</option>
	                  </select>

        			</div>

			          <div className="col-12">&nbsp;</div>

			          <div className="col-12">
			            <ul className="nav nav-tabs" id="custom-content-below-tab" role="tablist">
			              <li className="nav-item">
			                <a className={"nav-link" + (self.state.selectedTab==='description'?' active':'')}
			                   id="custom-content-description-tab" 
			                   data-toggle="pill" 
			                   href="#custom-content-description" 
			                   role="tab" 
			                   aria-controls="custom-content-description" 
			                   aria-selected="false"
			                   onClick={(e)=>{
			                   	self.setState({selectedTab:'description'})
			                   }}>Description</a>
			              </li>
			              <li className="nav-item">
			                <a className={"nav-link" + (self.state.selectedTab==='notes'?' active':'')}
			                   id="custom-content-notes-tab" 
			                   data-toggle="pill" 
			                   href="#custom-content-notes" 
			                   role="tab" 
			                   aria-controls="custom-content-notes" 
			                   aria-selected="false"
			                   onClick={(e)=>{
			                   	self.setState({selectedTab:'notes'})
			                   }}>Notes</a>
			              </li>
			              <li className="nav-item">
			                <a className={"nav-link" + (self.state.selectedTab==='photos'?' active':'')}
			                   id="custom-content-photos-tab" 
			                   data-toggle="pill" 
			                   href="#custom-content-photos" 
			                   role="tab" 
			                   aria-controls="custom-content-photos" 
			                   aria-selected="false"
			                   onClick={(e)=>{
			                   	self.setState({selectedTab:'photos'})
			                   }}>Photos</a>
			              </li>
			              <li className="nav-item">
			                <a className={"nav-link" + (self.state.selectedTab==='more'?' active':'')}
			                   id="custom-content-more-tab" 
			                   data-toggle="pill" 
			                   href="#custom-content-more" 
			                   role="tab" 
			                   aria-controls="custom-content-more" 
			                   aria-selected="false"
			                   onClick={(e)=>{
			                   	self.setState({selectedTab:'more'})
			                   }}>More...</a>
			              </li>
			            </ul>
			            <div className="tab-content" id="custom-content-below-tabContent">
			              <div className={"tab-pane fade show" + (self.state.selectedTab==='description'?' active':'')} 
			              	   id="custom-content-description" 
			              	   role="tabpanel" 
			              	   aria-labelledby="custom-content-description-tab">
			                 
			                      <textarea 
			                      	className="form-control" 
			                      	rows="10" 
			                        onChange={(e)=>{self.updateField('description',e.target.value)}}
			                        value={this.state.sale.description}
			                      	placeholder="Description..."/>
			              </div>
			              <div className={"tab-pane fade show" + (self.state.selectedTab==='notes'?' active':'')} 
			              	   id="custom-content-notes" 
			              	   role="tabpanel" 
			              	   aria-labelledby="custom-content-notes-tab">
			                 
			                      <textarea 
			                      	className="form-control" 
			                      	rows="10" 
			                        onChange={(e)=>{self.updateField('notes',e.target.value)}}
			                        value={this.state.sale.notes}
			                      	placeholder="Notes..."/>
			              </div>
			              <div className={"tab-pane fade show" + (self.state.selectedTab==='photos'?' active':'')} 
			              	   id="custom-content-photos" 
			              	   role="tabpanel" 
			              	   aria-labelledby="custom-content-photos-tab">
			                 
			                 	<div className="col-2">
									<PhotoEditor 
									text="New Photo" 
									onImageBlob={(data)=>{
										self.newPhoto(data.blobFile)
									}}/>
								</div>
			            		{gallery}
			              </div>
			              <div className={"tab-pane fade show" + (self.state.selectedTab==='more'?' active':'')} 
			              	   id="custom-content-more" 
			              	   role="tabpanel" 
			              	   aria-labelledby="custom-content-more-tab">
			                 
			                 	{payLaterButton}
	          					<button type="button" className="btn btn-block btn-danger" onClick={()=>{self.delete()}}>delete</button>
			              </div>
			            </div>
			          </div>

        		</div>

            </div>
	        {
	        //<!-- /.card-body -->
	        }
          </div>
          {
          	//<!-- /.card -->
      	  }
        </div>
        {
        //<!-- /.col -->
    	}
      </div>
      {
      //<!-- /.row -->
  }
    </section>
	{lookupModal}
	</div>

      );
  }
}

export default Sale;
