import React, { Component } from 'react';
import swal from "sweetalert";
import axios from "axios";

import './repairs.css';

import DatePicker from "react-datepicker";
import setMinutes from "date-fns/setMinutes";
import setHours from "date-fns/setHours";
import "react-datepicker/dist/react-datepicker.css";


import Gallery from 'react-grid-gallery';

import PhotoEditor from "../photoEditor/photoEditor";


const DatepickerInput = ({ ...props }) => (
  <input type="text" {...props} readOnly />
);

class Repair extends Component {

  constructor(props){
  	super(props)

  	let qvals={}
    let repairId = (window.location.href.split('/repair/')[1].split('/').slice(0)[0]||'').split('?')[0].trim()
    let sst = ''
    let selectedTab = ''
	let qqs
    
  	(window.location.href.split('?')[1]||'').split('&').map((vv)=>{
    	let vx=vv.split('=')
    	qvals[vx[0]]=vx[1]
	})
	if(qvals.selectedTab){
		selectedTab = qvals.selectedTab
		sst = '?selectedTab=' + selectedTab
	}
    if (qvals.c && repairId) {
        localStorage.setItem("currentCustomer", qvals.c);
        window.location='/repair/' + repairId + sst
        return;
    }

  	this.state={
  		currentImage:null,
  		selectedTab:selectedTab||'description',
  		isDirty:false,
  		repairId,
  		repair:{},
  		photos:[],
  		jewelers:[]
  	}

  	this.fetch()
  }

  fetchJewelers(){
	let self=this

    axios
      .get("/api/users/jewelers")
      .then(res => {
        if (res.data) {
        	self.setState({jewelers:res.data},self.fetchPhotos)
        }
        else{

        	self.setState({jewelers:[]})	
        }
        // else if (res.data.result === "error") {
        	console.log({jewelers:res});
        // }
      })
      .catch(error => {
        console.log(error);
      });

  }
  fetchPhotos(){
	let self=this

    axios
      .get("/api/photos/repairs/"+self.state.repairId)
      .then(res => {
        if (res.data) {
        	self.setState({photos:res.data})
        }
        else{

        	self.setState({photos:[]})	
        }
        // else if (res.data.result === "error") {
        	console.log({photos:res});
        // }
      })
      .catch(error => {
        console.log(error);
      });

  }
  fetch(){
	let self=this

    axios
      .get("/api/repairs/view/"+self.state.repairId)
      .then(res => {
        if (res.data) {
        	self.setState({repair:res.data},self.fetchJewelers)
        }
        else{

        	self.setState({repair:{}})	
        }
        // else if (res.data.result === "error") {
        	console.log({repair:res});
        // }
      })
      .catch(error => {
        console.log(error);
      });

  }

  updateField(field,value){
    let repair = this.state.repair
    repair[field]=value
    this.setState({repair,isDirty:true})
  }
  updateFields(fields,values){
    let repair = this.state.repair
    fields.forEach((f,fx)=>{
    	repair[f]=values[fx]	
    })
    
    this.setState({repair,isDirty:true})
  }

  update(callback){
    let self=this
    let repair = this.state.repair

    axios.put('/api/repairs/'+repair._id,{repair}).then( (data) => {
      console.log(`Repair updated successfully`);
      	if(callback){
      		callback()
      	}
      	else{

			swal({
				title:"Success!",
				text:'Repair updated successfully',
				type:"success",
				timer:1000
			}).then(value => {
      			window.location='/repair/'+repair._id+'?t='+new Date().getTime() + '&selectedTab='+self.state.selectedTab
			});    		
      	}

    }).catch((e) => {
    	console.log('Creation failed, Error ',e)
    	swal("Error!", e.message, "error");
		// self.setState({errorMessage:e})
	});
  }
  detach(){
  		let self = this
	  	let additionalWarning=''
  	  	if(this.state.repair.price>=0.01 || this.state.repair.price<=-0.01){

		  	if(this.state.repair.transaction && (this.state.repair.transaction.balance>=0.01 || this.state.repair.transaction.balance<=-0.01)){
		  		additionalWarning = 'This transaction associated with repair item has a balance.  Deleting this may mess up the balance of the associated transaction.  '
		  	}
		  	else if(this.state.repair.transaction && (this.state.repair.transaction.paymentTotal>=0.01 || this.state.repair.transaction.paymentTotal<=-0.01)){
		  		additionalWarning = 'This transaction associated with this sale has a paid balance.  Deleting this may mess up the balance of the associated transaction.  '
		  	}
		}

	    swal(additionalWarning+ "Are you sure you want to save this repair for later?", {
	  		icon: "warning",
	      buttons: {
	        nope: {
	          text: "Cancel",
	          value: "nope"
	        },
	        sure: {
	          className: "btn-danger",
	          text: "I'm, Sure",
	          value: "sure"
	        }
	      }
	    }).then(value => {

	      switch (value) {
	        case "sure":

		        let transactionId = this.state.repair.transaction?self.state.repair.transaction._id:null

		        let repair = self.state.repair
		        repair.transaction=null
		        self.setState({repair}, () => {
		        	self.update(()=>{

						swal({
							title:"Success!",
							text:'Repair saved for later.',
							type:"success",
							timer:1000
						}).then(value => {
			      			window.location=(transactionId?'/transaction/'+transactionId:'/repairs')
						}); 
		        	})
		        })
	          break;
	        case "nope":
	          	console.log('Didnt Delete (cancel)')
	          break;
	        default:
	          	console.log('Didnt Delete (dismissed)')
	          // swal("Got away safely!");
	          break;
	      }
	    });


  }
  delete(){

  	let self=this
  	console.log(this.state.repair)

  	let additionalWarning=''
  	if(this.state.repair.price>=0.01 || this.state.repair.price<=-0.01){
	  	if(this.state.repair.returned){
	  		additionalWarning += 'This repair has been returned.  Deleting this may mess up the balance of the associated transaction.  '
	  	}
	  	if(this.state.repair.transaction && (this.state.repair.transaction.balance>=0.01 || this.state.repair.transaction.balance<=-0.01)){
	  		additionalWarning += 'This transaction associated with this repair has a balance.  Deleting this may mess up the balance of the associated transaction.  '
	  	}
  	}

    swal(additionalWarning+ "Are you sure you want to delete this repair?", {
  		icon: "warning",
      buttons: {
        nope: {
          text: "Cancel",
          value: "nope"
        },
        sure: {
          className: "btn-danger",
          text: "I'm, Sure",
          value: "sure"
        }
      }
    }).then(value => {
	  	if(this.state.photos && this.state.photos.length){
	  		swal("Error!", "You must first delete all photos associated with this repair.", "error");
	  		return
	  	}

      switch (value) {
        case "sure":
        	let transactionId = self.state.repair.transaction ?self.state.repair.transaction._id:null
          	console.log('Deleted')
				axios.delete('/api/repairs/'+self.state.repair._id, {})
				.then( (data) => {
			      console.log(`Repair deleted successfully`);
			      	if(transactionId){
		      			window.location='/transaction/'+transactionId	
			      	}
			      	else{
		      			window.location='/repairs'	
			      	}
			      // console.log(data)
			      	// self.props.addPhotoToModel(data.data.filename)
					// self.setState({
					// 	selectedNote:data.data,
					// },self.filterResults)
			    }).catch((e) => {
			    	console.log('Repair delete failed, Error ',e)
					// self.setState({errorMessage:e})
				});
          break;
        case "nope":
          	console.log('Didnt Delete (cancel)')
          break;
        default:
          	console.log('Didnt Delete (dismissed)')
          // swal("Got away safely!");
          break;
      }
    });
  }

	updatePhoto(field,value){
		let photos = this.state.photos
		photos[this.state.currentImage][field]=value
		this.setState({photos})
		console.log({updatePhoto:{field,value}})
	}

  	savePhoto(){
  		let photo = this.state.photos[this.state.currentImage]


		axios.put('/api/photos/'+photo._id, {photo})
		.then( (data) => {
	      console.log(`Item updated successfully`);
	      // self.fetchPhotos()
	      console.log(data)
	      	// self.props.addPhotoToModel(data.data.filename)
			// self.setState({
			// 	selectedNote:data.data,
			// },self.filterResults)
	    }).catch((e) => {
	    	console.log('Photo update failed, Error ',e)
			// self.setState({errorMessage:e})
		});
  	}


	newPhoto(fileObj){
		let self=this
		console.log(this.state)

		var formData = new FormData();
		formData.append("file", fileObj);
		formData.append("type", 'repairs');
		formData.append("refId", this.state.repair._id);

		axios.post('/api/photos', formData, {
		    headers: {
		      'Content-Type': 'multipart/form-data'
		    }
		})
		.then( (data) => {
	      console.log(`Item created successfully`);
	      self.fetchPhotos()
	      console.log(data)
	      	// self.props.addPhotoToModel(data.data.filename)
			// self.setState({
			// 	selectedNote:data.data,
			// },self.filterResults)
	    }).catch((e) => {
	    	console.log('Creation failed, Error ',e)
			// self.setState({errorMessage:e})
		});

	}

	deletePhoto(){
		let self=this
  		let photo = this.state.photos[this.state.currentImage]


		axios.delete('/api/photos/'+photo._id, {})
		.then( (data) => {
	      console.log(`Photo deleted successfully`);
      		window.location='/repair/'+self.state.repair._id+'?t='+new Date().getTime() + '&selectedTab='+self.state.selectedTab
	      // console.log(data)
	      	// self.props.addPhotoToModel(data.data.filename)
			// self.setState({
			// 	selectedNote:data.data,
			// },self.filterResults)
	    }).catch((e) => {
	    	console.log('Photo delete failed, Error ',e)
			// self.setState({errorMessage:e})
		});
		// let photo = this.state.repair.photos[this.state.currentImage]
		// this.props.deleteModelPhoto(photo)
		// console.log({deletePhoto:photo})
		// console.log({deletePhoto:this.state.currentImage})
	}

	onCurrentImageChange(index) {
		console.log({onCurrentImageChange:index})
        this.setState({ currentImage: index });
    }



  render() {
		let self=this
		let shopHint = ''
		if(self.state.repair && !self.state.repair.transaction){
			shopHint = '/' + localStorage.currentShop
		}


		let updateButton=(

            <ol key='update_button' className="breadcrumb float-sm-right">
              <li style={{marginRight: '10px'}}>          
                <a href={axios.defaults.baseURL + '/api/print/repair/'+ this.state.repair._id + shopHint} target='_blank' className="btn btn-block btn-primary">Print Ticket</a>
              </li>
            </ol>
		)
		if(self.state.isDirty){
			updateButton=(

	          <ol key='update_button' className="breadcrumb float-sm-right">
	            <li style={{marginRight: '10px'}}>         	
	          		<button type="button" className="btn btn-block btn-success" onClick={()=>{self.update()}}>update</button>
	            </li>
	          </ol>
			)
		}
		let transactionButton = null
		let payLaterButton = null
		if(this.state.repair.transaction){
			transactionButton = (

	          <ol key='transaction_button' className="breadcrumb float-sm-right">
	            <li style={{marginRight: '10px'}}>         	
	          		<button type="button" className="btn btn-block btn-primary" onClick={()=>{
    					window.location = '/transaction/'+this.state.repair.transaction._id
    				}}>See Transaction</button>
	            </li>
	          </ol>
			)

			if(!this.state.repair.returned){

				payLaterButton = (
					<button type="button" className="btn btn-block btn-warning" onClick={()=>{self.detach()}}>Pay for this later</button>
				)	
			}
		}


        let galleryPhotos=(this.state.photos||[]).map((p,pid)=>{
			return {
		        src: axios.defaults.baseURL + "/api/photos/"+p.type+'/'+p.fileName,
		        thumbnail: axios.defaults.baseURL + "/api/photos/"+p.type+'/'+p.fileName,
		        // thumbnailWidth: 80,
		        // thumbnailHeight: 190,
		        // isSelected: true,
		        // caption: "After Rain (Jeshu John - designerspics.com)"
			}
		})

		let currentImageDetails = ''
		if(this.state.photos && this.state.photos.length){
			if(this.state.currentImage!=null && !isNaN(this.state.currentImage) && this.state.photos[this.state.currentImage] && this.state.photos[this.state.currentImage].details){
				currentImageDetails = this.state.photos[this.state.currentImage].details
			}
		}
		let gallery = null
		if(galleryPhotos.length){
			gallery = (

        		<Gallery key="_gallery"
        			rowHeight={100}
        			enableImageSelection={false} 
					backdropClosesModal={true}
					showLightboxThumbnails={true}
        			images={galleryPhotos}
					currentImageWillChange={(index)=>self.onCurrentImageChange(index)}
					customControls={[

                    	<button key="deleteImage" onClick={()=>self.deletePhoto()}>Delete</button>,

						<textarea 
							className="form-control photo-description-textarea" 
							rows="7" 
							onChange={(e)=>{self.updatePhoto('details',e.target.value)}}
							onBlur={()=>{self.savePhoto()}}
							value={currentImageDetails}
							placeholder="Photo Details..."/>
					]}/>
			)
		}
    return (
    <div className="content-wrapper">
	  {/* Content Header (Page header) */}
	  <div className="content-header">
	    <div className="container-fluid">
	      <div className="row mb-2">
	        <div className="col-sm-12">

	          <h1 className="m-0 text-dark float-sm-left">
            	{self.state.repair.title}
	          </h1>
	          {transactionButton}
	          {updateButton}
	        </div>{/* /.col */}
	      </div>{/* /.row */}
	    </div>{/* /.container-fluid */}
	  </div>
	  {/* /.content-header */}
	  {/* Main content */}



    <section className="content">
      <div className="row">
        <div className="col-12">

          <div className="card">
          	{
            // <div className="card-header">
            // 	<h3>
            // 		{name}
            // 	</h3>
            // </div>
        	}
            {
            // <!-- /.card-header -->
            //const newRepair = new Repair({type,title,description,instructions,customer,user,price});
        	}
            <div className="card-body">

      			<div className="row">
        			<div className="col-6">

        			  <label>Title</label>

                      <input
                        onChange={(e)=>{self.updateField('title',e.target.value)}}
                        value={this.state.repair.title||''}                
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off" 
                        type="text"
                        className="form-control"
                        id="title"
                        placeholder="Title"
                      />

        			  <label>Type</label>
	                  <select 
	                  	className="form-control select2"
                        onChange={(e)=>{self.updateField('type',e.target.value||'')}}
                        value={this.state.repair.type}>
	                    <option value="">Choose Type</option>
	                    <option value="bracelet">Bracelet</option>
	                    <option value="earrings">Earrings</option>
	                    <option value="ring">Ring</option>
	                    <option value="necklace">Necklace</option>
	                    <option value="watch">Watch</option>
	                    <option value="other">Other</option>
	                  </select>
	                  

        			  <label>Due</label>
	                  	<div className="row">
		        			<div className="col-6">

			                  <select 
			                  	className="form-control select2"
		                        onChange={(e)=>{self.updateField('dueType',e.target.value||'')}}
		                        value={this.state.repair.dueType}>
			                    <option value="will-call">Will Call</option>
			                    <option value="date">Date</option>
			                  </select>
		        			</div>
		        			<div className="col-6">
		        			
		                      <DatePicker
		            			selected={this.state.repair.due?new Date(this.state.repair.due):new Date()}
		                        onChange={date => this.updateField('due',date)}
		                        dateFormat="yyyy-MM-dd"
		                        customInput={<DatepickerInput className="form-control" />}
		                      />
		        			</div>
		        		</div>


        			</div>

        			<div className="col-6">

	                  	<div className="row">
		        			<div className="col-6">
			        			  <label>Price</label>
			                      <input
			                        onChange={(e)=>{self.updateField('price',e.target.value.replace(/[^0-9\.]/gi,''))}}
			                        value={'$'+this.state.repair.price||0}                
			                        autoComplete="off"
			                        autoCorrect="off"
			                        autoCapitalize="off" 
			                        type="text" pattern="\d*"
			                        className="form-control"
			                        id="price"
			                        placeholder="Price"
			                        onBlur={(e)=>{
			                        	if(isNaN(+this.state.repair.price)){
			                        		self.updateField('price','0.00')
			                        	}
			                        	else{
			                        		self.updateField('price',(+this.state.repair.price).toFixed(2))	
			                        	}
			                        }}
			                      />
                      		</div>
		        			<div className="col-6">

			        			  <label>Tax</label>

				                  <select 
				                  	className="form-control select2"
			                        onChange={(e)=>{
			                        	switch(e.target.value){
			                        		case 'yes':
			                        			self.updateFields(['taxable', 'taxInPrice'],[true,false])
			                        			break;
			                        		case 'included':
			                        			self.updateFields(['taxable', 'taxInPrice'],[true,true])
			                        			break;
			                        		default:
			                        			self.updateFields(['taxable', 'taxInPrice'],[false,false])
			                        			break;

			                        	}
			                        }}
			                        value={this.state.repair.taxable?(this.state.repair.taxInPrice?'included':'yes'):'no'}>
				                    <option value="no">no</option>
				                    <option value="yes">yes</option>
				                    <option value="included">included</option>
				                  </select>
		        			</div>
                      </div>
                      <div className="row">
		        			<div className="col-6">

			        			  <label>Payment Status</label>
				                  <select 
				                  	className={"form-control select2" + (self.state.repair.paymentStatus==='see-note'?' highlight':'')}
			                        onChange={(e)=>{self.updateField('paymentStatus',e.target.value||'')}}
			                        value={this.state.repair.paymentStatus}>
				                    <option value="unpaid">Unpaid</option>
				                    <option value="paid-partial">Paid (partial)</option>
				                    <option value="paid-full">Paid (full)</option>
				                    <option value="see-note">See note</option>
				                  </select>
		        			</div>
		        			<div className="col-6">
			        			  <label>Refunded</label>
				                  <select 
				                  	className="form-control select2"
			                        onChange={(e)=>{self.updateField('returned',e.target.value==='yes'?true:false)}}
			                        value={this.state.repair.returned?'yes':'no'}>
				                    <option value="no">No</option>
				                    <option value="yes">Yes</option>
				                  </select>
		        			</div>
                      </div>



	                  	<div className="row">
		        			<div className="col-12">

			        			  <label>Ticket Status</label>
				                  <select 
				                  	className={"form-control select2" + (self.state.repair.status==='see-note'?' highlight':'')}
			                        onChange={(e)=>{self.updateField('status',e.target.value||'')}}
			                        value={this.state.repair.status}>
				                    <option value="New">New</option>
				                    <option value="In-progress">In-progress</option>
				                    <option value="Completed">Completed</option>
				                    <option value="see-note">See note</option>
				                  </select>
		        			</div>
{
		        			// <div className="col-6">

			        		// 	  <label>Assignment</label>
				         //          <select 
				         //          	className="form-control select2"
			          //               onChange={(e)=>{self.updateField('jeweler',e.target.value||'')}}
			          //               value={(this.state.repair.jeweler||'')}>
				         //            <option value="">Choose Jeweler</option>
				         //            {self.state.jewelers.map((j)=>{
				         //            	return <option key={'j_'+j._id} value={j._id}>{[(j.firstName||''),(j.lastName||'')].filter((vv)=>{return vv.trim().length}).join(' ').trim()}</option>
				         //            })}
				         //          </select>
		        			// </div>
}				        			
	        			</div>
        			</div>

			          <div className="col-12">&nbsp;</div>

			          <div className="col-12">
			            <ul className="nav nav-tabs" id="custom-content-below-tab" role="tablist">
			              <li className="nav-item">
			                <a className={"nav-link" + (self.state.selectedTab==='description'?' active':'')}
			                   id="custom-content-description-tab" 
			                   data-toggle="pill" 
			                   href="#custom-content-description" 
			                   role="tab" 
			                   aria-controls="custom-content-description" 
			                   aria-selected="false"
			                   onClick={(e)=>{
			                   	self.setState({selectedTab:'description'})
			                   }}>Description</a>
			              </li>
			              <li className="nav-item">
			                <a className={"nav-link" + (self.state.selectedTab==='instructions'?' active':'')}
			                   id="custom-content-instructions-tab" 
			                   data-toggle="pill" 
			                   href="#custom-content-instructions" 
			                   role="tab" 
			                   aria-controls="custom-content-instructions" 
			                   aria-selected="true"
			                   onClick={(e)=>{
			                   	self.setState({selectedTab:'instructions'})
			                   }}>Instructions</a>
			              </li>
			              <li className="nav-item">
			                <a className={"nav-link" + (self.state.selectedTab==='notes'?' active':'') + ((self.state.repair.status==='see-note' || self.state.repair.paymentStatus==='see-note')?' highlight':'')}
			                   id="custom-content-notes-tab" 
			                   data-toggle="pill" 
			                   href="#custom-content-notes" 
			                   role="tab" 
			                   aria-controls="custom-content-notes" 
			                   aria-selected="false"
			                   onClick={(e)=>{
			                   	self.setState({selectedTab:'notes'})
			                   }}>Notes</a>
			              </li>
			              <li className="nav-item">
			                <a className={"nav-link" + (self.state.selectedTab==='photos'?' active':'')}
			                   id="custom-content-photos-tab" 
			                   data-toggle="pill" 
			                   href="#custom-content-photos" 
			                   role="tab" 
			                   aria-controls="custom-content-photos" 
			                   aria-selected="false"
			                   onClick={(e)=>{
			                   	self.setState({selectedTab:'photos'})
			                   }}>Photos</a>
			              </li>
			              <li className="nav-item">
			                <a className={"nav-link" + (self.state.selectedTab==='more'?' active':'')}
			                   id="custom-content-more-tab" 
			                   data-toggle="pill" 
			                   href="#custom-content-more" 
			                   role="tab" 
			                   aria-controls="custom-content-more" 
			                   aria-selected="false"
			                   onClick={(e)=>{
			                   	self.setState({selectedTab:'more'})
			                   }}>More...</a>
			              </li>
			            </ul>
			            <div className="tab-content" id="custom-content-below-tabContent">
			              <div className={"tab-pane fade show" + (self.state.selectedTab==='instructions'?' active':'')} 
			              	   id="custom-content-instructions" 
			              	   role="tabpanel" 
			              	   aria-labelledby="custom-content-instructions-tab">
			                      <textarea 
			                      	className="form-control" 
			                      	rows="10" 
			                        onChange={(e)=>{self.updateField('instructions',e.target.value)}}
			                        value={this.state.repair.instructions}
			                      	placeholder="Instructions..."/>
			              </div>
			              <div className={"tab-pane fade show" + (self.state.selectedTab==='description'?' active':'')} 
			              	   id="custom-content-description" 
			              	   role="tabpanel" 
			              	   aria-labelledby="custom-content-description-tab">
			                 
			                      <textarea 
			                      	className="form-control" 
			                      	rows="10" 
			                        onChange={(e)=>{self.updateField('description',e.target.value)}}
			                        value={this.state.repair.description}
			                      	placeholder="Description..."/>
			              </div>
			              <div className={"tab-pane fade show" + (self.state.selectedTab==='notes'?' active':'')} 
			              	   id="custom-content-notes" 
			              	   role="tabpanel" 
			              	   aria-labelledby="custom-content-notes-tab">
			                 
			                      <textarea 
			                      	className="form-control" 
			                      	rows="10" 
			                        onChange={(e)=>{self.updateField('notes',e.target.value)}}
			                        value={this.state.repair.notes}
			                      	placeholder="Notes..."/>
			              </div>
			              <div className={"tab-pane fade show" + (self.state.selectedTab==='photos'?' active':'')} 
			              	   id="custom-content-photos" 
			              	   role="tabpanel" 
			              	   aria-labelledby="custom-content-photos-tab">
			                 
			                 	<div className="col-2">
									<PhotoEditor 
									text="New Photo" 
									onImageBlob={(data)=>{
										self.newPhoto(data.blobFile)
									}}/>
								</div>
			            		{gallery}
			              </div>
			              <div className={"tab-pane fade show" + (self.state.selectedTab==='more'?' active':'')} 
			              	   id="custom-content-more" 
			              	   role="tabpanel" 
			              	   aria-labelledby="custom-content-more-tab">
			                 
	          					{payLaterButton}
	          					<button type="button" className="btn btn-block btn-danger" onClick={()=>{self.delete()}}>Delete</button>
			              </div>
			            </div>
			          </div>

        		</div>

            </div>
	        {
	        //<!-- /.card-body -->
	        }
          </div>
          {
          	//<!-- /.card -->
      	  }
        </div>
        {
        //<!-- /.col -->
    	}
      </div>
      {
      //<!-- /.row -->
  }
    </section>
	</div>

      );
  }
}

export default Repair;
