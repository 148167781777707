import React, { Component } from 'react';
import axios from "axios";

class ShoutOut extends Component {

  constructor(props){
  	super(props)

  	this.state={
  		customers:[],
        homeNumbers:[],
        cellNumbers:[],
        emails:[],
      	ut:this.parseJwt()
  	}

  	// this.fetch()
  }
  parseJwt() {
    let token = localStorage.getItem("jwtToken");
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function(c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  fetch(){
	let self=this

	if(!this.state.ut.admin){
		window.location = '/admin/user/'+this.state.ut.id
		return;
	}

    axios
      .get("/api/report/customers")
      .then(res => {
        if (res.data && res.data.customers && res.data.customers.length) {
			let customers = res.data.customers
            let homeNumbers = []
            let cellNumbers = []
            let emails = []
            
            customers.forEach((c)=>{
                let phone = `${c.phone}`.replace(/[^0-9]/gi,'').trim()
                let cell = `${c.cellPhone}`.replace(/[^0-9]/gi,'').trim()
                let email = `${c.email}`.trim()

                if(phone && phone.length && homeNumbers.indexOf(phone)<0){
                    homeNumbers.push(phone)
                }
                if(cell && cell.length && cellNumbers.indexOf(cell)<0){
                    cellNumbers.push(cell)
                }
                if(email && email.length && emails.indexOf(email)<0 && email.indexOf('@')>0){
                    emails.push(email)
                }
            })
            let goodPhone = []
            let badPhone = []
            homeNumbers.forEach((h)=>{
                if((h.length===10 || (h.length===11 && h[0]==='1')) && h.indexOf('1111')<0 && h.indexOf('2222')<0 && h.indexOf('3333')<0 && h.indexOf('4444')<0 && h.indexOf('5555')<0 && h.indexOf('6666')<0 && h.indexOf('7777')<0 && h.indexOf('8888')<0 && h.indexOf('9999')<0 && h.indexOf('0000')<0){
                    if(h.length===11 && h[0]==='1'){
                        goodPhone.push(h.slice(1))
                    }
                    else{
                        goodPhone.push(h)
                    }
                }
                else{
                    badPhone.push(h)
                }
            })
            goodPhone.sort()
            badPhone.sort()

            homeNumbers = ["bad",...badPhone,"","good",...goodPhone]

            let goodCell = []
            let badCell = []
            cellNumbers.forEach((h)=>{
                if((h.length===10 || (h.length===11 && h[0]==='1')) && h.indexOf('1111')<0 && h.indexOf('2222')<0 && h.indexOf('3333')<0 && h.indexOf('4444')<0 && h.indexOf('5555')<0 && h.indexOf('6666')<0 && h.indexOf('7777')<0 && h.indexOf('8888')<0 && h.indexOf('9999')<0 && h.indexOf('0000')<0){
                    if(h.length===11 && h[0]==='1'){
                        goodCell.push(h.slice(1))
                    }
                    else{
                        goodCell.push(h)
                    }
                }
                else{
                    badCell.push(h)
                }
            })

            goodCell.sort()
            badCell.sort()
            cellNumbers = ["bad",...badCell,"","good",...goodCell]
			
            // customers.sort((a,b)=>{
            //     if(a.priority<b.priority){
            //         return -1
            //     }
            //     else if(a.priority>b.priority){
            //         return 1
            //     }
            //     else{
            //         return 0
            //     }
            // })
            emails.sort()
        	self.setState({customers,homeNumbers,cellNumbers,emails})
        }
        else{

        	self.setState({customers:[],homeNumbers:[],cellNumbers:[]})	
        }
        // else if (res.data.result === "error") {
        	console.log({shoutout:res});
        // }
      })
      .catch(error => {
        console.log(error);
      });

  }



  render() {
		let self=this
    return (
    <div className="content-wrapper">
  {/* Content Header (Page header) */}
  <div className="content-header">
    <div className="container-fluid">
      <div className="row mb-2">
        <div className="col-sm-6">
          <h1 className="m-0 text-dark">
          Customer Phone Numbers
          </h1>
        </div>{/* /.col */}

        <div className="col-sm-6">
          <ol className="breadcrumb float-sm-right">
    {
            // <li className="breadcrumb-item"><a href="/admin">Admin</a></li>
            // <li className="breadcrumb-item active">Users</li>
    }
            <li style={{marginLeft:'20px'}}>           	
          		<button type="button" disabled={self.state.homeNumbers.length>0} className="btn btn-block btn-success" onClick={()=>{self.fetch()}}>Fetch</button>
            </li>
          </ol>
        </div>
    	{/* /.col */}
      </div>{/* /.row */}
    </div>{/* /.container-fluid */}
  </div>
  {/* /.content-header */}
  {/* Main content */}



    <section className="content">

        <div className="row mb-2">
            <div className="col-sm-1">
                &nbsp;
            </div>
            <div className="col-sm-3">
                <h3>Home Numbers ({self.state.homeNumbers.length})</h3>
                <textarea style={{width:'200px',height:'600px'}} value={self.state.homeNumbers.join('\n')} />
            </div>
            <div className="col-sm-3">
                <h3>Cell Numbers ({self.state.cellNumbers.length})</h3>
                <textarea style={{width:'200px',height:'600px'}} value={self.state.cellNumbers.join('\n')} />
            </div>
            <div className="col-sm-4">
                <h3>Emails ({self.state.emails.length})</h3>
                <textarea style={{width:'400px',height:'600px'}} value={self.state.emails.join('\n')} />
            </div>
        </div>

    </section>
</div>

      );
  }
}

export default ShoutOut;
