import React, { Component } from 'react';
import swal from "sweetalert";
import axios from "axios";

// import './repairs.css';

class Transactions extends Component {

    constructor(props) {
        super(props)

        this.state = {
            customerId: localStorage.getItem("currentCustomer"),
            layawayFilter: (window.location.href.toLowerCase().indexOf('/layaway')>=0),
            searchText: '',
            transactions: [],
            ut: this.parseJwt()
        }

        this.fetch()
    }
    parseJwt() {
        let token = localStorage.getItem("jwtToken");
        var base64Url = token.split(".")[1];
        var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        var jsonPayload = decodeURIComponent(
            atob(base64)
            .split("")
            .map(function(c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
        );

        return JSON.parse(jsonPayload);
    }

    fetch() {
        let self = this

        let apiUrl = null

        if (self.state.customerId) {
            apiUrl = "/api/transactions/customer/" + self.state.customerId

	        if(self.state.layawayFilter){
	        	apiUrl += '/layaway'
	        }


            if (self.state.searchText.length > 0 && self.state.searchText.length < 3) {
                self.setState({
                    transactions: []
                })
                return
            }
            else if (self.state.searchText.length >= 3) {
	        	apiUrl += ('/' + self.state.searchText.trim())
            }
        }
        else{
            apiUrl = "/api/transactions/customer/all"

            if(self.state.layawayFilter){
                apiUrl += '/layaway'
            }


            if (self.state.searchText.length > 0 && self.state.searchText.length < 3) {
                self.setState({
                    transactions: []
                })
                return
            }
            else if (self.state.searchText.length >= 3) {
                apiUrl += ('/' + self.state.searchText.trim())
            }

        }

        axios
        // .get("/api/repairs/"+self.state.searchText)
            .get(apiUrl)
            .then(res => {
                if (res.data && res.data.length) {
                    self.setState({
                        transactions: res.data
                    })
                }
                else {

                    self.setState({
                        transactions: []
                    })
                }
                // else if (res.data.result === "error") {
                console.log({
                    transactions: res
                });
                // }
            })
            .catch(error => {
                console.log(error);
            });

    }

    createTransaction() {

        let self = this
        let customer = self.state.customerId
        let user = self.state.ut.id
        let layaway = self.state.layawayFilter

        let shopId = localStorage.currentShop || 'EJR'
            //{type,title,description,instructions,customer,user,price}

        axios.post('/api/transactions', {
            customer,
            user,
            layaway,
            shopId
        }).then((data) => {
            let transaction = data.data

            console.log({
                transaction
            })
			swal({
				title:"Success!",
				text:(layaway?'Layaway':'Transaction') + ' created successfully',
				type:"success",
				timer:1000
			}).then(value => {
                self.fetch()
			});
            // swal("Success!", transaction.status_message || 'transaction created successfully', "success").then(value => {
            //     // console.log({data})

            //     // window.location='/transaction/'+transaction._id
            //     // self.setState({searchText:'',repairs:[data.data]})
            //     self.fetch()
            // });
        }).catch((e) => {
            console.log('Creation failed, Error ', e)
                // self.setState({errorMessage:e})
        });
    }



    sortTransactions(a,b){
        if(a.closeDate && b.closeDate){
            if(a.closeDate<=b.closeDate){ return -1}
            else if(a.closeDate>b.closeDate){ return 1}
            else{
                if(a.created<=b.created){ return -1}
                else if(a.created>b.created){ return 1}
                else{ return 0}
            }
        }
        else if(!a.closeDate){
            return -1

        }
        else if(!b.closeDate){
            return 1
        }
        else{
                if(a.created<=b.created){ return -1}
                else if(a.created>b.created){ return 1}
                else{ return 0}
        }
    }
  render() {
		let self=this

        let closedTransactions = []
        let openTransactions = []
        let closedLayaways = []
        let openLayaways = []




        if(self.state.transactions){
            closedTransactions = self.state.transactions.filter((ot)=>{return ot.closed && !ot.layaway})
            openTransactions = self.state.transactions.filter((ot)=>{return !ot.closed && !ot.layaway})
            closedLayaways = self.state.transactions.filter((ot)=>{return ot.closed && ot.layaway})
            openLayaways = self.state.transactions.filter((ot)=>{return !ot.closed && ot.layaway})

            closedTransactions.sort(self.sortTransactions)
            closedTransactions.reverse()
            closedTransactions = closedTransactions.slice(0,3)

            openTransactions.sort(self.sortTransactions)
            openTransactions.reverse()
            // openTransactions = openTransactions.slice(0,3)

            closedLayaways.sort(self.sortTransactions)
            closedLayaways.reverse()
            closedLayaways = closedLayaways.slice(0,3)

            openLayaways.sort(self.sortTransactions)
            openLayaways.reverse()
            // openLayaways = openLayaways.slice(0,3)
        }

        let transactionTypes=[
            {name:'Open Transactions', data:openTransactions},
            {name:'Recent Closed Transactions', data:closedTransactions},
            {name:'Open Layaways', data:openLayaways},
            {name:'Recent Closed Layaways', data:closedLayaways},
        ]




    return (
    <div className="content-wrapper">
	  {/* Content Header (Page header) */}
	  <div className="content-header">
	    <div className="container-fluid">
	      <div className="row mb-2">
	        <div className="col-sm-6">
	          <h1 className="m-0 text-dark">
	           {self.state.layawayFilter?'Layaways':'Transactions'}
	          </h1>
	        </div>{/* /.col */}

	        <div className="col-sm-6">
	          <ol className="breadcrumb float-sm-right">
                <li style={{marginLeft:'20px'}}>            
                    <button type="button" className="btn btn-block btn-primary" onClick={()=>{
                        window.location = '/allTransactions'
                    }}>View All</button>
                </li>
                <li style={{marginLeft:'20px'}}>            
                    <button type="button" className="btn btn-block btn-success" onClick={()=>{self.createTransaction()}}>New</button>
                </li>
	          </ol>
	        </div>{/* /.col */}
	      </div>{/* /.row */}
	    </div>{/* /.container-fluid */}
	  </div>
	  {/* /.content-header */}
	  {/* Main content */}



    <section className="content">


      <div className="card card-solid">
        <div className="card-body pb-0">

            {transactionTypes.map((txt,txtx)=>{
                return (
                    <React.Fragment key={'kx_'+txtx}>
                        
                          <h3>{txt.name}</h3>
                          <div className="col-12 row d-flex align-items-stretch">


                          {txt.data.map((ot,otx)=>{

                                let customerDesc=null
                                let linkQs = ''
                                if(!self.state.customerId){
                                    linkQs = '?c=' + ot.customer._id
                                    customerDesc = (
                                        <div>
                                            <b>Customer:</b> {[ot.customer.firstName||'',ot.customer.lastName||''].join(' ').trim()}
                                        </div>
                                    )

                                }

                                let repairDesc= null
                                let saleDesc = null
                                if(ot.repairDesc && ot.repairDesc.length){
                                    repairDesc = (
                                        <div>
                                            <b>Repairs:</b> {ot.repairDesc}
                                        </div>
                                    )
                                }
                                if(ot.saleDesc && ot.saleDesc.length){
                                    saleDesc = (
                                        <div>
                                            <b>Sales:</b> {ot.saleDesc}
                                        </div>
                                    )
                                }

                                let wmClass =''
                                if(ot.refId && ot.refId.indexOf('EJR')>=0){
                                    wmClass = ' ejr-wm'
                                }
                                if(ot.refId && ot.refId.indexOf('WIL')>=0){
                                    wmClass = ' wil-wm'
                                }

                                return(
                                    <div key={'ot_'+ot._id} className="col-12 col-sm-6 col-md-4 d-flex align-items-stretch">
                                      <div className={"card bg-light" + wmClass} style={{width: '100%'}}>
                                        <div className="card-header text-muted border-bottom-0">
                                          
                                                {customerDesc}
                                                {repairDesc}
                                                {saleDesc}
                                        </div>
                                        <div className="card-body pt-0">
                                          <div className="row">
                                            <div className="col-12">
                                              
                                                <div style={{width:'100%',textAlign:'right'}}>
                                                    <div>
                                                        <b>Total:</b>${(ot.lineItemTotal+ot.taxTotal).toFixed(2)}
                                                    </div>
                                                    <div>
                                                        <b>Balance:</b>${(ot.balance).toFixed(2)}
                                                    </div>
                                                </div>

                                            </div>
                                          </div>
                                        </div>
                                        <div className="card-footer">
                                          <div className="text-left" style={{float:'left',fontSize:'.7em'}}>
                                                <div className="text-right">
                                                    {ot.refId}<br/>
                                                    Created: {(ot.created||'').split('T')[0]}<br/>
                                                    {ot.closed?'Closed: '+(ot.closeDate||'').split('T')[0]:''}
                                                </div>
                                          </div>
                                          <div className="text-right">
                                            <a href={'/transaction/'+ot._id+linkQs} className="btn btn-sm btn-primary">
                                              View
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                )

                          })}
                          </div>

                    </React.Fragment>
                )
            })}



        </div>
      </div>

    </section>
	</div>

      );
  }
}

export default Transactions;
